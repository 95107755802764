import React, { FormEvent, useEffect, useState } from 'react';
import { Button, Col, Form, Input, Radio, Row, Select, Switch } from 'antd';
import { useAppDispatch } from '@hook/index';
import { ICreateGame } from '@interfaces/Game';
import UploadImage from '@components/common/upload/UploadImg';
import { createGame } from '@store/gameSlice';
import TagComponent from '@components/common/tag';
const optionsApplyType = [
  {
    value: '1',
    label: 'Live casino',
  },
  {
    value: '2',
    label: 'Bắn cá',
  },
  {
    value: '3',
    label: 'Điện tử',
  },
  {
    value: '4',
    label: 'Xổ số',
  },
  {
    value: '5',
    label: 'Thể thao',
  },
  {
    value: '6',
    label: 'Game bài',
  },
  {
    value: '7',
    label: 'SBO',
  },
  {
    value: '99',
    label: 'Khác',
  },
];

export interface IFormCreateComponent {
  handleCloseScreen: (type: string) => void;
  getData: (message?: string) => void;
}
export default function FormCreateComponent({ handleCloseScreen, getData }: IFormCreateComponent) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [dataForm, setDataForm] = useState<ICreateGame>();

  const handleSubmint = (event: ICreateGame) => {
    setDataForm(event);
    dispatch(
      createGame({
        param: {
          ...dataForm,
          ...event,
          status: event.status ? 1 : 0,
          game_type: typeof event.game_type === 'object' ? event.game_type.value : event.game_type,
        },
        callback: getData,
      }),
    );
  };

  const handleChangeImg = (url: string, nameImg: string) => {
    setDataForm({ ...(dataForm as ICreateGame), [nameImg]: url });
  };

  const handleChangeTag = (value: string, name: string) => {
    setDataForm({ ...(dataForm as ICreateGame), [name]: value });
  };
  return (
    <Form
      form={form}
      className="form-user"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 14 }}
      onFinish={handleSubmint}
      layout="horizontal"
      style={{ maxWidth: 1200, width: '100%' }}
      size={'large'}
    >
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item
            rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
            name="title"
            label="Tên game"
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item name={'subtitle'} label="Phụ đề">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item label="Hình ảnh game">
            <UploadImage handleChangeImg={handleChangeImg} nameImg={'web_pic'} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item
            rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
            name={'game_type'}
            initialValue={optionsApplyType[0]}
            label="Loại game"
          >
            <Select
              showSearch
              placeholder="Chọn loại ví"
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              options={optionsApplyType}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item name={'params'} label="Thông số game">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item rules={[{ required: true, message: 'Vui lòng không được để trống' }]} label="Tag">
            <TagComponent handleChangeTag={handleChangeTag} name={'tags'} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item
            rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
            name="status"
            initialValue={true}
            label="Trạng thái"
          >
            <Switch defaultChecked />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item label=" " colon={false}>
            <Button style={{ width: '100%' }} type="primary" htmlType="submit">
              Thêm mới
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
