import InviteUserComponent from '@components/admin/manager-user/list-invite-user';
import React from 'react';

export default function InviteUserListPage() {
  return (
    <>
      <InviteUserComponent />
    </>
  );
}
