import * as React from 'react';
import { Badge, Button, Dropdown, Empty, Menu, MenuProps, Space } from 'antd';
import Logout from '@feature/auth/logout';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { Link, useNavigate } from 'react-router-dom';
import { BellOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import Notify from '@services/Notify';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { getAllNotify } from '@store/notifySlice';
const menu = (
  <Menu
    items={[
      {
        label: <Logout />,
        key: '0',
      },
    ]}
  />
);
const itemInfo: MenuProps['items'] = [
  {
    key: '1',
    label: (
      <Link to="/info-user">
        <div className="drop-item">
          <UserOutlined />
          Thông tin cá nhân
        </div>
      </Link>
    ),
  },
  {
    key: '2',
    label: (
      <Link to="/reset-password">
        <div className="drop-item">
          <LockOutlined />
          Đổi mật khẩu
        </div>
      </Link>
    ),
  },
  {
    key: '3',
    label: <Logout />,
  },
];

type Props = {
  activeSound: boolean;
  createAudioContext: () => void;
};

export default function HeaderComponent({ activeSound, createAudioContext }: Props) {
  const { data } = useAppSelector((state) => state.authSlice);
  const { data: DataNotify, newNotify } = useAppSelector((state) => state.notifySlice);
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const loadMoreItems = () => {
    // Simulate loading data from an API
    setLoading(true);
    setTimeout(() => {
      const newItems = [...items /* new data */];
      setItems(newItems);
      setLoading(false);
    }, 1000);
  };

  const updateNotify = async (id: number, url: string) => {
    try {
      await Notify.update({ id });
      navigate(url);
      dispatch(getAllNotify({}));
    } catch (error) {}
  };

  const handleReadAll = async () => {
    try {
      await Notify.updateAllNotifyRing();
      dispatch(getAllNotify({ paginate: 15, page: 1 }));
    } catch (error) {}
  };
  const itemNotify: MenuProps['items'] = [
    ...DataNotify.listNotify.map((item) => ({
      key: item.id,
      label: (
        <div onClick={() => updateNotify(item.id, item.url)}>
          <div className="item-notify">
            <div className="notify-left">
              <UserOutlined />
            </div>
            <div className="notify-rigth">
              <div className="content-notify">{item.title}</div>
              <div>{item.date_create}</div>
            </div>
          </div>
        </div>
      ),
    })),
  ];
  return (
    <div className="header-wrap">
      <div className="sound-active" onClick={() => createAudioContext()}>
        {activeSound ? <i className="fa-solid fa-volume-xmark"></i> : <i className="fa-solid fa-volume-off"></i>}
      </div>
      <div className="notify">
        <div className="infor">
          <Dropdown
            menu={{ items: itemNotify }}
            placement="bottomRight"
            dropdownRender={(menu) => (
              <div className="notify-wapper">
                {' '}
                <div className="title-notify">
                  <span>Thông báo</span>
                  {DataNotify.listNotify?.length > 0 ? (
                    <i className="fa-solid fa-envelope" style={{ cursor: 'pointer' }} onClick={handleReadAll}></i>
                  ) : (
                    <i className="fa-solid fa-envelope-open"></i>
                  )}
                </div>
                {DataNotify.listNotify.length === 0 ? (
                  <Empty description="Không có thông báo nào" />
                ) : (
                  <>
                    <div className="list-notify">{React.cloneElement(menu as React.ReactElement)}</div>
                    <div className="view-all">Tất cả</div>
                  </>
                )}
              </div>
            )}
          >
            <Badge count={DataNotify.listNotify?.length || 0}>
              <BellOutlined />
            </Badge>
          </Dropdown>
        </div>
      </div>
      <div className="profile">
        {/* <img className="avatar" src="" alt="" /> */}
        <UserOutlined />
        <div className="infor">
          <Dropdown menu={{ items: itemInfo }} placement="bottomRight">
            <Space>{data.username}</Space>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
