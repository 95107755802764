import {
  ICreateListPost,
  IDataRes,
  IDeleteListPost,
  IEditListPost,
  IListPost,
  IReqGetAllListPost,
} from '@interfaces/ListPost';
import httpRequest from './Api';

const ListPost = {
  getListPost({ ...rest }: Partial<IReqGetAllListPost>): Promise<IDataRes<IListPost>> {
    const url = '/list-posts';
    return httpRequest.get(url, { params: { ...rest } });
  },
  create({ ...rest }: ICreateListPost): Promise<{ messages: string }> {
    const url = '/create-post';
    return httpRequest.post(
      url,
      { ...rest },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  update({ ...rest }: IEditListPost): Promise<{ messages: string }> {
    const url = '/update-post';
    return httpRequest.post(
      url,
      { ...rest },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  delete({ ...rest }: IDeleteListPost): Promise<{ messages: string }> {
    const url = '/delete-post';
    return httpRequest.post(url, { ...rest });
  },
};

export default ListPost;
