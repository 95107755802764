import Loading from '@components/common/loading';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { Button, Layout, message, notification } from 'antd';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import HeaderComponent from './Header';
import Siders from './Sider';
import './style.scss';
import { getCurrentUser } from '@store/authSlice';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { getAllNotify, getAllNotifyRing, newNotify } from '@store/notifySlice';
import Pusher from 'pusher-js';
import Cookies from 'universal-cookie';
import { getAllFile } from '@store/fileSlice';
import { getAllSetting } from '@store/settingSlice';

type Props = {};

const DefaultLayout: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const { loading, data: currentUser } = useAppSelector((state) => state.authSlice);
  const audioContextRef = useRef<AudioContext | null>(null);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [breakPoint, setBreakPoint] = useState<boolean>(false);
  const { data: DataFile } = useAppSelector((state) => state.fileSlice);
  const {
    data: { listNotify, ring_notify, time_notify },
  } = useAppSelector((state) => state.notifySlice);
  const [playRing, setPlayRing] = useState<boolean>(false);
  const [audio, setAudio] = useState<any>();

  const { Header, Content } = Layout;
  const callback = () => {
    navigate('/login');
  };

  const handlePusherSubscription = useCallback(
    (data: string) => {
      try {
        const res = JSON.parse(data);
        dispatch(newNotify(res));
        dispatch(getAllNotify({}));
        playAudio(audio);
        notification.success({ message: 'Có thông báo mới', duration: 3 });
      } catch (err: any) {
        notification.error({ message: err?.message, duration: 3 });
      }
    },
    [audio],
  );

  useEffect(() => {
    if (currentUser?.id) {
      Pusher.logToConsole = true;

      const cookies = new Cookies();
      const token = cookies.get('userToken');

      if (!token) {
        callback();
      }

      const pusher = new Pusher('e2914b3a4de90b95333b', {
        cluster: 'ap1',
        authEndpoint: 'https://api.viva88viet.net/admin/v1/pusher_auth',
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      const channel = pusher.subscribe(`private-notification.user.${currentUser.id}`);
      channel.bind('notifications', handlePusherSubscription);

      return () => {
        pusher.unsubscribe(`private-notification.user.${currentUser.id}`);
        channel.unbind('notifications', handlePusherSubscription);
      };
    }
  }, [currentUser?.id]);

  useEffect(() => {
    dispatch(getCurrentUser({ callback }));
    dispatch(getAllNotify({}));
    dispatch(getAllNotifyRing());
    dispatch(getAllFile({}));
    dispatch(getAllSetting());
  }, []);

  useEffect(() => {
    (async () => {
      if (playRing && ring_notify) {
        const rings = await fetchAudioBuffer(ring_notify);
        setAudio(rings);
      }
    })();
  }, [playRing, ring_notify]);

  useEffect(() => {
    (async () => {
      if (playRing && audio) {
        if (listNotify.length) {
          const intervalId = setInterval(async () => {
            playAudio(audio);
          }, time_notify);

          // Clean up the timer when the component unmounts or when time_notify changes
          return () => {
            clearInterval(intervalId);
          };
        }
      }
    })();
  }, [playRing, audio, listNotify.length]);

  const createAudioContext = () => {
    if (!audioContextRef.current && !playRing) {
      audioContextRef.current = new AudioContext();
      setPlayRing(true);
      notification.success({ message: 'Mở chuông thông báo thành công', duration: 3 });
    }
    // else {
    //   audioContextRef.current = null;
    //   setPlayRing(false);
    //   notification.success({ message: 'Tắt chuông thông báo thành công', duration: 3 });
    // }
  };

  const fetchAudioBuffer = async (url: string) => {
    try {
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      const audioContext = new AudioContext();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
      audioContextRef.current = audioContext;
      return audioBuffer;
    } catch (err: any) {
      notification.error({ message: err?.message, duration: 3 });
      return null;
    }
  };

  const playAudio = (audioBuffer: AudioBuffer) => {
    const audioSource = audioContextRef.current?.createBufferSource();
    if (audioSource) {
      audioSource.buffer = audioBuffer;
      const destination = audioContextRef.current?.destination;
      if (destination) {
        audioSource.connect(destination);
      }
      audioSource.start();
    }
  };

  return (
    <>
      {/* <Loading /> */}
      <Layout className="layout-default">
        <Siders setCollapsedChange={setCollapsed} collapsedTogole={collapsed} setBreakPoint={setBreakPoint} />

        <Layout
          style={{ paddingLeft: 1 }}
          className={`${!collapsed ? 'custom-size' : 'custom-size-collapsed'} ${
            !collapsed && breakPoint ? 'sider-fix' : ''
          }`}
        >
          <Header className={`site-layout-background layout-header`} style={{}}>
            {' '}
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: '16px',
                width: 64,
                height: 64,
              }}
            />
            <HeaderComponent activeSound={playRing} createAudioContext={createAudioContext} />
          </Header>
          <Content
            className="background-content"
            style={{
              // margin: 15,
              padding: 15,
              minHeight: 280,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default DefaultLayout;
