import React, { useState } from 'react';
import { Button, Col, Form, Input, Radio, Row } from 'antd';
import { updatePointPrice } from '@store/pointPrice';
import { useAppDispatch } from '@hook/index';
import { ICreatePointPrice, IEditPointPrice, IPointPrice } from '@interfaces/PointPrice';
import { convert } from '@utils/convert';

export interface IFormEditComponent {
  data: IPointPrice;
  handleCloseScreen: (type: string) => void;
  getData: (message?: string) => void;
}
export default function FormEditComponent({ handleCloseScreen, getData, data }: IFormEditComponent) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [dataForm, setDataForm] = useState<IEditPointPrice>({
    point: convert.moneyInput(Number(data.point)),
    price: convert.moneyInput(Number(data.price)),
    title: data.title,
    type_user: data.type_user,
    id: data.id,
  });

  // const handleSubmint = (event: IEditPointPrice) => {
  //   setDataForm(event);
  //   dispatch(updatePointPrice({ param: { ...dataForm, ...event }, callback: getData }));
  // };
  const handleSubmint = (event: IEditPointPrice) => {
    setDataForm(event);
    dispatch(
      updatePointPrice({
        param: {
          ...dataForm,
          ...event,
          title: event.title,
          price: event.price.replace(/\./g, '').replace(/,/g, ''),
          point: event.point.replace(/\./g, '').replace(/,/g, ''),
          id: data.id,
        },
        callback: getData,
      }),
    );
  };
  const handleValueChange = (event: any) => {
    if (event.title) form.setFieldsValue({ ...event, title: event.title });
    if (event.price)
      form.setFieldsValue({ ...event, price: convert.moneyInput(Number(event.price.replace(/,/g, ''))) });
    if (event.point)
      form.setFieldsValue({ ...event, point: convert.moneyInput(Number(event.point.replace(/,/g, ''))) });
  };
  return (
    <Form
      form={form}
      className="form-user form-col"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      initialValues={dataForm}
      onFinish={handleSubmint}
      onValuesChange={handleValueChange}
      layout="horizontal"
      style={{ maxWidth: 1200, width: '100%' }}
      size={'large'}
    >
      <Row gutter={10} justify="center">
        <Col span={10}>
          <Form.Item rules={[{ required: true, message: 'Vui lòng không được để trống' }]} name="title">
            <Input placeholder="Mệnh giá" />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item rules={[{ required: true, message: 'Vui lòng không được để trống' }]} name="price">
            <Input placeholder="Giá" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="center">
        <Col span={10}>
          <Form.Item rules={[{ required: true, message: 'Vui lòng không được để trống' }]} name="point">
            <Input placeholder="Điểm" />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
            label="Vai trò"
            name="type_user"
          >
            <Radio.Group>
              <Radio value={0}>Đại lí</Radio>
              <Radio value={1}>Thành viên</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={10} justify="center">
        <Col span={20}>
          <Button style={{ width: '100%' }} type="primary" htmlType="submit">
            Cập nhật
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
