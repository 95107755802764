import { DeleteOutlined, PlusOutlined, UndoOutlined } from '@ant-design/icons';
import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import Upload from '@services/Upload';
type IUploadFileImg = {
  handleChangeImg: (value: any, nameImg: string) => void;
  edit?: boolean;
  urlImg?: string;
  nameImg: string;
};
function UploadFileImg({ handleChangeImg, urlImg, nameImg, edit }: IUploadFileImg) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imageUrl, setImageUrl] = useState('');
  const handleImageUpload = async (event) => {
    if (event.target.files[0]) {
      const file = event.target.files[0];
      const src = URL.createObjectURL(file);
      setImageUrl(src);
      handleChangeImg(file, nameImg);
    }
  };
  useEffect(() => {
    if (urlImg === null || typeof urlImg === 'object') return;
    setImageUrl(urlImg || '');
  }, [urlImg]);
  const handleImageDelete = () => {
    setImageUrl('');
    handleChangeImg('', nameImg);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  const uploadButton = (
    <label className="btn-upload" htmlFor={`upload${nameImg}`}>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </label>
  );

  return (
    <div>
      <input
        ref={fileInputRef}
        id={`upload${nameImg}`}
        className="upload-img"
        type="file"
        onChange={handleImageUpload}
      />
      {!imageUrl && uploadButton}
      {!!imageUrl && (
        <div className="wrap-img">
          <div className="action-img">
            {edit && (
              <label htmlFor={`upload${nameImg}`} className="btn-change">
                <UndoOutlined />
              </label>
            )}
            <div className="btn-delete">
              <DeleteOutlined onClick={handleImageDelete} />
            </div>
          </div>

          <img src={imageUrl} alt="Uploaded" />
        </div>
      )}
    </div>
  );
}

export default UploadFileImg;
