import { useAppDispatch, useAppSelector } from '@hook/index';
import { getAllUser } from '@store/userSlice';
import React, { useEffect, useState } from 'react';
import FormSearch from './form/search';
import Container from '@components/common/container';
import EditRow from './table/Edit';
import { IUser } from '@interfaces/User';
import './style.scss';
import CreateRow from './table/Create';
import { AGENT, USER } from '@constants/text';
import { ParamReq } from '@interfaces/CommonInterface';
import showMessage from '@utils/helper';
import ListMember from './table/ListMember';
import { getAllProfitStatistics } from '@store/profitStatisticsSlice';
import { getAllLevel } from '@store/levelSlice';
import { getAllpointPrice } from '@store/pointPrice';

export default function AgentComponent() {
  const dispatch = useAppDispatch();
  const { loading, data } = useAppSelector((state) => state.userSlice);
  const [param, setParam] = useState<ParamReq>();
  const [type, setType] = useState<string>('table');
  const [editData, setEditData] = useState<IUser>();
  const getData = (message?: string) => {
    showMessage(message);
    dispatch(
      getAllUser({
        type_user: AGENT.type_user,
        is_active: param?.is_active || '',
        time_range: param?.time_range || '',
        paginate: param?.paginate || USER.LIMIT,
        page: param?.page || USER.PAGE,
        keysearch: param?.keysearch || '',
      }),
    );
    handleChangTypeScreen('table');
  };
  useEffect(() => {
    getData();
    dispatch(getAllpointPrice({ type_user: '0' }));
    dispatch(getAllProfitStatistics({}));
    dispatch(getAllLevel({}));
  }, []);

  useEffect(() => {
    if (!param) return;
    getData();
  }, [param]);
  type IContent = { type: string; component: React.ReactNode };

  const handleChangTypeScreen = (typeCurrent: string) => {
    setType(typeCurrent);
  };

  const handleEdit = (data: IUser, type: string) => {
    setEditData(data);
    setType(type);
  };

  const handleChangeParam = (paramChange: ParamReq) => {
    setParam({ ...param, ...paramChange });
  };
  const TableRender = () => {
    return (
      <>
        <FormSearch handleChangeParam={handleChangeParam} paramCurrent={param} />
        <ListMember
          getData={getData}
          data={data.listUser}
          current_page={data.current_page}
          total={data.total}
          per_page={data.per_page}
          loading={loading}
          handleChangeParam={handleChangeParam}
          onEdit={handleEdit}
          onCreate={handleChangTypeScreen}
        />
      </>
    );
  };

  const EditRowRender = () => {
    if (editData)
      return (
        <>
          <EditRow data={editData} handleCloseScreen={handleChangTypeScreen} getData={getData} />
        </>
      );
  };

  const CreateRowRender = () => {
    return (
      <>
        <CreateRow handleCloseScreen={handleChangTypeScreen} getData={getData} />
      </>
    );
  };
  const Content: IContent[] = [
    {
      type: 'table',
      component: TableRender(),
    },
    {
      type: 'create',
      component: CreateRowRender(),
    },
    {
      type: 'edit',
      component: EditRowRender(),
    },
  ];
  return <Container screen={Content} type={type} />;
}
