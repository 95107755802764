import Statisticals from '@services/Statisticals';
import * as React from 'react';
import CountUp from 'react-countup';

export interface IStatisticalProps {}

export default function Statistical(props: IStatisticalProps) {
  const [data, setData] = React.useState<any>();
  React.useEffect(() => {
    const getData = async () => {
      const res = await Statisticals.getStatisticals();
      setData(res);
    };
    getData();
  }, []);
  return (
    <div className="statistical">
      <div className="statistical__item color-1">
        <p className="title">Tổng số đại lí</p>
        <p className="value">{data ? <CountUp start={0} end={data.totalAgent} duration={3} /> : '0'}</p>
      </div>
      <div className="statistical__item color-2">
        <p className="title">Tổng số thành viên</p>
        <p className="value">
          <p className="value">{data ? <CountUp start={0} end={data.totalMember} duration={3} /> : '0'}</p>
        </p>
      </div>
      <div className="statistical__item color-3">
        <p className="title">
          Đại lý mới
          <br />
          hôm nay
        </p>
        <p className="value">
          <p className="value">{data ? <CountUp start={0} end={data.agentRegisterToday} duration={3} /> : '0'}</p>
        </p>
      </div>
      <div className="statistical__item color-4">
        <p className="title">
          Thành viên mới
          <br />
          hôm nay
        </p>
        <p className="value">
          <p className="value">{data ? <CountUp start={0} end={data.memberRegisterToday} duration={3} /> : '0'}</p>
        </p>
      </div>
      <div className="statistical__item color-5 item-w-50">
        <p className="title">Tổng điểm nạp hôm nay</p>
        <p className="value">
          <p className="value">{data ? <CountUp start={0} end={data.totalPointToday} duration={3} /> : '0'}</p>
        </p>
      </div>
      <div className="statistical__item color-5 item-w-50">
        <p className="title">Tổng điểm nạp hôm qua</p>
        <p className="value">
          <p className="value">{data ? <CountUp start={0} end={data.totalPointYesterday} duration={3} /> : '0'}</p>
        </p>
      </div>
    </div>
  );
}
