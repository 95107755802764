import { IGameUser, IGames } from '@interfaces/GameUser';
import { convert } from '@utils/convert';
import { Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import * as React from 'react';

export interface IViewTableProps {
  data: IGameUser;
  handleClose: () => void;
}

export default function ViewTable(props: IViewTableProps) {
  const { data, handleClose } = props;
  const columns: ColumnsType<IGames> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      width: 50,
    },
    {
      title: 'Tên người dùng',
      dataIndex: 'username',
      key: 'username',
      align: 'center',
    },
    {
      title: 'Mật khẩu',
      dataIndex: 'password',
      key: 'password',
      align: 'center',
    },
    {
      title: 'Điểm số',
      dataIndex: 'point',
      key: 'point',
      align: 'center',
    },
    {
      title: 'Tên game',
      dataIndex: 'game',
      key: 'game',
      align: 'center',
      render: (_, record) => <div>{record.game.title}</div>,
    },
    {
      title: 'Cập nhật lúc',
      dataIndex: 'updated_at',
      key: 'updated_at',
      align: 'center',
      width: 250,
      render: (_, record) => <Space size="middle">{convert.convertDateFormat(record.updated_at)}</Space>,
    },
    {
      title: 'Tạo lúc',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'center',
      width: 250,
      render: (_, record) => <Space size="middle">{convert.convertDateFormat(record.created_at)}</Space>,
    },
  ];
  return (
    <div className="m-top">
      <Table
        className="table wrap-col"
        scroll={{ x: 960 }}
        pagination={false}
        columns={columns}
        dataSource={data.games}
        rowKey={(record) => record.id}
      />
    </div>
  );
}
