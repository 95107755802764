import React, { useState } from 'react';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { useAppDispatch } from '@hook/index';
import './style.scss';
import { IEditLevel, ILevel } from '@interfaces/Level';
import { updateLevel } from '@store/levelSlice';
import { convert } from '@utils/convert';
import UploadFileImg from '@components/common/upload/UploadFileImg';
export interface IFormCreateComponent {
  handleCloseScreen: (type: string) => void;
  getData: (message?: string) => void;
}

const optionsLevelup_type = [
  {
    value: '1',
    label: 'Số tiền gửi đạt tiêu chuẩn',
  },
  {
    value: '2',
    label: 'Số tiền đặt cược đạt tiêu chuẩn',
  },
  {
    value: '3',
    label: 'Tất cả đạt tiêu chuẩn',
  },
  {
    value: '4',
    label: 'Bất kỳ số tiền nào đạt tiêu chuẩn',
  },
];

export interface IFormEditComponent {
  handleCloseScreen: (type: string) => void;
  getData: (message?: string) => void;
  data: ILevel;
}
export default function FormEditComponent(props: IFormEditComponent) {
  const { getData, data } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [dataForm, setDataForm] = useState<IEditLevel>({
    level: data.level,
    level_name: data.level_name,
    deposit_money: convert.moneyInput(parseInt(data.deposit_money)),
    withdrawal_today: data.withdrawal_today,
    level_bonus: convert.moneyInput(parseInt(data.level_bonus)),
    day_bonus: convert.moneyInput(parseInt(data.day_bonus)),
    week_bonus: convert.moneyInput(parseInt(data.week_bonus)),
    month_bonus: convert.moneyInput(parseInt(data.month_bonus)),
    year_bonus: convert.moneyInput(parseInt(data.year_bonus)),
    credit_bonus: convert.moneyInput(parseInt(data.credit_bonus)),
    levelup_type: {
      value: optionsLevelup_type[Number(data.levelup_type) - 1]?.value,
      label: optionsLevelup_type[Number(data.levelup_type) - 1]?.label,
    },
    image: data.image || '',
  });

  const handleSubmint = (event: IEditLevel) => {
    setDataForm(event);

    dispatch(
      updateLevel({
        param: {
          ...dataForm,
          ...event,
          id: data.id,
          levelup_type: typeof event.levelup_type === 'object' ? event.levelup_type.value : event.levelup_type,
          deposit_money: event.deposit_money && String(event.deposit_money).replace(/\./g, '').replace(/,/g, ''),
          level_bonus: event.level_bonus && String(event.level_bonus).replace(/\./g, '').replace(/,/g, ''),
          day_bonus: event.day_bonus && String(event.day_bonus).replace(/\./g, '').replace(/,/g, ''),
          week_bonus: event.week_bonus && String(event.week_bonus).replace(/\./g, '').replace(/,/g, ''),
          month_bonus: event.month_bonus && String(event.month_bonus).replace(/\./g, '').replace(/,/g, ''),
          year_bonus: event.year_bonus && String(event.year_bonus).replace(/\./g, '').replace(/,/g, ''),
          credit_bonus: event.credit_bonus && String(event.credit_bonus).replace(/\./g, '').replace(/,/g, ''),
        },
        callback: getData,
      }),
    );
  };
  const handleValueChange = (event: any) => {
    if (event.deposit_money)
      form.setFieldsValue({
        ...event,
        deposit_money: convert.moneyInput(Number(event.deposit_money.replace(/,/g, ''))),
      });

    if (event.level_bonus)
      form.setFieldsValue({ ...event, level_bonus: convert.moneyInput(Number(event.level_bonus.replace(/,/g, ''))) });
    if (event.day_bonus)
      form.setFieldsValue({ ...event, day_bonus: convert.moneyInput(Number(event.day_bonus.replace(/,/g, ''))) });
    if (event.week_bonus)
      form.setFieldsValue({ ...event, week_bonus: convert.moneyInput(Number(event.week_bonus.replace(/,/g, ''))) });
    if (event.month_bonus)
      form.setFieldsValue({ ...event, month_bonus: convert.moneyInput(Number(event.month_bonus.replace(/,/g, ''))) });
    if (event.year_bonus)
      form.setFieldsValue({ ...event, year_bonus: convert.moneyInput(Number(event.year_bonus.replace(/,/g, ''))) });
    if (event.credit_bonus)
      form.setFieldsValue({ ...event, credit_bonus: convert.moneyInput(Number(event.credit_bonus.replace(/,/g, ''))) });
  };

  const handleChangeImg = (url: any, name: string) => {
    setDataForm({ ...(dataForm as IEditLevel), [name]: url });
  };
  return (
    <>
      <Form
        form={form}
        className="form-event"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        initialValues={dataForm}
        onValuesChange={handleValueChange}
        onFinish={handleSubmint}
        style={{ maxWidth: 1200, width: '100%' }}
        size={'large'}
      >
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label="Hình ảnh">
              <UploadFileImg urlImg={data.image || ''} nameImg={'image'} handleChangeImg={handleChangeImg} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name="level"
              label="Mức độ"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name="level_name"
              label="Tên cấp bậc"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name={'deposit_money'}
              label="Số tiền gửi khuyến mại"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name={'withdrawal_today'}
              label="Số lần rút trong ngày"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name={'level_bonus'}
              label="Quà khuyến mãi"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name={'day_bonus'}
              label="Quà tặng hàng ngày"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name={'week_bonus'}
              label="Quà tặng hàng tuần"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name={'month_bonus'}
              label="Quà tặng hàng tháng"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name={'year_bonus'}
              label="Quà tặng hàng năm"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name={'credit_bonus'}
              label="Phần thưởng vay tiền"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name={'levelup_type'}
              label="Loại điều kiện khuyến mãi"
            >
              <Select
                showSearch
                allowClear
                placeholder="Chọn cách áp dụng"
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={optionsLevelup_type}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label=" " colon={false}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                Cập nhật
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}
