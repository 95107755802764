import GameComponent from '@components/admin/game';
import React from 'react';

export default function GamePage() {
  return (
    <>
      <GameComponent />
    </>
  );
}
