import React, { FormEvent, useEffect, useState } from 'react';
import { Button, Col, Form, Input, Radio, Row, Select } from 'antd';
import { useAppDispatch, useAppSelector } from '@hook/index';
import UploadMutipleImage from '@components/common/upload/UploadMutipleImage';
import UploadFileImg from '@components/common/upload/UploadFileImg';
import { ICreateAdmin } from '@interfaces/listAdmin';
import { createAdmin } from '@store/adminSlice';
import { getAllRole } from '@store/roleSlice';

export interface IFormCreateComponent {
  handleCloseScreen: (type: string) => void;
  getData: (message?: string) => void;
}
export default function FormCreateComponent({ handleCloseScreen, getData }: IFormCreateComponent) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading, data: dataRole } = useAppSelector((state) => state.roleSlice);
  const [dataForm, setDataForm] = useState<ICreateAdmin>();

  const handleSubmint = (event: ICreateAdmin) => {
    setDataForm(event);
    dispatch(createAdmin({ param: { ...dataForm, ...event }, callback: getData }));
  };

  const handleChangeImg = (url: any, name: string) => {
    setDataForm({ ...(dataForm as ICreateAdmin), [name]: url });
  };

  const handleChangeImgMuti = (imgList: any) => {
    setDataForm({ ...(dataForm as ICreateAdmin), 'image_other[]': imgList });
  };
  return (
    <Form
      form={form}
      className="form-user form-col"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      onFinish={handleSubmint}
      layout="horizontal"
      style={{ maxWidth: 960, width: '100%' }}
      size={'large'}
    >
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item label="Tải hình lên">
            <UploadFileImg nameImg={'avatar'} handleChangeImg={handleChangeImg} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item label="Giấy tời khác">
            <UploadMutipleImage handleChangeImg={handleChangeImgMuti} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item
            rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
            label="Tên thật"
            name="real_name"
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item
            rules={[{ required: false, message: 'Vui lòng không được để trống' }]}
            label="Danh xưng"
            name="nick_name"
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item
            rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
            label="Tên đăng nhập"
            name="username"
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item
            rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
            label="Mật khẩu"
            name="password"
          >
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item
            rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
            label="Xác nhận mật khẩu"
            name="password_confirm"
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item
            rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
            label="Giới tính"
            initialValue={0}
            name="gender"
          >
            <Radio.Group defaultValue={0}>
              <Radio value={0}>Nam</Radio>
              <Radio value={1}>Nữ</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item
            rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
            label="Chọn nhóm quyền"
            name="role_name"
          >
            <Select
              placeholder="Chọn nhóm quyền"
              options={dataRole.role.map((value, index) => ({
                label: value.description,
                value: value.name,
                key: value.id,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item
            rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
            label="Điện thoại"
            name="mobile"
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item rules={[{ required: false, message: 'Vui lòng không được để trống' }]} label="Email" name="email">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item label="Zalo" name="zalo">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item label="telegram" name="telegram">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item label="viber" name="viber">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item label=" " colon={false}>
            <Button style={{ width: '100%' }} type="primary" htmlType="submit">
              Thêm mới
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
