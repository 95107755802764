import { PayloadCallBack } from '@interfaces/CommonInterface';
import { IDeleteLogs, StateLogs, IDataLogs, IReqGetAllLogs } from '@interfaces/Log';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Logs from '@services/Logs';
import { BANK_USER } from '@constants/text';

const initialState: StateLogs<IDataLogs> = {
  data: {
    listLogs: [],
    current_page: BANK_USER.PAGE,
    per_page: BANK_USER.LIMIT,
    total: 1,
  },
  loading: false,
  error: '',
};

export const getAllLogs = createAsyncThunk('Logs/getAll', async (payload: Partial<IReqGetAllLogs>, { getState }) => {
  try {
    const { data: dataRes } = await Logs.getLogs({ ...payload });
    const { data, current_page, total, per_page } = dataRes;
    return {
      listLogs: [...data],
      current_page,
      total,
      per_page,
    };
  } catch (error) {
    console.log(error);
  }
});

export const deleteLogs = createAsyncThunk(
  'Logs/delete',
  async (payload: PayloadCallBack<IDeleteLogs>, { getState }) => {
    try {
      const { messages } = await Logs.delete({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

const logSlice = createSlice({
  name: 'Logs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('Logs/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Logs/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Logs/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default logSlice.reducer;
