import { PayloadCallBack } from '@interfaces/CommonInterface';
import {
  ICreateTransferMoney,
  IDeleteTransferMoney,
  IEditTransferMoney,
  StateTransferMoney,
  IDataTransferMoney,
  IReqGetAllTransferMoney,
  IUpdateTransferEvent,
} from '@interfaces/TransferMoney';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import TransferMoney from '@services/TransferMoney';
import { BANK_USER } from '@constants/text';

const initialState: StateTransferMoney<IDataTransferMoney> = {
  data: {
    listTransferMoney: [],
    current_page: BANK_USER.PAGE,
    per_page: BANK_USER.LIMIT,
    total: 1,
  },
  loading: false,
  error: '',
};

export const getAllTransferMoney = createAsyncThunk(
  'TransferMoney/getAll',
  async (payload: Partial<IReqGetAllTransferMoney>, { getState }) => {
    try {
      const { data: dataRes } = await TransferMoney.getTransferMoney({ ...payload });
      const { data, current_page, total, per_page } = dataRes;
      return {
        listTransferMoney: [...data],
        current_page,
        total,
        per_page,
      };
    } catch (error) {
      console.log(error);
    }
  },
);

export const createTransferMoney = createAsyncThunk(
  'TransferMoney/create',
  async (payload: PayloadCallBack<ICreateTransferMoney>, { getState }) => {
    try {
      const { messages } = await TransferMoney.create({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const updateTransferMoney = createAsyncThunk(
  'TransferMoney/edit',
  async (payload: PayloadCallBack<IEditTransferMoney>, { getState }) => {
    try {
      const { messages } = await TransferMoney.update({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const deleteTransferMoney = createAsyncThunk(
  'TransferMoney/delete',
  async (payload: PayloadCallBack<IDeleteTransferMoney>, { getState }) => {
    try {
      const { messages } = await TransferMoney.delete({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const updateTransferMoneyEvent = createAsyncThunk(
  'TransferMoney/event',
  async (payload: PayloadCallBack<IUpdateTransferEvent>, { getState }) => {
    try {
      const { messages } = await TransferMoney.updateEvent({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

const transferMoneySlice = createSlice({
  name: 'TransferMoney',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('TransferMoney/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('TransferMoney/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('TransferMoney/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default transferMoneySlice.reducer;
