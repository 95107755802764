import { FastBackwardOutlined } from '@ant-design/icons';
import * as React from 'react';
import IFormEditComponent from '../form/Edit';
import { ICategory } from '@interfaces/Category';

export interface IEditRowProps {
  data: ICategory;
  handleCloseScreen: (type: string) => void;
  getData: (message?: string) => void;
  listCategory: ICategory[];
}

export default function EditRow(props: IEditRowProps) {
  const { data, handleCloseScreen, getData, listCategory } = props;
  return (
    <div className="screen-option">
      <div className="header">
        <h2 className="title-screen">Chỉnh sửa</h2>
        <button className="btn-back" onClick={() => handleCloseScreen('table')}>
          <FastBackwardOutlined /> Trở về
        </button>
      </div>
      <div className="body-screen">
        <IFormEditComponent
          listCategory={listCategory}
          getData={getData}
          handleCloseScreen={handleCloseScreen}
          data={data}
        />
      </div>
    </div>
  );
}
