import { useAppDispatch } from '@hook/index';
import { authLogout } from '@store/authSlice';
import { Button } from 'antd';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import { LogoutOutlined } from '@ant-design/icons';

export default function Logout() {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();

  /**
   * @description redirect to login when logout
   */
  const redirectLogout = () => {
    navigate('/login');
  };

  /**
   * @description handle get api logout
   */
  const handleLogout = () => {
    dispatch(authLogout({ isLogin: false, redirectLogout }));
  };
  return (
    <>
      <div onClick={handleLogout} className="drop-item">
        <LogoutOutlined />
        Thoát
      </div>
    </>
  );
}
