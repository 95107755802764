import { Button, message } from 'antd';
import React, { useState } from 'react';
import './style.scss';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { deleteUser, getAllUser } from '@store/userSlice';
import { updateTransferMoney } from '@store/transferMoneySlice';
export interface IDeleteProps {
  id: number;
  title: string;
  status: number;
  handleClose: () => void;
  getData: (message?: string) => void;
}

export default function Accept({ id, handleClose, getData, status, title }: IDeleteProps) {
  const dispatch = useAppDispatch();
  const callback = (message?: string) => {
    getData(message);
    handleClose();
  };
  const handleDelete = async () => {
    dispatch(updateTransferMoney({ param: { transfer_id: id, status }, callback }));
  };
  return (
    <div className="wrapper-delete">
      <div className="body-delete">
        <div className="message">{title}</div>
        <div className="group-btn">
          <Button onClick={handleDelete} type="primary">
            Đồng ý
          </Button>
          <Button onClick={() => handleClose()} type="primary" danger>
            Hủy bỏ
          </Button>
        </div>
      </div>
    </div>
  );
}
