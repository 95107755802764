import React, { useState } from 'react';
import { Button, Checkbox, Col, Form, Input, Row, Switch } from 'antd';
import { useAppDispatch, useAppSelector } from '@hook/index';
import './style.scss';
import { createBank } from '@store/bankSlice';
import { ICreateRole } from '@interfaces/Role';
import { convert } from '@utils/convert';
import { createRole } from '@store/roleSlice';
export interface IFormCreateComponent {
  handleCloseScreen: (type: string) => void;
  getData: (message?: string) => void;
}

export default function FormCreateComponent(props: IFormCreateComponent) {
  const { getData } = props;
  const [form] = Form.useForm();
  const { loading, data } = useAppSelector((state) => state.roleSlice);
  const dispatch = useAppDispatch();
  const [dataForm, setDataForm] = useState<ICreateRole>();

  const handleSubmint = (event: ICreateRole & { [key: string]: number[] }) => {
    let permission: number[] = [];
    for (let value of Object.values(event)) {
      if (typeof value === 'object') {
        permission = [...permission, ...(value as number[])];
      }
    }
    setDataForm({
      ...(dataForm as ICreateRole),
      name: event.name,
      description: event.description,
      permissions: permission,
    });
    dispatch(
      createRole({
        param: {
          ...dataForm,
          name: event.name,
          description: event.description,
          permissions: permission,
        },
        callback: getData,
      }),
    );
  };
  return (
    <>
      <Form
        form={form}
        className="form-role"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
        onFinish={handleSubmint}
        style={{ maxWidth: 1200, width: '100%' }}
        size={'large'}
      >
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name="name"
              label="Tên nhóm quyền"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name="description"
              label="Mô tả"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        {convert.filterRoleWithRoute(data.permissions).map((item, index) => {
          return (
            <Row gutter={10} justify="start" key={item.name_route}>
              <Col span={24}>
                <Form.Item
                  rules={[{ required: false, message: 'Vui lòng không được để trống' }]}
                  name={item.name_route}
                  initialValue={item.group.map((subItem) => subItem.id)}
                  label={item.name}
                >
                  <Checkbox.Group
                    options={item.group.map((subItem) => ({ label: subItem.label, value: subItem.id }))}
                  />
                </Form.Item>
              </Col>
            </Row>
          );
        })}

        <Row gutter={10} justify="start">
          <Col span={12}>
            <Form.Item rules={[{ required: false, message: 'Vui lòng không được để trống' }]} label=" " colon={false}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                Thêm mới
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}
