import { useAppDispatch, useAppSelector } from '@hook/index';
import { getAllUser } from '@store/userSlice';
import React, { useEffect, useState } from 'react';
import Container from '@components/common/container';
import './style.scss';
import { MEMBER, USER } from '@constants/text';
import EditRow from './table/Edit';
import CreateRow from './table/Create';
import showMessage from '@utils/helper';
import { IBonus } from '@interfaces/Bonus';
import DataTable from './table/DataTable';
import { getAllBonus } from '@store/bonusSlice';
import { ParamReq } from '@interfaces/CommonInterface';
import FormSearch from './form/search';

export default function BonusComponent() {
  const dispatch = useAppDispatch();
  const { loading, data } = useAppSelector((state) => state.bonusSlice);
  const [type, setType] = useState<string>('table');
  const [editData, setEditData] = useState<IBonus>();
  const [param, setParam] = useState<ParamReq>();
  const getData = (message?: string) => {
    showMessage(message);
    dispatch(
      getAllBonus({
        type_user: MEMBER.type_user,
        paginate: USER.LIMIT,
        page: USER.PAGE,
        time_range: param?.time_range || '',
      }),
    );
    handleChangTypeScreen('table');
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!param) return;
    getData();
  }, [param]);
  type IContent = { type: string; component: React.ReactNode };

  const handleChangTypeScreen = (typeCurrent: string) => {
    setType(typeCurrent);
  };

  const handleEdit = (data: IBonus, type: string) => {
    setEditData(data);
    setType(type);
  };
  const handleChangeParam = (paramChange: ParamReq) => {
    setParam({ ...param, ...paramChange });
  };
  const TableRender = () => {
    return (
      <>
        <FormSearch handleChangeParam={handleChangeParam} paramCurrent={param} />
        <DataTable
          getData={getData}
          data={data.listBonus}
          current_page={data.current_page}
          total={data.total}
          per_page={data.per_page}
          loading={loading}
          onEdit={handleEdit}
          onCreate={handleChangTypeScreen}
          handleChangeParam={handleChangeParam}
        />
      </>
    );
  };

  const EditRowRender = () => {
    if (editData)
      return (
        <>
          <EditRow data={editData} handleCloseScreen={handleChangTypeScreen} getData={getData} />
        </>
      );
  };

  const CreateRowRender = () => {
    return (
      <>
        <CreateRow handleCloseScreen={handleChangTypeScreen} getData={getData} />
      </>
    );
  };
  const Content: IContent[] = [
    {
      type: 'table',
      component: TableRender(),
    },
    {
      type: 'create',
      component: CreateRowRender(),
    },
    {
      type: 'edit',
      component: EditRowRender(),
    },
  ];
  return <Container screen={Content} type={type} />;
}
