import InviteAgentComponent from '@components/admin/manager-agent/list-invite-agent';
import React from 'react';

export default function InviteAgentListPage() {
  return (
    <>
      <InviteAgentComponent />
    </>
  );
}
