import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import ModalBase from '@components/common/modal/ModalBase';
import { DataRole, IRole } from '@interfaces/Role';
import { IPermissions as IPermissionsUser } from '@interfaces/User';
import { convert } from '@utils/convert';
import { Button, Checkbox, Collapse, Empty } from 'antd';
import React, { useEffect, useState } from 'react';
import Delete from '../dialog/Delete';
import { useAppSelector } from '@hook/index';
import EditTable from '@components/common/role/EditTable';
import { isCheckRole } from '@utils/helper';
import DeleteTable from '@components/common/role/DeleteTable';
import CreateTable from '@components/common/role/CreateTable';
export interface IRoleProps {
  data: DataRole;
  onEdit: (data: IRole, type: string) => void;
  onCreate: (type: string) => void;
  getData: (message?: string) => void;
}

export default function RoleList(props: IRoleProps) {
  const { data, onEdit, onCreate, getData } = props;
  const { Panel } = Collapse;
  const onChange = (key: string | string[]) => {};
  const [openModel, setOpenModal] = useState<boolean>(false);
  const [optoin, setOption] = useState({ id: 0, key: 0, title: '' });
  const [role, setRole] = useState<IPermissionsUser[]>([]);
  const { data: currentUser } = useAppSelector((state) => state.authSlice);
  useEffect(() => {
    if (!currentUser.isLogin) return;
    setRole(convert.getRole(currentUser));
  }, [currentUser]);

  const handleShowOption = (id: number, item) => {
    setOpenModal(true);
    setOption({ id, key: item.key, title: item.label });
  };

  const handleSubmit = (value: boolean) => {
    setOpenModal(value);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDelete = (id: number) => {
    setOpenModal(true);
    setOption({ id, key: 2, title: 'Xác nhận' });
  };
  const renderContentModal = (id: number, key?: number) => {
    return <Delete handleClose={handleClose} getData={getData} id={id} />;
  };

  return (
    <div className="collapse-wrapper">
      <ModalBase openModel={openModel} title={optoin?.title} handleSubmit={handleSubmit}>
        <div>{renderContentModal(optoin?.id, optoin?.key)}</div>
      </ModalBase>
      <div className="tool-bar__left">
        <CreateTable permission={isCheckRole(role, 'create')}>
          <button onClick={() => onCreate('create')} className="btn-add">
            <PlusOutlined />
            <strong>Thêm nhóm quyền</strong>
          </button>
        </CreateTable>
      </div>
      <Collapse className="list-role" onChange={onChange}>
        {isCheckRole(role, 'view role') ? (
          data?.role?.map((item, index) => {
            return (
              <Panel
                header={
                  <div className="header-collapse">
                    <div className="title-collapse">{item?.description}</div>
                    <div className="action-collapse">
                      <EditTable permission={isCheckRole(role, 'edit')}>
                        <Button onClick={() => onEdit(item, 'edit')} type="primary" icon={<EditOutlined />} />
                      </EditTable>
                      <DeleteTable permission={isCheckRole(role, 'delete')}>
                        <Button onClick={() => handleDelete(item.id)} type="primary" danger icon={<DeleteOutlined />} />
                      </DeleteTable>
                    </div>
                  </div>
                }
                key={item?.id}
              >
                <Collapse ghost>
                  {convert.filterRoleWithRouteById(data.permissions).map((level1, index) => {
                    if (level1?.children.length !== 0) {
                      return (
                        <Panel
                          className="title-level-1"
                          header={
                            <div className="role-level-1">
                              <Checkbox
                                checked={item?.permissions.findIndex((value) => value.id === level1?.id) !== -1}
                              ></Checkbox>
                              {level1?.title_name}
                            </div>
                          }
                          key={level1?.id}
                        >
                          <Collapse ghost>
                            {level1?.children.map((level2, index) => {
                              if (Array.isArray(level2?.children)) {
                                return (
                                  <Panel
                                    className="title-level-2"
                                    header={
                                      <div className="role-level-2">
                                        <Checkbox
                                          checked={
                                            item?.permissions.findIndex((value) => value?.id === level2?.id) !== -1
                                          }
                                        ></Checkbox>
                                        {level2?.title_name}
                                      </div>
                                    }
                                    key={level2?.id}
                                  >
                                    <Checkbox.Group
                                      options={level2?.children.map((item) => ({
                                        label: item?.description,
                                        value: item?.name,
                                      }))}
                                      value={item?.permissions.map((value) => value?.name)}
                                    ></Checkbox.Group>
                                  </Panel>
                                );
                              }
                              return (
                                <Panel
                                  className="title-level-2"
                                  header={
                                    <div className="role-level-2">
                                      <Checkbox
                                        checked={item?.permissions.findIndex((value) => value.id === level2?.id) !== -1}
                                      ></Checkbox>
                                      {level2?.title_name}
                                    </div>
                                  }
                                  key={level2?.id}
                                ></Panel>
                              );
                            })}
                          </Collapse>
                        </Panel>
                      );
                    }
                    return (
                      <Panel
                        className="title-level-1"
                        header={
                          <div className="role-level-1">
                            <Checkbox
                              checked={item?.permissions.findIndex((value) => value?.id === level1?.id) !== -1}
                            ></Checkbox>
                            {level1?.title_name}
                          </div>
                        }
                        key={level1?.id}
                      ></Panel>
                    );
                  })}
                </Collapse>
              </Panel>
            );
          })
        ) : (
          <Empty style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column' }} />
        )}
      </Collapse>
    </div>
  );
}
