import { HOME_SIDER_MENU_LIST, ListMenu } from '@constants/menu';
import { Layout, Menu } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import ImgLogo from '../../../assets/images/white-txt_color-eye.png';
import Logo from '@assets/images/logo-sidebar.png';
import { useAppSelector } from '@hook/index';

type Props = {
  setCollapsedChange: any;
  collapsedTogole: any;
  setBreakPoint: any;
};

const Siders = ({ setCollapsedChange, collapsedTogole, setBreakPoint }: Props) => {
  const { data } = useAppSelector((state) => state.authSlice);
  const { data: DataSetting } = useAppSelector((state) => state.settingSlice);
  const { Sider } = Layout;
  const menuFilterPermission = (): ListMenu[] => {
    return HOME_SIDER_MENU_LIST.filter((menu) => {
      return data.roles[0].permissions.findIndex((itemParent) => itemParent.path === menu.path) !== -1;
    });
  };

  const menuMapPermission = (): ListMenu[] => {
    return menuFilterPermission().map((menu) => {
      if (Array.isArray(menu.children)) {
        return {
          ...menu,
          children: menu.children.filter(
            (item) => data.roles[0].permissions.findIndex((itemParent) => itemParent.path === item.path) !== -1,
          ),
        };
      }
      return { ...menu };
    });
  };
  const menuItems = useMemo(() => {
    if (!data.isLogin || !data.roles) return;
    return menuMapPermission().map((menu) => {
      if (Array.isArray(menu.children)) {
        return {
          key: menu.path,
          icon: menu.icon,
          label: menu.name,
          children: menu.children.map(({ path, name, ...rest }) => {
            return {
              key: path,
              label: <Link to={menu.path + path}>{name}</Link>,
              ...rest,
            };
          }),
        };
      }

      return {
        key: menu.path,
        icon: menu.icon,
        label: <Link to={menu.path}>{menu.name}</Link>,
      };
    });
  }, [data]);

  return (
    <>
      <Sider
        className="wrap-sider site-layout-background"
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          zIndex: 1,
        }}
        trigger={null}
        width={250}
        breakpoint={'lg'}
        collapsedWidth={60}
        collapsible
        collapsed={collapsedTogole}
        onBreakpoint={(broken) => {
          setCollapsedChange(broken);
          setBreakPoint(broken);
        }}
      >
        <h1 className="title-logo">
          <Link to="/">
            <img src={DataSetting.listSetting.find((item) => item.key === 'logo')?.value} />
          </Link>
        </h1>
        <Menu
          // theme="dark"
          mode="inline"
          defaultSelectedKeys={['/dashboard']}
          selectedKeys={[`/${location.pathname.split('/')[2] || location.pathname.split('/')[1]}`]}
          defaultOpenKeys={[`/${location.pathname.split('/')[1]}`]}
          items={menuItems}
        />
      </Sider>
    </>
  );
};

export default Siders;
