import httpRequest from './Api';

type IImgRep = {
  messages: string;
  file_url: string;
};
const Upload = {
  img({ ...rest }: any): Promise<IImgRep> {
    const url = '/upload-image';
    return httpRequest.post(
      url,
      { ...rest },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  delete({ ...rest }: any): Promise<any> {
    const url = '/delete-image';
    return httpRequest.post(
      url,
      { ...rest },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
};

export default Upload;
