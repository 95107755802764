import React from 'react';
import { useRoutes } from 'react-router-dom';
import Login from '../pages/login/Login';
import { DefaultLayout } from '@shared/layouts';
import PrivateRoute from '@shared/layouts/PrivateRoute';
import InfoUser from '@pages/info-use';
import ResetPassword from '@pages/reset-password';
import PointPrice from '@pages/admin/point-price';
import EventPage from '@pages/admin/event';
import Level from '@pages/admin/level';
import BannerPage from '@pages/admin/banner';
import BankPage from '@pages/admin/bank';
import Role from '@pages/admin/role';
import DashBoard from '@pages/admin/dashboard';
import BankCardPage from '@pages/admin/bank-card';
import GamePage from '@pages/admin/game';
import PointUser from '@pages/admin/point-user';
// import AgentListPage from '@pages/admin/list-agent';
import UserListPage from '@pages/admin/manager-user/list-user';
import BankUserPage from '@pages/admin/manager-user/bank-user';
import TransferMoney from '@pages/admin/manager-user/transfer-money';
import TransferPoint from '@pages/admin/manager-user/transfer-point';
import GameUser from '@pages/admin/manager-user/game-user';
import InviteUserListPage from '@pages/admin/manager-user/list-invite-user';
import AgentListPage from '@pages/admin/manager-agent/list-agent';
import WithdrawMoney from '@pages/admin/manager-user/withdraw-money';
import TransferMoneyAgent from '@pages/admin/manager-agent/transfer-money-agent';
import WithdrawMoneyAgent from '@pages/admin/manager-agent/withdraw-money-agent';
import TransferPointAgent from '@pages/admin/manager-agent/transfer-point-agent';
import BankAgentPage from '@pages/admin/manager-agent/bank-agent';
import GameAgent from '@pages/admin/manager-agent/game-agent';
import InviteAgentListPage from '@pages/admin/manager-agent/list-invite-agent';
import MenuPage from '@pages/admin/menu';
import ListPage from '@pages/admin/list-page';
import CategoryPage from '@pages/admin/category';
import ListPostPage from '@pages/admin/list-post';
import ListAdmin from '@pages/admin/listAdmin';
import LogPage from '@pages/admin/log';
import FilePage from '@pages/admin/file';
import SettingPage from '@pages/admin/setting';
import BonusMemberPage from '@pages/admin/manager-user/bonus';
import BonusAgentPage from '@pages/admin/manager-agent/bonus';
import ProfitStatistics from '@pages/admin/profitStatistics';

const AuthRoutes: React.FC = () => {
  const LoginPrivate = <PrivateRoute element={Login} />;
  const elements = useRoutes([
    {
      path: '/',
      element: LoginPrivate,
    },
    {
      path: '/login',
      element: LoginPrivate,
    },
    {
      element: (
        <PrivateRoute
          element={DefaultLayout}
          meta={{
            requiresAuth: true,
          }}
        />
      ),
      children: [
        {
          path: 'reset-password',
          element: <ResetPassword />,
        },
        {
          path: 'info-user',
          element: <InfoUser />,
        },
        {
          path: 'dashboard',
          element: <DashBoard />,
        },
        {
          path: 'members',
          // element: <UserComponent />,
          children: [
            {
              path: 'clients',
              element: <UserListPage />,
            },
            {
              path: 'recharge-money-members',
              element: <TransferMoney />,
            },
            {
              path: 'withdraw-money-members',
              element: <WithdrawMoney />,
            },
            {
              path: 'transfer-point-members',
              element: <TransferPoint />,
            },

            // {
            //   path: 'transfer-point-members',
            //   element: <PointUser />,
            // },
            {
              path: 'bank-members',
              element: <BankUserPage />,
            },
            {
              path: 'game-members',
              element: <GameUser />,
            },
            {
              path: 'invite-members',
              element: <InviteUserListPage />,
            },
            {
              path: 'bonus-members',
              element: <BonusMemberPage />,
            },
          ],
        },

        {
          path: 'partners',
          // element: <UserComponent />,
          children: [
            {
              path: 'agents',
              element: <AgentListPage />,
            },
            {
              path: 'recharge-money-agents',
              element: <TransferMoneyAgent />,
            },
            {
              path: 'withdraw-money-agents',
              element: <WithdrawMoneyAgent />,
            },
            {
              path: 'transfer-point-agents',
              element: <TransferPointAgent />,
            },
            {
              path: 'bank-agents',
              element: <BankAgentPage />,
            },
            {
              path: 'game-agents',
              element: <GameAgent />,
            },
            {
              path: 'invite-agents',
              element: <InviteAgentListPage />,
            },
            {
              path: 'bonus-agents',
              element: <BonusAgentPage />,
            },
          ],
        },
        {
          path: 'finances',
          children: [
            {
              path: 'point-prices',
              element: <PointPrice />,
            },
            {
              path: 'bank-cards',
              element: <BankCardPage />,
            },
            {
              path: 'banks',
              element: <BankPage />,
            },
            {
              path: 'games',
              element: <GamePage />,
            },
            {
              path: 'profit-statistics',
              element: <ProfitStatistics />,
            },
          ],
        },
        {
          path: 'pages',
          element: <ListPage />,
        },
        {
          path: 'promotions',
          children: [
            {
              path: 'events',
              element: <EventPage />,
            },
            {
              path: 'levels',
              element: <Level />,
            },
          ],
        },
        {
          path: 'permissions',
          children: [
            {
              path: 'admins',
              element: <ListAdmin />,
            },
            {
              path: 'roles',
              element: <Role />,
            },
          ],
        },

        {
          path: 'news',
          children: [
            {
              path: 'categories',
              element: <CategoryPage />,
            },
            {
              path: 'posts',
              element: <ListPostPage />,
            },
          ],
        },
        {
          path: 'settings',
          children: [
            {
              path: 'configs',
              element: <SettingPage />,
            },
            {
              path: 'menus',
              element: <MenuPage />,
            },
            {
              path: 'logs',
              element: <LogPage />,
            },
            {
              path: 'banners',
              element: <BannerPage />,
            },
            {
              path: 'files',
              element: <FilePage />,
            },
          ],
        },
      ],
    },
  ]);

  return elements;
};

export default AuthRoutes;
