import { StopOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import * as React from 'react';

export interface ICancelStatusProps {
  children: React.ReactNode;
  permission: boolean;
}

export default function CancelStatus(props: ICancelStatusProps) {
  const { children, permission } = props;
  return (
    <>
      {permission ? (
        children
      ) : (
        <>
          <Tooltip placement="top" title={'Hủy'}>
            <Button type="primary" className="btn-cancel" icon={<StopOutlined />} disabled></Button>
          </Tooltip>
        </>
      )}
    </>
  );
}
