import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import 'jodit';
import 'jodit/build/jodit.min.css';
import JoditEditor from 'jodit-react';
import './style.scss';

type IEditor = {
  handleChangeEdit: (content: string) => void;
  content?: string;
};
const copyStringToClipboard = function (str) {
  let el: any = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style = { position: 'absolute', left: '-9999px' };
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const facilityMergeFields = [
  'FacilityNumber',
  'FacilityName',
  'Address',
  'MapCategory',
  'Latitude',
  'Longitude',
  'ReceivingPlant',
  'TrunkLine',
  'SiteElevation',
];
const inspectionMergeFields = ['InspectionCompleteDate', 'InspectionEventType'];
const createOptionGroupElement = (mergeFields, optionGrouplabel) => {
  let optionGroupElement = document.createElement('optgroup');
  optionGroupElement.setAttribute('label', optionGrouplabel);
  for (let index = 0; index < mergeFields.length; index++) {
    let optionElement = document.createElement('option');
    optionElement.setAttribute('class', 'merge-field-select-option');
    optionElement.setAttribute('value', mergeFields[index]);
    optionElement.text = mergeFields[index];
    optionGroupElement.appendChild(optionElement);
  }
  return optionGroupElement;
};
const buttons = [
  'undo',
  'redo',
  'bold',
  'strikethrough',
  'underline',
  'italic',
  'superscript',
  'subscript',
  'align',
  'ul',
  'ol',
  'outdent',
  'indent',
  'font',
  'fontsize',
  'brush',
  'paragraph',
  'image',
  'link',
  'table',
  'hr',
  'eraser',
  'copyformat',
  'fullsize',
  'selectall',
  'print',
  'source',
  {
    name: 'insertMergeField',
    tooltip: 'Insert Merge Field',
    iconURL: 'images/merge.png',
    popup: (editor, current, self, close) => {
      function onSelected(e) {
        let mergeField = e.target.value;
        if (mergeField) {
          editor.selection.insertNode(editor.create.inside.fromHTML('{{' + mergeField + '}}'));
        }
      }
      let divElement = editor.create.div('merge-field-popup');

      let labelElement: any = document.createElement('label');
      labelElement.setAttribute('class', 'merge-field-label');
      labelElement.text = 'Merge field: ';
      divElement.appendChild(labelElement);

      let selectElement = document.createElement('select');
      selectElement.setAttribute('class', 'merge-field-select');
      selectElement.appendChild(createOptionGroupElement(facilityMergeFields, 'Facility'));
      selectElement.appendChild(createOptionGroupElement(inspectionMergeFields, 'Inspection'));
      selectElement.onchange = onSelected;
      divElement.appendChild(selectElement);

      return divElement;
    },
  },
  {
    name: 'copyContent',
    tooltip: 'Copy HTML to Clipboard',
    iconURL: 'images/copy.png',
    exec: function (editor) {
      let html = editor.value;
      copyStringToClipboard(html);
    },
  },
];

const editorConfig: any = {
  readonly: false,
  toolbar: true,
  spellcheck: true,
  language: 'vi',
  toolbarButtonSize: 'small',
  toolbarAdaptive: false,
  showCharsCounter: true,
  showWordsCounter: true,
  showXPathInStatusbar: false,
  askBeforePasteHTML: false,
  askBeforePasteFromWord: true,
  //defaultActionOnPaste: "insert_clear_html",
  buttons: buttons,
  uploader: {
    insertImageAsBase64URI: true,
  },
  width: 800,
  maxHeight: 500,
};

export default function EditText({ handleChangeEdit, content }: IEditor) {
  const [data, setData] = useState<string>(content || ' ');
  const handleCreateValue = (value) => {
    if (value === null) {
      setData('...');
    }
    setData(value);
    handleChangeEdit(value);
  };
  return (
    <div className="App" style={{ maxWidth: editorConfig.width, margin: '0 auto' }}>
      <JoditEditor value={data} config={editorConfig} onBlur={handleCreateValue} />
    </div>
  );
}
