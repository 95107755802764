import { PayloadCallBack } from '@interfaces/CommonInterface';
import {
  ICreatePointPrice,
  IDeletePointPrice,
  IEditPointPrice,
  IPointPrice,
  IReqGetAllPointPrice,
  StatePointPrice,
} from '@interfaces/PointPrice';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import PointPrice from '@services/PointPrice';

const initialState: StatePointPrice<IPointPrice> = {
  data: [],
  loading: false,
  error: '',
};

export const getAllpointPrice = createAsyncThunk(
  'PointPrice/getAll',
  async (payload: Partial<IReqGetAllPointPrice>, { getState }) => {
    try {
      const { data } = await PointPrice.getPointPrice({ ...payload });
      return data.data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const createPointPrice = createAsyncThunk(
  'PointPrice/create',
  async (payload: PayloadCallBack<ICreatePointPrice>, { getState }) => {
    try {
      const { messages } = await PointPrice.create({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const updatePointPrice = createAsyncThunk(
  'PointPrice/edit',
  async (payload: PayloadCallBack<IEditPointPrice>, { getState }) => {
    try {
      const { messages } = await PointPrice.update({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const deletePointPrice = createAsyncThunk(
  'PointPrice/delete',
  async (payload: PayloadCallBack<IDeletePointPrice>, { getState }) => {
    try {
      const { messages } = await PointPrice.delete({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

const pointPriceSlice = createSlice({
  name: 'PointPrice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('PointPrice/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('PointPrice/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = [...action.payload];
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('PointPrice/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default pointPriceSlice.reducer;
