import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import * as React from 'react';

export interface IEditTableProps {
  children: React.ReactNode;
  permission: boolean;
}

export default function EditTable(props: IEditTableProps) {
  const { children, permission } = props;
  return (
    <>
      {permission ? (
        children
      ) : (
        <>
          <Button disabled type="primary" icon={<EditOutlined />} />
        </>
      )}
    </>
  );
}
