import { ICreateBanner, IDataRes, IDeleteBanner, IEditBanner, IBanner, IReqGetAllBanner } from '@interfaces/Banner';
import httpRequest from './Api';

const Banner = {
  getBanner({ ...rest }: Partial<IReqGetAllBanner>): Promise<IDataRes<IBanner>> {
    const url = '/list-banners';
    return httpRequest.get(url, { params: { ...rest } });
  },
  create({ ...rest }: ICreateBanner): Promise<{ messages: string }> {
    const url = '/create-banner';
    return httpRequest.post(
      url,
      { ...rest },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  update({ ...rest }: IEditBanner): Promise<{ messages: string }> {
    const url = '/update-banner';
    return httpRequest.post(
      url,
      { ...rest },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  delete({ ...rest }: IDeleteBanner): Promise<{ messages: string }> {
    const url = '/delete-banner';
    return httpRequest.post(url, { ...rest });
  },
};

export default Banner;
