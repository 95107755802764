import { Button } from 'antd';
import React, { useState } from 'react';
import './style.scss';
import { useAppDispatch } from '@hook/index';
import { deleteListPost } from '@store/postSlice';
export interface IDeleteProps {
  id: number;
  handleClose: () => void;
  getData: (message?: string) => void;
}

export default function Delete({ id, handleClose, getData }: IDeleteProps) {
  const dispatch = useAppDispatch();
  const callback = (message?: string) => {
    getData(message);
    handleClose();
  };
  const handleDelete = async () => {
    dispatch(deleteListPost({ param: { id }, callback }));
  };
  return (
    <div className="wrapper-delete">
      <div className="body-delete">
        <div className="message">Bạn có chắc muốn xóa không</div>
        <div className="group-btn">
          <Button onClick={handleDelete} type="primary">
            Đồng ý
          </Button>
          <Button onClick={() => handleClose()} type="primary" danger>
            Hủy bỏ
          </Button>
        </div>
      </div>
    </div>
  );
}
