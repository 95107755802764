import UserComponent from '@components/admin/manager-user/list-user';
import React from 'react';

export default function UserListPage() {
  return (
    <>
      <UserComponent />
    </>
  );
}
