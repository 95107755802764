import { ICreateBank, IDataRes, IDeleteBank, IEditBank, IBank, IReqGetAllBank } from '@interfaces/Bank';
import httpRequest from './Api';

const Bank = {
  getBank({ ...rest }: Partial<IReqGetAllBank>): Promise<IDataRes<IBank>> {
    const url = '/list-banks';
    return httpRequest.get(url, { params: { ...rest } });
  },
  create({ ...rest }: ICreateBank): Promise<{ messages: string }> {
    const url = '/create-bank';
    return httpRequest.post(
      url,
      { ...rest },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  update({ ...rest }: IEditBank): Promise<{ messages: string }> {
    const url = '/update-bank';
    return httpRequest.post(
      url,
      { ...rest },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  delete({ ...rest }: IDeleteBank): Promise<{ messages: string }> {
    const url = '/delete-bank';
    return httpRequest.post(url, { ...rest });
  },
};

export default Bank;
