import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Button, Upload } from 'antd';
import ImgFile from '@assets/folder.png';

type UploadFileProps = {
  handleChangeFile: (value: any, title: string) => void;
  title: string;
  fileDefault?: any;
};
const UploadFile = ({ handleChangeFile, title, fileDefault }: UploadFileProps) => {
  const onChange = ({ file, fileList }) => {
    if (file.status !== 'uploading') {
      handleChangeFile(file.originFileObj, title);
    }
  };
  return (
    <Upload onChange={onChange} action="https://www.mocky.io/v2/5cc8019d300000980a055e76">
      {!!fileDefault ? (
        <div className="file-notify">
          <img src={ImgFile} alt="" />
        </div>
      ) : (
        <Button icon={<UploadOutlined />}>Tải file</Button>
      )}
    </Upload>
  );
};

export default UploadFile;
