import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Button, Dropdown, MenuProps, Pagination, Space, Table } from 'antd';
import { IPermissions, IUser } from '@interfaces/User';
import {
  CaretDownOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import ModalBase from '@components/common/modal/ModalBase';
import InfoUser from '../dialog/InforUser';
import ChangePassword from '../dialog/changePass';
import Delete from '../dialog/Delete';
import User from '@services/User';
import { convert } from '@utils/convert';
import { ParamReq } from '@interfaces/CommonInterface';
import { useAppSelector } from '@hook/index';
import EditTable from '@components/common/role/EditTable';
import { isCheckRole } from '@utils/helper';
import CreateTable from '@components/common/role/CreateTable';
import DeleteTable from '@components/common/role/DeleteTable';
import Active from '@components/common/role/Active';
import GameUserModal from '../dialog/GameUser';
export interface IListMember {
  data: IUser[];
  current_page: number;
  total: number;
  per_page: number;
  loading: boolean;
  handleChangeParam: (param: ParamReq) => void;
  onEdit: (data: IUser, type: string) => void;
  onCreate: (type: string) => void;
  getData: (message?: string) => void;
}

export default function ListMember(props: IListMember) {
  const { data, current_page, total, per_page, loading, onEdit, onCreate, getData, handleChangeParam } = props;
  const [openModel, setOpenModal] = useState<boolean>(false);
  const [role, setRole] = useState<IPermissions[]>([]);
  const { data: currentUser } = useAppSelector((state) => state.authSlice);
  const [optoin, setOption] = useState({ id: 0, key: 0, title: '' });

  useEffect(() => {
    if (!currentUser.isLogin) return;
    setRole(convert.getRole(currentUser));
  }, [currentUser]);
  const handleShowOption = (id: number, item) => {
    setOpenModal(true);
    setOption({ id, key: item.key, title: item.label });
  };

  const handleSubmit = (value: boolean) => {
    setOpenModal(value);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDelete = (id: number) => {
    setOpenModal(true);
    setOption({ id, key: 3, title: 'Xác nhận' });
  };

  const handleToggle = async (user_id: string, key: string) => {
    const res = await User.toggle({ user_id, key });
    getData();
  };

  const MenuTransaction: MenuProps['items'] = [
    {
      label: 'Thông tin thành viên',
      key: '0',
      disabled: !isCheckRole(role, 'view detail'),
    },
    {
      label: 'Đổi mật khẩu',
      key: '1',
      disabled: !isCheckRole(role, 'change password'),
    },
    {
      label: 'Trò chơi',
      key: '2',
      // disabled: !isCheckRole(role, 'change password'),
    },
  ];

  /** data for column */
  const columns: ColumnsType<IUser> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      width: 50,
    },
    {
      title: 'Tên người dùng',
      dataIndex: 'username',
      key: 'username',
      width: 200,
      render: (_, record) => (
        <Dropdown
          menu={{
            items: MenuTransaction,
            onClick: ({ key }) => handleShowOption(record.id, (MenuTransaction as [])[Number(key)]),
          }}
          autoAdjustOverflow={false}
          destroyPopupOnHide={true}
        >
          <div>
            <div className="name-column">
              <span className="content-drop">{record.username || 'username'}</span>
              <CaretDownOutlined />
            </div>
          </div>
        </Dropdown>
      ),
    },
    {
      title: 'Vip',
      dataIndex: 'level_name',
      key: 'level_name',
      align: 'center',
      width: 150,
      render: (_, record) => <Space size="middle">{record?.level_name}</Space>,
    },
    {
      title: 'Tỷ giá',
      dataIndex: 'point_price',
      key: 'point_price',
      align: 'center',
      width: 150,
      render: (_, record) => <Space size="middle">{convert.money(record?.point_price, 'VNĐ')}</Space>,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          <Active permission={isCheckRole(role, 'active')} active={record.is_active}>
            <div className="status-col" onClick={() => handleToggle(String(record.id), String('is_active'))}>
              {record.is_active ? (
                <span className="status-on btn-hover">
                  <CheckOutlined />
                </span>
              ) : (
                <span className="status-off btn-hover">
                  <CloseOutlined />
                </span>
              )}
            </div>
          </Active>
        </Space>
      ),
    },
    {
      title: 'Rút tiền',
      dataIndex: 'is_withdraw',
      key: 'is_withdraw',
      align: 'center',
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          <Active permission={isCheckRole(role, 'withdraw')} active={record.is_withdraw}>
            <div className="status-col" onClick={() => handleToggle(String(record.id), String('is_withdraw'))}>
              {record.is_withdraw ? (
                <span className="status-on btn-hover">
                  <CheckOutlined />
                </span>
              ) : (
                <span className="status-off btn-hover">
                  <CloseOutlined />
                </span>
              )}
            </div>
          </Active>
        </Space>
      ),
    },
    {
      title: 'Giới thiệu bởi',
      dataIndex: 'agent_id',
      key: 'agent_id',
      width: 150,
      render: (_, record) => <Space size="middle">{record?.agent?.username}</Space>,
    },
    {
      title: 'Số lần nạp',
      dataIndex: 'recharge',
      key: 'recharge',
      width: 150,
      render: (_, record) => <Space size="middle">{record?.recharge}</Space>,
    },
    {
      title: 'Số lần rút',
      dataIndex: 'withdraw',
      key: 'withdraw',
      width: 150,
      render: (_, record) => <Space size="middle">{record?.withdraw}</Space>,
    },
    {
      title: 'Tỷ lệ bonus',
      dataIndex: 'bonus_rate',
      key: 'bonus_rate',
      width: 150,
      render: (_, record) => <Space size="middle">{Number(record?.bonus_rate)}%</Space>,
    },
    {
      title: 'Tạo lúc	',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'center',
      width: 200,
      render: (_, record) => <Space size="middle">{convert.convertDateFormat(record.created_at)}</Space>,
    },
    {
      title: 'Điều khiển',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 150,
      fixed: 'right',
      render: (_, record) => (
        <div className="group-action">
          <EditTable permission={isCheckRole(role, 'edit')}>
            <Button onClick={() => onEdit(record, 'edit')} type="primary" icon={<EditOutlined />} />
          </EditTable>
          <DeleteTable permission={isCheckRole(role, 'delete')}>
            <Button onClick={() => handleDelete(record.id)} type="primary" danger icon={<DeleteOutlined />} />
          </DeleteTable>
        </div>
      ),
    },
  ];

  const renderContentModal = (id: number, key?: number) => {
    switch (Number(key)) {
      case 0:
        return <InfoUser id={id} />;
      case 1:
        return <ChangePassword handleClose={handleClose} id={id} />;
      case 2:
        return <GameUserModal id={id} openModel={openModel} />;
      default:
        return <Delete handleClose={handleClose} getData={getData} id={id} />;
    }
  };
  const ChangePage = (pageItem: number, pageSize: number) => {
    handleChangeParam({ paginate: pageSize, page: pageItem });
  };
  return (
    <>
      <ModalBase openModel={openModel} title={optoin?.title} handleSubmit={handleSubmit}>
        <div>{renderContentModal(optoin?.id, optoin?.key)}</div>
      </ModalBase>
      <div className="table-wrap">
        <div className="tool-bar">
          <div className="tool-bar__left">
            <CreateTable permission={isCheckRole(role, 'create')}>
              <button onClick={() => onCreate('create')} className="btn-add">
                <PlusOutlined />
                <strong>Thêm mới</strong>
              </button>
            </CreateTable>
          </div>
        </div>
        <div className="m-top">
          <Table
            className="table wrap-col"
            loading={loading}
            scroll={{ x: 1200 }}
            pagination={false}
            columns={columns}
            dataSource={isCheckRole(role, 'view client') ? data : undefined}
            rowKey={(record) => record.id}
          />
          <div className="wrap-pagination">
            {data !== undefined && (
              <Pagination
                onChange={ChangePage}
                current={current_page}
                total={total}
                defaultPageSize={100}
                showSizeChanger={true}
                pageSizeOptions={[10, 20, 50, 100, 500, 1000]}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
