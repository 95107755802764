import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import qs from 'query-string';
import Cookies from 'universal-cookie';

type Props = {
  element: React.FC | React.ComponentClass;
  meta?: Record<string, any>;
};

const o = Object.create(null);

const PrivateRoute: React.FC<Props> = function ({ element: Component, meta = o, ...rest }) {
  const { pathname, search } = useLocation();
  const cookies = new Cookies();
  const isLoginPage = pathname === '/' || pathname === '/login';
  let isLogin = cookies.get('userToken');

  if (isLogin && isLoginPage) {
    const redirectUrl = qs.parse(search).redirectUrl as string;
    const url = redirectUrl || '/dashboard' + search;
    return <Navigate to={url} replace />;
  }
  if (meta.requiresAuth) {
    if (isLogin) {
      return <Component {...rest} />;
    } else {
      if (!isLoginPage) {
        return <Navigate to={`/?redirectUrl=${pathname}${search} `} replace />;
      }
    }
  }
  return <Component {...rest} />;
};

export default PrivateRoute;
