import { useAppDispatch, useAppSelector } from '@hook/index';
import { IUser, IUserInfo } from '@interfaces/User';
import User from '@services/User';
import { handleLoading } from '@store/userSlice';
import { Col, Form, Input, Radio, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import ImgZalo from '@assets/zalo.png';
import ImgTelegram from '@assets/telegram.png';
import ImgViber from '@assets/viber.png';
import './style.scss';
import { convert } from '@utils/convert';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
export interface IInfoUserProps {
  id: number;
}

export default function InfoUser({ id }: IInfoUserProps) {
  const dispatch = useAppDispatch();
  const [info, setInfo] = useState<IUserInfo>();
  const [isCopied, setIsCopied] = useState(false);
  const { loading, data: DataProfitStatisticslice } = useAppSelector((state) => state.profitStatisticslice);
  const profitStatisticslice = DataProfitStatisticslice.listProfitStatistics.find((item) => item.user_id === id);
  useEffect(() => {
    (async () => {
      dispatch(handleLoading(true));
      const res = await User.getUserInfo({ user_id: id });
      dispatch(handleLoading(false));
      setInfo(res.data);
    })();
  }, [id]);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };
  return (
    <div className="info-content form-col">
      <Row gutter={10} justify="center">
        <Col span={20}>
          <div className="filed-item">
            <span className="title">Ảnh đại diện</span>
            <img className="avatar" src={info?.avatar || ''} alt="" />
          </div>
        </Col>
      </Row>
      <Row gutter={10} justify="center">
        <Col span={20}>
          <div className="filed-item">
            <span className="title">Giấy tời khác</span>
            {
              <div className="list-img">
                {info?.image_other
                  ? info?.image_other.map((item: string) => (
                      <img key={item} className="avatar" src={item || ''} alt="" />
                    ))
                  : ''}
              </div>
            }
          </div>
        </Col>
      </Row>
      <Row gutter={10} justify="center">
        <Col span={10}>
          <div className="filed-item">
            <span className="title">Tên đăng nhập:</span>
            <span className="value">{info?.username}</span>
          </div>
        </Col>
        <Col span={10}>
          <div className="filed-item">
            <span className="title">Mật khẩu:</span>
            <span className="value"> {info?.o_password}</span>
          </div>
        </Col>
      </Row>
      <Row gutter={10} justify="center">
        <Col span={10}>
          <div className="filed-item">
            <span className="title">Ngày sinh:</span>
            <span className="value">{info?.birthday}</span>
          </div>
        </Col>
        <Col span={10}>
          <div className="filed-item">
            <span className="title">Giới tính</span>
            <Radio.Group name="radiogroup" value={info?.gender}>
              <Radio value={0}>Nam</Radio>
              <Radio value={1}>Nữ</Radio>
            </Radio.Group>
          </div>
        </Col>
      </Row>
      <Row gutter={10} justify="center">
        <Col span={10}>
          <div className="filed-item">
            <span className="title">Email:</span>
            <span className="value"> {info?.email} </span>
          </div>
        </Col>
        <Col span={10}>
          <div className="filed-item">
            <span className="title">Số điện thoại:</span>
            <span className="value">{info?.mobile} </span>
          </div>
        </Col>
      </Row>
      <Row gutter={10} justify="center">
        <Col span={10}>
          <div className="filed-item">
            <span className="title">Rút tiền</span>
            <div>
              {info?.is_withdraw ? (
                <i style={{ fontSize: '20px', color: '#008000' }} className="icon-check fa-solid fa-circle-check"></i>
              ) : (
                <i style={{ fontSize: '20px', color: '#fe0000' }} className="fa-solid fa-circle-xmark"></i>
              )}
            </div>
          </div>
        </Col>
        <Col span={10}>
          <div className="filed-item">
            <span className="title">Mạng xã hội</span>
            <div className="list-social">
              <div className="img-item">
                <Link to={`zalo://chat?to=${info?.zalo}`} target="_blank">
                  <img src={ImgZalo} alt="Zalo" />
                </Link>
              </div>
              <div className="img-item">
                <Link to={`tg://resolve?domain=${info?.telegram}`} target="_blank">
                  <img src={ImgTelegram} alt="Telegram" />
                </Link>
              </div>
              <div className="img-item">
                <Link to={`viber://${info?.viber}`} target="_blank">
                  <img src={ImgViber} alt="Viber" />
                </Link>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={10} justify="center">
        <Col span={10}>
          <div className="filed-item">
            <span className="title">Tỷ giá điểm</span>
            <span className="value"> {convert.money(info?.point_price, 'VNĐ')} / 1 điểm</span>
          </div>
        </Col>
        <Col span={10}>
          <div className="filed-item">
            <span className="title">Mã giới thiệu:</span>
            <CopyToClipboard text={info?.invite_code} onCopy={onCopyText}>
              <div className="copy-area">
                {info?.invite_code}
                {!isCopied ? (
                  <i style={{ marginLeft: '10px' }} className="fa-solid fa-copy"></i>
                ) : (
                  <i style={{ marginLeft: '10px' }} className="fa-regular fa-circle-check"></i>
                )}
              </div>
            </CopyToClipboard>
          </div>
        </Col>
      </Row>
      <Row gutter={10} justify="center">
        <Col span={10}>
          <div className="filed-item">
            <span className="title">Tổng nộp:</span>
            <span className="value"> {profitStatisticslice?.total_recharge || ''} </span>
          </div>
        </Col>
        <Col span={10}>
          <div className="filed-item">
            <span className="title">Tổng rút:</span>
            <span className="value">{profitStatisticslice?.total_withdraw || ''} </span>
          </div>
        </Col>
      </Row>
      <Row gutter={10} justify="center">
        <Col span={10}>
          <div className="filed-item">
            <span className="title">Tổng lợi nhuận:</span>
            <span className="value"> {profitStatisticslice?.net_profit || ''} </span>
          </div>
        </Col>
        <Col span={10}></Col>
      </Row>
    </div>
  );
}
