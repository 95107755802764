import * as React from 'react';
import './style.scss';
import Loading from '../loading';
export interface IContainerProps {
  screen: { type: string; component: React.ReactNode }[];
  type: string;
  noLoading?: boolean;
}

export default function Container(props: IContainerProps) {
  const { type, screen, noLoading } = props;

  return (
    <>
      {screen.map((item, index) => {
        return (
          <React.Fragment key={index}>
            {item.type === type && (
              <div className="screen">
                {' '}
                {noLoading ? '' : item.type !== 'table' ? <Loading /> : ''} {item.component}{' '}
              </div>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
}
