import { PayloadCallBack } from '@interfaces/CommonInterface';
import { ICreateBank, IDeleteBank, IEditBank, StateBank, IDataBank, IReqGetAllBank } from '@interfaces/Bank';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Bank from '@services/Bank';
import { BANK } from '@constants/text';

const initialState: StateBank<IDataBank> = {
  data: {
    listBank: [],
    current_page: BANK.PAGE,
    per_page: BANK.LIMIT,
    total: 1,
  },
  loading: false,
  error: '',
};

export const getAllBank = createAsyncThunk('Bank/getAll', async (payload: Partial<IReqGetAllBank>, { getState }) => {
  try {
    const { data: dataRes } = await Bank.getBank({ ...payload });
    const { data, current_page, total, per_page } = dataRes;
    return {
      listBank: [...data],
      current_page,
      total,
      per_page,
    };
  } catch (error) {
    console.log(error);
  }
});

export const createBank = createAsyncThunk(
  'Bank/create',
  async (payload: PayloadCallBack<ICreateBank>, { getState }) => {
    try {
      const { messages } = await Bank.create({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const updateBank = createAsyncThunk('Bank/edit', async (payload: PayloadCallBack<IEditBank>, { getState }) => {
  try {
    const { messages } = await Bank.update({ ...payload.param });
    payload.callback(messages);
  } catch (error) {
    console.log(error);
  }
});

export const deleteBank = createAsyncThunk(
  'Bank/delete',
  async (payload: PayloadCallBack<IDeleteBank>, { getState }) => {
    try {
      const { messages } = await Bank.delete({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

const BankSlice = createSlice({
  name: 'Bank',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('Bank/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Bank/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Bank/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default BankSlice.reducer;
