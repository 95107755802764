import React, { ReactElement, useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Button, Dropdown, MenuProps, Pagination, Space, Table } from 'antd';
import { IPermissions, IUser } from '@interfaces/User';
import { CaretDownOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import ModalBase from '@components/common/modal/ModalBase';
import { useAppSelector } from '@hook/index';
import { convert } from '@utils/convert';
import EditTable from '@components/common/role/EditTable';
import DeleteTable from '@components/common/role/DeleteTable';
import { isCheckRole } from '@utils/helper';
import CreateTable from '@components/common/role/CreateTable';
import Delete from '../dialog/Delete';
import { ICategory } from '@interfaces/Category';
import { ParamReq } from '@interfaces/CommonInterface';
export interface IListMember {
  data: ICategory[];
  current_page: number;
  total: number;
  per_page: number;
  loading: boolean;
  onEdit: (data: ICategory, type: string) => void;
  handleChangeParam: (param: ParamReq) => void;
  onCreate: (type: string) => void;
  getData: (message?: string) => void;
}

export default function TabaleData(props: IListMember) {
  const { data, current_page, total, per_page, loading, onEdit, onCreate, getData, handleChangeParam } = props;
  const [openModel, setOpenModal] = useState<boolean>(false);
  const [optoin, setOption] = useState({ id: 0, key: 0, title: '' });
  const [role, setRole] = useState<IPermissions[]>([]);
  const { data: currentUser } = useAppSelector((state) => state.authSlice);
  useEffect(() => {
    if (!currentUser.isLogin) return;
    setRole(convert.getRole(currentUser));
  }, [currentUser]);
  const handleShowOption = (id: number, item) => {
    setOpenModal(true);
    setOption({ id, key: item.key, title: item.label });
  };
  const handleSubmit = (value: boolean) => {
    setOpenModal(value);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDelete = (id: number) => {
    setOpenModal(true);
    setOption({ id, key: 2, title: 'Xác nhận' });
  };

  /** data for column */
  const columns: ColumnsType<ICategory> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'title',
      width: 200,
      key: 'title',
      align: 'center',
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'image',
      key: 'image',
      align: 'center',
      render: (_, record) => (
        <div className="image-col">
          <img src={record.image} alt="" />
        </div>
      ),
    },
    {
      title: 'Điều khiển',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record) => (
        <div className="group-action">
          <EditTable permission={isCheckRole(role, 'edit')}>
            <Button onClick={() => onEdit(record, 'edit')} type="primary" icon={<EditOutlined />} />
          </EditTable>
          <DeleteTable permission={isCheckRole(role, 'delete')}>
            <Button onClick={() => handleDelete(record.id)} type="primary" danger icon={<DeleteOutlined />} />
          </DeleteTable>
        </div>
      ),
    },
  ];

  const expandedRowRender = (record: ICategory[]) => {
    const columns: ColumnsType<ICategory> = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Tiêu đề',
        dataIndex: 'title',
        key: 'title',
        width: 200,
        align: 'center',
      },
      {
        title: 'Hình ảnh',
        dataIndex: 'image',
        key: 'image',
        align: 'center',
        render: (_, record) => (
          <div className="image-col">
            <img src={record.image} alt="" />
          </div>
        ),
      },
      {
        title: 'Điều khiển',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        render: (_, record) => (
          <div className="group-action">
            <EditTable permission={isCheckRole(role, 'edit')}>
              <Button onClick={() => onEdit(record, 'edit')} type="primary" icon={<EditOutlined />} />
            </EditTable>
            <DeleteTable permission={isCheckRole(role, 'delete')}>
              <Button onClick={() => handleDelete(record.id)} type="primary" danger icon={<DeleteOutlined />} />
            </DeleteTable>
          </div>
        ),
      },
    ];

    return <Table columns={columns} dataSource={record} pagination={false} />;
  };

  const renderContentModal = (id: number, key?: number) => {
    return <Delete handleClose={handleClose} getData={getData} id={id} />;
  };
  const ChangePage = (pageItem: number, pageSize: number) => {
    handleChangeParam({ paginate: pageSize, page: pageItem });
  };
  return (
    <>
      <ModalBase openModel={openModel} title={optoin?.title} handleSubmit={handleSubmit}>
        <div>{renderContentModal(optoin?.id, optoin?.key)}</div>
      </ModalBase>
      <div className="table-wrap">
        <div className="tool-bar">
          <div className="tool-bar__left">
            <CreateTable permission={isCheckRole(role, 'create')}>
              <button onClick={() => onCreate('create')} className="btn-add">
                <PlusOutlined />
                <strong>Thêm mới</strong>
              </button>
            </CreateTable>
          </div>
        </div>
        <div className="m-top">
          <Table
            className="table wrap-col"
            loading={loading}
            scroll={{ x: 960 }}
            pagination={false}
            columns={columns}
            dataSource={isCheckRole(role, 'view category') ? data : undefined}
            expandable={{
              expandedRowRender: (record) => expandedRowRender(record.child),
              rowExpandable: (record) => record.child.length !== 0,
            }}
            rowKey={(record) => record.id}
          />
          <div className="wrap-pagination">
            {data !== undefined && (
              <Pagination
                onChange={ChangePage}
                current={current_page}
                defaultPageSize={100}
                total={total}
                showSizeChanger={true}
                pageSizeOptions={[10, 20, 50, 100, 500, 1000]}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
