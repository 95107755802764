import { ListResponse } from '@interfaces/CommonInterface';
import { ILogin, IUserLogin } from '@interfaces/UserInterface';
import httpRequest from './Api';
import { IUserInfo } from '@interfaces/User';

export type IReqPassword = {
  password_old: string;
  password: string;
  password_confirmation: string;
};
const Auth = {
  login(body: IUserLogin): Promise<ILogin> {
    const url = '/login';
    return httpRequest.post(url, body);
  },
  logout(): Promise<ListResponse<any>> {
    const url = '/logout';
    return httpRequest.post(url);
  },
  refetchToken(): Promise<any> {
    const url = '/refresh-token';
    return httpRequest.get(url);
  },
  changePassword(body: IReqPassword): Promise<{ messages: string }> {
    const url = '/change-password';
    return httpRequest.post(url, body);
  },
  getUserCurrent(): Promise<IUserInfo> {
    const url = '/current-user';
    return httpRequest.get(url);
  },
};

export default Auth;
