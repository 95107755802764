import { ICreatePage, IDataRes, IDeletePage, IEditPage, IPage, IReqGetAllPage } from '@interfaces/Page';
import httpRequest from './Api';

const Page = {
  getPage({ ...rest }: Partial<IReqGetAllPage>): Promise<IDataRes<IPage>> {
    const url = '/list-pages';
    return httpRequest.get(url, { params: { ...rest } });
  },
  create({ ...rest }: ICreatePage): Promise<{ messages: string }> {
    const url = '/create-page';
    return httpRequest.post(
      url,
      { ...rest },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  update({ ...rest }: IEditPage): Promise<{ messages: string }> {
    const url = '/update-page';
    return httpRequest.post(
      url,
      { ...rest },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  delete({ ...rest }: IDeletePage): Promise<{ messages: string }> {
    const url = '/delete-page';
    return httpRequest.post(url, { ...rest });
  },
};

export default Page;
