import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Radio, Row, Select } from 'antd';
import { updatePointPrice } from '@store/pointPrice';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { ITransferMoney, IUpdateTransferEvent } from '@interfaces/TransferMoney';
import { updateTransferMoneyEvent } from '@store/transferMoneySlice';
import { getAllEvent } from '@store/eventSlice';

export interface IFormEditComponent {
  data: ITransferMoney;
  handleCloseScreen: (type: string) => void;
  getData: (message?: string) => void;
}
export default function FormEditComponent({ handleCloseScreen, getData, data }: IFormEditComponent) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading, data: DataEvent } = useAppSelector((state) => state.eventSlice);

  const [dataForm, setDataForm] = useState<IUpdateTransferEvent>({
    transfer_id: data.id,
    event_id: data.event_id,
  });

  const handleSubmint = (event: IUpdateTransferEvent) => {
    setDataForm(event);
    dispatch(
      updateTransferMoneyEvent({
        param: {
          ...dataForm,
          ...event,
        },
        callback: getData,
      }),
    );
  };

  return (
    <Form
      form={form}
      className="form-user form-col"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      initialValues={dataForm}
      onFinish={handleSubmint}
      layout="horizontal"
      style={{ maxWidth: 1200, width: '100%' }}
      size={'large'}
    >
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item
            rules={[{ required: false, message: 'Vui lòng không được để trống' }]}
            name={'event_id'}
            label="Danh sách sự kiện"
          >
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              options={[
                { value: 0, label: '----' },
                ...DataEvent.listEvent.map((item, index) => ({
                  value: item.id,
                  label: item.title,
                })),
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item label=" " colon={false}>
            <Button style={{ width: '100%' }} type="primary" htmlType="submit">
              Cập nhật
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
