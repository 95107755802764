import { CloseOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import './style.scss';
import Loading from '../loading';
export interface IModalBaseProps {
  children: React.ReactElement;
  handleSubmit?: (value: boolean) => void;
  title: string;
  openModel: boolean;
}

export default function ModalBase(props: IModalBaseProps) {
  const { children, title, openModel, handleSubmit } = props;
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    setOpen(openModel);
  }, [openModel]);

  const handleClose = () => {
    setOpen(false);
    handleSubmit?.(false);
  };
  return (
    <Modal className="wrap-modal" closable={false} footer={null} open={open} onOk={() => {}} onCancel={() => {}}>
      <div className="container-modal">
        <Loading sx={'modal-loading-container'} />
        <div className="header-modal">
          <h3 className="title-modal">{title}</h3>
          <div className="action-modal">
            <button className="btn-close" onClick={handleClose}>
              <CloseOutlined />
            </button>
          </div>
        </div>
        <div className="content-modal">{children}</div>
      </div>
    </Modal>
  );
}
