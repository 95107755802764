import DashBoardComponent from '@components/admin/dashboard';
import React from 'react';

export default function Dashboard() {
  return (
    <>
      <DashBoardComponent />
    </>
  );
}
