import { ICreateBonus, IDataRes, IDeleteBonus, IBonus, IReqGetAllBonus, IEditBonus } from '@interfaces/Bonus';
import httpRequest from './Api';

const Bonus = {
  getBonus({ ...rest }: Partial<IReqGetAllBonus>): Promise<IDataRes<IBonus>> {
    const url = '/list-bonus';
    return httpRequest.get(url, { params: { ...rest } });
  },
  create({ ...rest }: ICreateBonus): Promise<{ messages: string }> {
    const url = '/create-bonus';
    return httpRequest.post(url, { ...rest });
  },
  update({ ...rest }: IEditBonus): Promise<{ messages: string }> {
    const url = '/update-bonus';
    return httpRequest.post(url, { ...rest });
  },
  delete({ ...rest }: IDeleteBonus): Promise<{ messages: string }> {
    const url = '/delete-bonus';
    return httpRequest.post(url, { ...rest });
  },
};

export default Bonus;
