import {
  ICreateGameUser,
  IDataRes,
  IDeleteGameUser,
  IEditGameUser,
  IGameUser,
  IReqGetAllGameUser,
} from '@interfaces/GameUser';
import httpRequest from './Api';

const GameUser = {
  getGameUser({ ...rest }: Partial<IReqGetAllGameUser>): Promise<IDataRes<IGameUser>> {
    const url = '/list-user-games';
    return httpRequest.get(url, { params: { ...rest } });
  },
  create({ ...rest }: ICreateGameUser): Promise<{ messages: string }> {
    const url = '/create-user-game';
    return httpRequest.post(url, { ...rest });
  },
  update({ ...rest }: IEditGameUser): Promise<{ messages: string }> {
    const url = '/update-user-game';
    return httpRequest.post(url, { ...rest });
  },
  delete({ ...rest }: IDeleteGameUser): Promise<{ messages: string }> {
    const url = '/delete-user-game';
    return httpRequest.post(url, { ...rest });
  },
};

export default GameUser;
