import React, { ReactElement, useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Button, Dropdown, MenuProps, Pagination, Space, Table } from 'antd';
import { IPermissions, IUser } from '@interfaces/User';
import { CaretDownOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { MenuTransaction } from '@constants/menu';
import ModalBase from '@components/common/modal/ModalBase';
import { IInviteUser } from '@interfaces/InviteUser';
import { convert } from '@utils/convert';
import { isCheckRole } from '@utils/helper';
import { useAppSelector } from '@hook/index';
import { ParamReq } from '@interfaces/CommonInterface';
export interface IListMember {
  data: IInviteUser[];
  current_page: number;
  total: number;
  per_page: number;
  loading: boolean;
  handleChangeParam: (param: ParamReq) => void;
  onCreate: (type: string) => void;
  getData: (message?: string) => void;
}

export default function ListInviteUser(props: IListMember) {
  const { data, current_page, total, per_page, loading, onCreate, getData, handleChangeParam } = props;
  const [openModel, setOpenModal] = useState<boolean>(false);
  const [optoin, setOption] = useState({ id: 0, key: 0, title: '' });
  const [role, setRole] = useState<IPermissions[]>([]);
  const { data: currentUser } = useAppSelector((state) => state.authSlice);
  useEffect(() => {
    if (!currentUser.isLogin) return;
    setRole(convert.getRole(currentUser));
  }, [currentUser]);
  const handleShowOption = (id: number, item) => {
    setOpenModal(true);
    setOption({ id, key: item.key, title: item.label });
  };

  const handleSubmit = (value: boolean) => {
    setOpenModal(value);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDelete = (id: number) => {
    setOpenModal(true);
    setOption({ id, key: 2, title: 'Xác nhận' });
  };

  /** data for column */
  const columns: ColumnsType<IInviteUser> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Tên người gửi',
      dataIndex: 'user',
      key: 'user',
      render: (_, record) => <Space size="middle">{record.user.username}</Space>,
    },
    {
      title: 'Mã giới thiệu',
      dataIndex: 'user',
      key: 'invite_code',
      render: (_, record) => <Space size="middle">{record.user.invite_code}</Space>,
    },
    {
      title: 'Tên người nhận',
      dataIndex: 'member',
      key: 'member',
      render: (_, record) => <Space size="middle">{record.member.username}</Space>,
    },
    {
      title: 'Mã giới thiệu',
      dataIndex: 'member',
      key: 'member',
      render: (_, record) => <Space size="middle">{record.member.invite_code}</Space>,
    },
    {
      title: 'Tổng nạp',
      dataIndex: 'total_recharge',
      key: 'total_recharge',
      render: (_, record) => (
        <Space size="middle">
          {record.member.total_recharge ? convert.money(record.member.total_recharge, 'VND') : 0}
        </Space>
      ),
    },
    {
      title: 'Tổng rút',
      dataIndex: 'total_withdraw',
      key: 'total_withdraw',
      render: (_, record) => (
        <Space size="middle">
          {record.member.total_withdraw ? convert.money(record.member.total_withdraw, 'VND') : 0}
        </Space>
      ),
    },
    {
      title: 'Lãi/lỗ',
      dataIndex: 'profit_money',
      key: 'profit_money',
      render: (_, record) => (
        <Space size="middle">
          {record.member.total_recharge && record.member.total_withdraw
            ? convert.money(record.member.total_recharge - record.member.total_withdraw, 'VND')
            : 0}
        </Space>
      ),
    },
  ];

  const renderContentModal = (id: number, key?: number) => {
    switch (Number(key)) {
      case 0:
        return key;
      case 1:
        return key;
      default:
        return key;
    }
  };
  const ChangePage = (pageItem: number, pageSize: number) => {
    handleChangeParam({ paginate: pageSize, page: pageItem });
  };
  return (
    <>
      <ModalBase openModel={openModel} title={optoin?.title} handleSubmit={handleSubmit}>
        <div>{renderContentModal(optoin?.id, optoin?.key)}</div>
      </ModalBase>
      <div className="table-wrap">
        <div className="m-top">
          <Table
            className="table wrap-col"
            loading={loading}
            scroll={{ x: 960 }}
            pagination={false}
            columns={columns}
            dataSource={isCheckRole(role, 'view invite agent') ? data : undefined}
            rowKey={(record) => record.id}
          />
          <div className="wrap-pagination">
            {data !== undefined && (
              <Pagination
                onChange={ChangePage}
                current={current_page}
                defaultPageSize={100}
                total={total}
                showSizeChanger={true}
                pageSizeOptions={[10, 20, 50, 100, 500, 1000]}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
