import BannerComponent from '@components/admin/bannber';
import React from 'react';

export default function BannerPage() {
  return (
    <>
      <BannerComponent />
    </>
  );
}
