import BankComponent from '@components/admin/bank';
import React from 'react';

export default function BankPage() {
  return (
    <>
      <BankComponent />
    </>
  );
}
