import React, { useState } from 'react';
import { Button, Col, Form, Input, Row, Switch } from 'antd';
import { useAppDispatch } from '@hook/index';
import './style.scss';
import { ICreateBank } from '@interfaces/Bank';
import { createBank } from '@store/bankSlice';
import UploadFileImg from '@components/common/upload/UploadFileImg';
export interface IFormCreateComponent {
  handleCloseScreen: (type: string) => void;
  getData: () => void;
}

export default function FormCreateComponent(props: IFormCreateComponent) {
  const { getData } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [dataForm, setDataForm] = useState<ICreateBank>();

  const handleSubmint = (event: ICreateBank) => {
    setDataForm({ ...(dataForm as ICreateBank), ...event });
    dispatch(
      createBank({
        param: {
          ...dataForm,
          ...event,
          status: event.status ? 1 : 0,
          sort: Number(event.sort),
        },
        callback: getData,
      }),
    );
  };
  const handleChangeImg = (url: any, name: string) => {
    setDataForm({ ...(dataForm as ICreateBank), [name]: url });
  };
  return (
    <>
      <Form
        form={form}
        className="form-event"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
        onFinish={handleSubmint}
        style={{ maxWidth: 1200, width: '100%' }}
        size={'large'}
      >
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item rules={[{ required: true, message: 'Vui lòng không được để trống' }]} name="key" label="Từ Khóa">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name="name"
              label="Tên ngân hàng"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name={'url'} label="Link liên kết">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name="status"
              initialValue={true}
              label="Trạng thái"
            >
              <Switch defaultChecked />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name={'sort'}
              label="STT hiển thị"
            >
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label="Hình ảnh">
              <UploadFileImg nameImg={'image'} handleChangeImg={handleChangeImg} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label="Thumbail">
              <UploadFileImg nameImg={'thumbail'} handleChangeImg={handleChangeImg} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label=" " colon={false}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                Thêm mới
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}
