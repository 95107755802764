import { IDataRes, IDataResRing, INotify, IReqGetAllNotify } from '@interfaces/Notify';
import httpRequest from './Api';

const Notify = {
  getNotify({ ...rest }: Partial<IReqGetAllNotify>): Promise<IDataRes<INotify>> {
    const url = '/notifications';
    return httpRequest.get(url, { params: { ...rest } });
  },
  getNotifyRing(): Promise<IDataResRing> {
    const url = '/ring-notification';
    return httpRequest.get(url);
  },
  updateAllNotifyRing(): Promise<{ messages: string }> {
    const url = '/update-all-notification';
    return httpRequest.post(url);
  },
  update({ ...rest }: { id: number }): Promise<{ messages: string }> {
    const url = '/update-notification';
    return httpRequest.post(url, { ...rest });
  },
};

export default Notify;
