import { PayloadCallBack } from '@interfaces/CommonInterface';
import { ICreateMenu, IDeleteMenu, IEditMenu, StateMenu, IDataMenu, IReqGetAllMenu } from '@interfaces/Menu';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Menu from '@services/Menu';
import { BANK_USER } from '@constants/text';

const initialState: StateMenu<IDataMenu> = {
  data: {
    listMenu: [],
    current_page: BANK_USER.PAGE,
    per_page: BANK_USER.LIMIT,
    total: 1,
  },
  loading: false,
  error: '',
};

export const getAllMenu = createAsyncThunk('Menu/getAll', async (payload: Partial<IReqGetAllMenu>, { getState }) => {
  try {
    const { data: dataRes } = await Menu.getMenu({ ...payload });
    const { data, current_page, total, per_page } = dataRes;
    return {
      listMenu: [...data],
      current_page,
      total,
      per_page,
    };
  } catch (error) {
    console.log(error);
  }
});

export const createMenu = createAsyncThunk(
  'Menu/create',
  async (payload: PayloadCallBack<ICreateMenu>, { getState }) => {
    try {
      const { messages } = await Menu.create({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const updateMenu = createAsyncThunk('Menu/edit', async (payload: PayloadCallBack<IEditMenu>, { getState }) => {
  try {
    const { messages } = await Menu.update({ ...payload.param });
    payload.callback(messages);
  } catch (error) {
    console.log(error);
  }
});

export const deleteMenu = createAsyncThunk(
  'Menu/delete',
  async (payload: PayloadCallBack<IDeleteMenu>, { getState }) => {
    try {
      const { messages } = await Menu.delete({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

const menuSlice = createSlice({
  name: 'Menu',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('Menu/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Menu/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Menu/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default menuSlice.reducer;
