import TransferPointComponent from '@components/admin/manager-user/transfer-point';
import React from 'react';

export default function TransferPoint() {
  return (
    <>
      <TransferPointComponent />
    </>
  );
}
