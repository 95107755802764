import { IDataRes, IDeleteLogs, ILogs, IReqGetAllLogs } from '@interfaces/Log';
import httpRequest from './Api';

const Logs = {
  getLogs({ ...rest }: Partial<IReqGetAllLogs>): Promise<IDataRes<ILogs>> {
    const url = '/list-logs';
    return httpRequest.get(url, { params: { ...rest } });
  },

  delete({ ...rest }: IDeleteLogs): Promise<{ messages: string }> {
    const url = '/delete-log';
    return httpRequest.post(url, { ...rest });
  },
};

export default Logs;
