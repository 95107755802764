import { useAppDispatch } from '@hook/index';
import { ParamReq } from '@interfaces/CommonInterface';
import { IGame } from '@interfaces/Game';
import { ITransferPoint } from '@interfaces/TransferPoint';
import { IUser } from '@interfaces/User';
import { Col, Form, Input, Row, Select } from 'antd';
import React, { useState } from 'react';

import { startOfDay, endOfDay, addDays, subDays } from 'date-fns';
import DateRangePicker, { RangeType } from 'rsuite/esm/DateRangePicker';
import { convert } from '@utils/convert';
export interface IFormSearchProps {
  handleChangeParam: (param: ParamReq) => void;
  paramCurrent?: ParamReq;
  dataUser: IUser[];
}

export default function FormSearch(props: IFormSearchProps) {
  const { handleChangeParam, paramCurrent, dataUser } = props;
  const [param, setParam] = useState<any>({});
  const [form] = Form.useForm();
  const Ranges: RangeType[] = [
    {
      label: 'Hôm nay',
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      label: 'Hôm qua',
      value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))],
    },
    {
      label: '7 ngày qua',
      value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
    },
    {
      label: '15 ngày qua',
      value: [startOfDay(subDays(new Date(), 14)), endOfDay(new Date())],
    },
    {
      label: '30 ngày qua',
      value: [startOfDay(subDays(new Date(), 29)), endOfDay(new Date())],
    },
    {
      label: '90 ngày qua',
      value: [startOfDay(subDays(new Date(), 89)), endOfDay(new Date())],
    },
  ];
  const handleChangeTime = (event) => {
    if (event === null) {
      setParam({ ...param, time_range: '' });
      handleChangeParam({
        ...param,
        time_range: '',
      });
      return;
    }
    setParam({ ...param, time_range: convert.formatDate(event[0]) + ' - ' + convert.formatDate(event[1]) });
    handleChangeParam({
      ...param,
      time_range: convert.formatDate(event[0]) + ' - ' + convert.formatDate(event[1]),
    });
  };
  const handleDetectDuplicateField = (value: string, key: string, dataField: any) => {
    if (value === param[key]) {
      form.setFieldsValue({ ...dataField, [key]: '' });
    }
  };
  const handleChange = (event) => {
    if (event.user_id) {
      setParam({ ...param, user_id: event.user_id });
      handleChangeParam({ ...param, user_id: event.user_id });
      // handleDetectDuplicateField(event.game_id, 'game_transfer_id', event);
    } else if (event.invite_id) {
      setParam({ ...param, invite_id: event.invite_id });
      handleChangeParam({ ...param, invite_id: event.invite_id });
      // handleDetectDuplicateField(event.game_transfer_id, 'game_id', event);
    } else {
      setParam({ ...param, ...event });
      handleChangeParam({ ...param, ...event });
    }
  };
  return (
    <div className="box-wrap custom-col custom-m-x">
      <Form
        form={form}
        className="search-group"
        initialValues={param}
        onValuesChange={handleChange}
        name="advanced_search"
        layout="horizontal"
        size={'large'}
      >
        <div className="row">
          <div className="col">
            <Form.Item name={'user_id'}>
              <Select
                defaultValue={paramCurrent?.user_id}
                showSearch
                allowClear
                placeholder="Chọn thành viên giới thiệu"
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={dataUser.map((item) => ({
                  value: item.id,
                  label: item.username,
                  disabled: param.invite_id === item.id,
                }))}
              />
            </Form.Item>
          </div>
          <div className="col">
            <Form.Item name={'invite_id'}>
              <Select
                defaultValue={paramCurrent?.user_id}
                showSearch
                allowClear
                placeholder="Chọn thành viên được mời"
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={dataUser.map((item) => ({
                  value: item.id,
                  label: item.username,
                  disabled: param.user_id === item.id,
                }))}
              />
            </Form.Item>
          </div>
          <div className="col">
            <div className="date-custom">
              <DateRangePicker
                ranges={Ranges}
                className="date-custom-style"
                format="yyyy-MM-dd"
                onChange={handleChangeTime}
                style={{ width: 300 }}
                placeholder="Thời gian tạo"
              />
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
