import React, { useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Switch, DatePicker } from 'antd';
import { useAppDispatch } from '@hook/index';
import './style.scss';
import { IUpdateFile } from '@interfaces/File';
import { updateFile } from '@store/fileSlice';
import UploadFile from '@components/common/upload/UploadFile';
export interface IFormCreateComponent {
  handleCloseScreen: (type: string) => void;
  getData: (message?: string) => void;
}
const listTypeFile = [
  {
    value: 'pic',
    label: 'Hình ảnh',
  },
  {
    value: 'file',
    label: 'mp3, mp4, ...',
  },
];
export default function FormCreateComponent(props: IFormCreateComponent) {
  const { getData } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [dataForm, setDataForm] = useState<IUpdateFile>();

  const handleSubmint = (event: IUpdateFile) => {
    setDataForm({ ...(dataForm as IUpdateFile), ...event });
    dispatch(
      updateFile({
        param: {
          ...dataForm,
          ...event,
        },
        callback: getData,
      }),
    );
  };

  const handleChangeFile = (value: any, title: string) => {
    setDataForm({ ...(dataForm as IUpdateFile), [title]: value });
  };
  return (
    <>
      <Form
        form={form}
        className="form-event"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        initialValues={dataForm}
        onFinish={handleSubmint}
        style={{ maxWidth: 1200, width: '100%' }}
        size={'large'}
      >
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label="Tải file lên">
              <UploadFile handleChangeFile={handleChangeFile} title="file" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name="type_file"
              initialValue={{
                value: 'pic',
                label: 'Hình ảnh',
              }}
              label="Loại file"
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={listTypeFile}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name="category"
              label="Danh mục"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item rules={[{ required: true, message: 'Vui lòng không được để trống' }]} label=" " colon={false}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                Thêm File
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}
