import {
  ICreateTransferPoint,
  IDataRes,
  IDeleteTransferPoint,
  IEditTransferPoint,
  ITransferPoint,
  IReqGetAllTransferPoint,
} from '@interfaces/TransferPoint';
import httpRequest from './Api';

const TransferPoint = {
  getTransferPoint({ ...rest }: Partial<IReqGetAllTransferPoint>): Promise<IDataRes<ITransferPoint>> {
    const url = '/list-transfer-points';
    return httpRequest.get(url, { params: { ...rest } });
  },
  create({ ...rest }: ICreateTransferPoint): Promise<{ messages: string }> {
    const url = '/create-transfer-point';
    return httpRequest.post(url, { ...rest });
  },
  update({ ...rest }: IEditTransferPoint): Promise<{ messages: string }> {
    const url = '/transfer-point-status';
    return httpRequest.post(url, { ...rest });
  },
  delete({ ...rest }: IDeleteTransferPoint): Promise<{ messages: string }> {
    const url = '/delete-transfer-point';
    return httpRequest.post(url, { ...rest });
  },
};

export default TransferPoint;
