import { useAppDispatch } from '@hook/index';
import { ParamReq } from '@interfaces/CommonInterface';
import { Col, Form, Row, Select } from 'antd';
import React, { useState } from 'react';

import { startOfDay, endOfDay, addDays, subDays } from 'date-fns';
import DateRangePicker, { RangeType } from 'rsuite/esm/DateRangePicker';
import { convert } from '@utils/convert';
export interface IFormSearchProps {
  handleChangeParam: (param: ParamReq) => void;
  paramCurrent?: ParamReq;
}

export default function FormSearch(props: IFormSearchProps) {
  const { handleChangeParam, paramCurrent } = props;
  const [param, setParam] = useState<any>({});
  const [form] = Form.useForm();
  const Ranges: RangeType[] = [
    {
      label: 'Hôm nay',
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      label: 'Hôm qua',
      value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))],
    },
    {
      label: '7 ngày qua',
      value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
    },
    {
      label: '15 ngày qua',
      value: [startOfDay(subDays(new Date(), 14)), endOfDay(new Date())],
    },
    {
      label: '30 ngày qua',
      value: [startOfDay(subDays(new Date(), 29)), endOfDay(new Date())],
    },
    {
      label: '90 ngày qua',
      value: [startOfDay(subDays(new Date(), 89)), endOfDay(new Date())],
    },
  ];
  const handleChangeTime = (event) => {
    if (event === null) {
      setParam({ ...param, time_range: '' });
      handleChangeParam({
        ...param,
        time_range: '',
      });
      return;
    }
    setParam({ ...param, time_range: convert.formatDate(event[0]) + ' - ' + convert.formatDate(event[1]) });
    handleChangeParam({
      ...param,
      time_range: convert.formatDate(event[0]) + ' - ' + convert.formatDate(event[1]),
    });
  };
  const handleChange = (event) => {
    if (event.status) {
      setParam({ ...param, status: event.status });
      handleChangeParam({ ...param, status: event.status });
    } else {
      setParam({ ...param, ...event });
      handleChangeParam({ ...param, ...event });
    }
  };
  return (
    <div className="box-wrap custom-col custom-m-x">
      <Form
        form={form}
        className="search-group"
        onValuesChange={handleChange}
        name="advanced_search"
        layout="horizontal"
        size={'large'}
      >
        <div className="row">
          <div className="col">
            <Form.Item name={'status'}>
              <Select
                placeholder="Trạng thái"
                defaultValue={paramCurrent?.status}
                allowClear
                options={[
                  { value: '1', label: 'Hoạt động' },
                  { value: '0', label: 'Không hoạt dộng' },
                ]}
              />
            </Form.Item>
          </div>
          <div className="col">
            <div className="date-custom">
              <DateRangePicker
                ranges={Ranges}
                className="date-custom-style"
                format="yyyy-MM-dd"
                onChange={handleChangeTime}
                style={{ width: 300 }}
                placeholder="Thời gian tạo"
              />
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
