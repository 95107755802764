import WithdrawMoneyComponent from '@components/admin/manager-user/withdraw-money';
import React from 'react';

export default function WithdrawMoney() {
  return (
    <>
      <WithdrawMoneyComponent />
    </>
  );
}
