import { PayloadCallBack } from '@interfaces/CommonInterface';
import {
  ICreateBankCard,
  IDeleteBankCard,
  IEditBankCard,
  IEditStatusBankCard,
  StateBankCard,
  IDataBankCard,
  IReqGetAllBankCard,
} from '@interfaces/BankCard';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import BankCard from '@services/BankCard';
import { BANK_CARD } from '@constants/text';

const initialState: StateBankCard<IDataBankCard> = {
  data: {
    listBankCard: [],
    listBankBankType: null,
    current_page: BANK_CARD.PAGE,
    per_page: BANK_CARD.LIMIT,
    total: 1,
  },
  loading: false,
  error: '',
};

export const getAllBankCard = createAsyncThunk(
  'BankCard/getAll',
  async (payload: Partial<IReqGetAllBankCard>, { getState }) => {
    try {
      const { data: dataRes } = await BankCard.getBankCard({ ...payload });
      const { data, current_page, total, per_page } = dataRes;
      return {
        listBankCard: [...data],
        current_page,
        total,
        per_page,
      };
    } catch (error) {
      console.log(error);
    }
  },
);

export const getAllBankType = createAsyncThunk('BankCard/bankType', async (_, { getState }) => {
  try {
    const { data: dataRes } = await BankCard.getBankType();
    const { data } = dataRes;
    return {
      listBankBankType: { ...data },
    };
  } catch (error) {
    console.log(error);
  }
});

export const createBankCard = createAsyncThunk(
  'BankCard/create',
  async (payload: PayloadCallBack<ICreateBankCard>, { getState }) => {
    try {
      const { messages } = await BankCard.create({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const updateBankCard = createAsyncThunk(
  'BankCard/edit',
  async (payload: PayloadCallBack<IEditBankCard>, { getState }) => {
    try {
      const { messages } = await BankCard.update({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const updateStatusBankCard = createAsyncThunk(
  'BankCard/edit',
  async (payload: PayloadCallBack<IEditStatusBankCard>, { getState }) => {
    try {
      const { messages } = await BankCard.update({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const deleteBankCard = createAsyncThunk(
  'BankCard/delete',
  async (payload: PayloadCallBack<IDeleteBankCard>, { getState }) => {
    try {
      const { messages } = await BankCard.delete({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

const bankCardSlice = createSlice({
  name: 'BankCard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('BankCard/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('BankCard/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...state.data, ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('BankCard/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default bankCardSlice.reducer;
