import * as React from 'react';
import './style.scss';
import { useAppSelector } from '@hook/index';
export interface LoadingProps {
  sx?: string;
}

export default function Loading({ sx }: LoadingProps) {
  const { loading: loadingUser } = useAppSelector((state) => state.userSlice);
  const { loading: loadingAuth } = useAppSelector((state) => state.authSlice);
  const { loading: loadPoint } = useAppSelector((state) => state.pointPriceSlice);
  const { loading: loadEvent } = useAppSelector((state) => state.eventSlice);
  const { loading: loadLevel } = useAppSelector((state) => state.levelSlice);
  const { loading: loadBanner } = useAppSelector((state) => state.bannerSlice);
  const { loading: loadBank } = useAppSelector((state) => state.bankSlice);
  const { loading: loadRole } = useAppSelector((state) => state.roleSlice);
  const { loading: loadPointUser } = useAppSelector((state) => state.pointUserSlice);
  const { loading: loadTransferPoint } = useAppSelector((state) => state.transferPointSlice);
  const { loading: loadTransferMoney } = useAppSelector((state) => state.transferMoneySlice);
  const { loading: loadBankCard } = useAppSelector((state) => state.bankCardSlice);
  const { loading: loadBankUser } = useAppSelector((state) => state.bankUserSlice);
  const { loading: loadGameUser } = useAppSelector((state) => state.gameUserSlice);
  const { loading: loadGame } = useAppSelector((state) => state.gameSlice);
  const loading =
    loadingUser ||
    loadingAuth ||
    loadPoint ||
    loadEvent ||
    loadLevel ||
    loadBanner ||
    loadBank ||
    loadRole ||
    loadPointUser ||
    loadTransferPoint ||
    loadTransferMoney ||
    loadBankCard ||
    loadBankUser ||
    loadGameUser ||
    loadGame;

  return (
    <>
      {loading && (
        <div className={`${sx ? sx : 'modal-loading'}`}>
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </>
  );
}
