import httpRequest from './Api';
import { IRole, IDataRes, IPermissions, ICreateRole, IEditRole, IDeleteRole } from '@interfaces/Role';

const Role = {
  getRole(): Promise<IDataRes<IRole, IPermissions>> {
    const url = '/list-roles';
    return httpRequest.get(url);
  },
  create({ ...rest }: ICreateRole): Promise<{ messages: string }> {
    const url = '/create-role';
    return httpRequest.post(url, { ...rest });
  },
  update({ ...rest }: IEditRole): Promise<{ messages: string }> {
    const url = '/update-role';
    return httpRequest.post(url, { ...rest });
  },
  delete({ ...rest }: IDeleteRole): Promise<{ messages: string }> {
    const url = '/delete-role';
    return httpRequest.post(url, { ...rest });
  },
};

export default Role;
