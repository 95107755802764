import TransferPointAgentComponent from '@components/admin/manager-agent/transfer-point-agent';
import React from 'react';

export default function TransferPointAgent() {
  return (
    <>
      <TransferPointAgentComponent />
    </>
  );
}
