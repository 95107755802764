import React, { ReactElement, useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Button, Dropdown, MenuProps, Pagination, Space, Table } from 'antd';
import {
  CaretDownOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { MenuTransaction } from '@constants/menu';
import ModalBase from '@components/common/modal/ModalBase';
import { ITransferMoney } from '@interfaces/TransferMoney';
import Delete from '../dialog/Delete';
import Accept from '../dialog/Accept';
import Tooltip from 'antd/es/tooltip';
import { convert } from '@utils/convert';
import { ParamReq } from '@interfaces/CommonInterface';
import { IPermissions } from '@interfaces/User';
import { useAppSelector } from '@hook/index';
import { isCheckRole } from '@utils/helper';
import AcceptStatus from '@components/common/role/Accept';
import CancelStatus from '@components/common/role/Cancel';
import DeleteStatus from '@components/common/role/Delete';
export interface IListMember {
  data: ITransferMoney[];
  current_page: number;
  total: number;
  per_page: number;
  loading: boolean;
  handleChangeParam: (param: ParamReq) => void;
  onEdit: (data: ITransferMoney, type: string) => void;
  onCreate: (type: string) => void;
  getData: (message?: string) => void;
}

export default function ListTransferMoney(props: IListMember) {
  const { data, current_page, total, per_page, loading, onEdit, onCreate, getData, handleChangeParam } = props;
  const [openModel, setOpenModal] = useState<boolean>(false);
  const [optoin, setOption] = useState({ id: 0, key: 0 });
  const [role, setRole] = useState<IPermissions[]>([]);
  const { data: currentUser } = useAppSelector((state) => state.authSlice);
  useEffect(() => {
    if (!currentUser.isLogin) return;
    setRole(convert.getRole(currentUser));
  }, [currentUser]);
  const handleShowOption = (id: number, key: number) => {
    setOpenModal(true);
    setOption({ id, key });
  };

  const handleSubmit = (value: boolean) => {
    setOpenModal(value);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  /** data for column */
  const columns: ColumnsType<ITransferMoney> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      width: 100,
    },
    {
      title: 'Tên người dùng',
      dataIndex: 'user',
      key: 'user',
      align: 'left',
      width: 200,
      render: (_, record) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>{record?.user?.username}</div>
          {record.event?.title && (
            <>
              {' '}
              {record.status === 0 ? (
                <div
                  style={{
                    backgroundColor: '#48b0f7',
                    color: 'white',
                    padding: '2px 5px',
                    borderRadius: '5px',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'initial',
                  }}
                >
                  {record.event?.title || ''}
                </div>
              ) : record.status === 1 ? (
                <div
                  style={{
                    backgroundColor: '#52c41a',
                    color: 'white',
                    padding: '2px 5px',
                    borderRadius: '5px',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'initial',
                  }}
                >
                  {record.event?.title || ''}
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: 'red',
                    color: 'white',
                    padding: '2px 5px',
                    borderRadius: '5px',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'initial',
                  }}
                >
                  {record.event?.title || ''}
                </div>
              )}
            </>
          )}
        </div>
      ),
    },
    {
      title: 'Tên game',
      dataIndex: 'game',
      key: 'game',
      width: 180,
      render: (_, record) => <Space size="middle">{record?.game?.title}</Space>,
    },
    {
      title: 'Mã giao dịch',
      dataIndex: 'bill_no',
      key: 'bill_no',
      width: 200,
    },
    {
      title: 'Số điểm (tiền)',
      dataIndex: 'point',
      key: 'point',
      width: 250,
      render: (_, record) => (
        <Space size="middle">
          {record?.point} ({convert.money(Number(record?.money), 'VNĐ')})
        </Space>
      ),
    },
    {
      title: 'Tài khoản chuyển tiền',
      dataIndex: 'bank',
      key: 'bank',
      align: 'left',
      width: 300,
      render: (_, record) => (
        <Space size="middle">
          <div className="list-bank-info">
            <p>Chủ tài khoản: {record.bank_admin?.owner_name || ''}</p>
            <p>Số tài khoản: {record.bank_admin?.card_no || ''}</p>
            <p>Ngân hàng: {record.bank_admin?.bank_type || ''}</p>
            <p>Địa chỉ: {record.bank_admin?.bank_address || ''}</p>
          </div>
        </Space>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          {record.status === 0 ? (
            <div style={{ backgroundColor: '#48b0f7', color: 'white', padding: '2px 5px', borderRadius: '5px' }}>
              Đang xử lí
            </div>
          ) : record.status === 1 ? (
            <div
              style={{
                backgroundColor: '#52c41a',
                color: 'white',
                padding: '2px 5px',
                borderRadius: '5px',
                whiteSpace: 'pre-wrap',
              }}
            >
              Thành Công
            </div>
          ) : (
            <div
              style={{
                backgroundColor: 'red',
                color: 'white',
                padding: '2px 5px',
                borderRadius: '5px',
                whiteSpace: 'pre-wrap',
              }}
            >
              Thất bại
            </div>
          )}
        </Space>
      ),
    },
    {
      title: 'Điều khiển',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 150,
      render: (_, record) => (
        <div className="group-action-icon">
          <Button onClick={() => onEdit(record, 'edit')} type="primary" icon={<EditOutlined />} />
          <AcceptStatus permission={isCheckRole(role, 'approval')}>
            <Tooltip placement="top" title={'Duyệt'}>
              <Button
                onClick={() => handleShowOption(record.id, 0)}
                type="primary"
                className="btn-accept"
                icon={<CheckCircleOutlined />}
                disabled={record.status === 1 || record.status === 2}
              ></Button>
            </Tooltip>
          </AcceptStatus>

          <CancelStatus permission={isCheckRole(role, 'cancel')}>
            <Tooltip placement="top" title={'Hủy'}>
              <Button
                onClick={() => handleShowOption(record.id, 1)}
                type="primary"
                className="btn-cancel"
                icon={<StopOutlined />}
                disabled={record.status === 1 || record.status === 2}
              ></Button>
            </Tooltip>
          </CancelStatus>
          <DeleteStatus permission={isCheckRole(role, 'delete')}>
            <Tooltip placement="top" title={'Xóa'}>
              <Button
                onClick={() => handleShowOption(record.id, 2)}
                type="primary"
                danger
                icon={<DeleteOutlined />}
              ></Button>
            </Tooltip>
          </DeleteStatus>
        </div>
      ),
    },
    {
      title: 'Tạo lúc',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'center',
      width: 200,
      render: (_, record) => <Space size="middle">{record.date_create}</Space>,
    },
    {
      title: 'Cập nhật lúc',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: 200,
      render: (_, record) => <Space size="middle">{record.date_update}</Space>,
    },
  ];

  const renderContentModal = (id: number, key?: number) => {
    switch (Number(key)) {
      case 0:
        return <Accept status={1} title={'Bạn có muốn duyệt'} handleClose={handleClose} getData={getData} id={id} />;
      case 1:
        return <Accept status={2} title={'Bạn có muốn hủy'} handleClose={handleClose} getData={getData} id={id} />;
      default:
        return <Delete handleClose={handleClose} getData={getData} id={id} />;
    }
  };
  const ChangePage = (pageItem: number, pageSize: number) => {
    handleChangeParam({ paginate: pageSize, page: pageItem });
  };

  return (
    <>
      <ModalBase openModel={openModel} title={''} handleSubmit={handleSubmit}>
        <div>{renderContentModal(optoin?.id, optoin?.key)}</div>
      </ModalBase>
      <div className="table-wrap">
        <div className="m-top">
          <Table
            className="table wrap-col"
            loading={loading}
            scroll={{ x: 960 }}
            pagination={false}
            columns={columns}
            dataSource={isCheckRole(role, 'view recharge money member') ? data : undefined}
            rowKey={(record) => record.id}
          />
          <div className="wrap-pagination">
            {data !== undefined && (
              <Pagination
                onChange={ChangePage}
                current={current_page}
                defaultPageSize={100}
                total={total}
                showSizeChanger={true}
                pageSizeOptions={[10, 20, 50, 100, 500, 1000]}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
