import LevelComponent from '@components/admin/level';
import React from 'react';

export default function Level() {
  return (
    <>
      <LevelComponent />
    </>
  );
}
