import { Button, Col, Form, Input, Row, message } from 'antd';
import React, { FormEvent, useState } from 'react';
import './style.scss';
import Auth, { IReqPassword } from '@services/Auth';
import { useAppDispatch } from '@hook/index';
import { handleLoading } from '@store/authSlice';
import { useNavigate } from 'react-router-dom';
import User, { IChangePassword } from '@services/User';
export interface IChangePasswordProps {
  id: number;
  handleClose: () => void;
}

export default function ChangePassword({ id, handleClose }: IChangePasswordProps) {
  const [form] = Form.useForm();
  const [dataForm, setDataForm] = useState<IChangePassword>();
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  const handleResetFile = () => {
    form.resetFields();
  };
  const handleSubmint = async (event: IChangePassword) => {
    setDataForm({ ...event });
    if (!event) {
      message.error('Yêu cầu thất bại');
      return;
    }
    dispatch(handleLoading(true));
    try {
      const { messages } = await User.changePassword({ ...event, user_id: String(id) });
      dispatch(handleLoading(false));
      message.success(messages);
    } catch (error) {
      message.error('Thất bại');
    }
    handleResetFile();
    handleClose();
  };
  return (
    <div className="wrapper-reset">
      <div className="body-reset">
        <Form
          // className="form-user"
          form={form}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          layout="horizontal"
          onFinish={handleSubmint}
          style={{ maxWidth: 1024, width: '100%' }}
          size={'large'}
        >
          <Row gutter={10} justify="start">
            <Col xs={{ span: 24 }} sm={{ span: 20 }}>
              <Form.Item
                rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
                label="Mật khẩu mới"
                name="password"
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} justify="start">
            <Col xs={{ span: 24 }} sm={{ span: 20 }}>
              <Form.Item
                rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
                label="Xác nhận mật khẩu mới"
                name="password_confirmation"
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} justify="start">
            <Col xs={{ span: 24 }} sm={{ span: 20 }}>
              <Form.Item rules={[{ required: true, message: 'Vui lòng không được để trống' }]} colon={false} label=" ">
                <div style={{ width: '100%', flexDirection: 'row', display: 'flex', gap: '10px' }}>
                  <Button type="primary" htmlType="submit">
                    Lưu
                  </Button>
                  <Button onClick={handleResetFile}>Đặt lại</Button>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}
