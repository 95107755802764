import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Pagination } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ModalBase from '@components/common/modal/ModalBase';
import { IFile } from '@interfaces/File';
import ImgFile from '@assets/folder.png';
import Delete from '../dialog/Delete';
import { ParamReq } from '@interfaces/CommonInterface';
export interface IListMember {
  data: IFile[];
  current_page: number;
  total: number;
  per_page: number;
  loading: boolean;
  onEdit: (data: IFile, type: string) => void;
  handleChangeParam: (param: ParamReq) => void;
  onCreate: (type: string) => void;
  getData: (message?: string) => void;
}

export default function DataTable(props: IListMember) {
  const { data, current_page, total, per_page, loading, onEdit, onCreate, getData, handleChangeParam } = props;
  const [openModel, setOpenModal] = useState<boolean>(false);
  const [optoin, setOption] = useState<any>({ id: 0, key: 0, title: '' });
  const handleShowOption = (id: number, item) => {
    setOpenModal(true);
    setOption({ id, key: item.key, title: item.label });
  };

  const handleSubmit = (value: boolean) => {
    setOpenModal(value);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDelete = (id: number) => {
    setOpenModal(true);
    setOption({ id, key: 2, title: 'Xác nhận' });
  };

  const handleReviewImg = (url: any) => {
    setOpenModal(true);
    setOption({ id: url, key: 1, title: String(url.name) });
  };

  const renderContentModal = (id: any, key?: number) => {
    switch (Number(key)) {
      case 1:
        return (
          <>
            {id?.type === 'pic' ? (
              <div className="img-view">
                <img src={id?.url} alt="" />
              </div>
            ) : (
              <div className="img-view">
                <audio controls>
                  <source src={id?.url} />
                </audio>
              </div>
            )}
          </>
        );
      default:
        return <Delete handleClose={handleClose} getData={getData} id={id} />;
    }
  };
  const ChangePage = (pageItem: number, pageSize: number) => {
    handleChangeParam({ paginate: pageSize, page: pageItem });
  };
  return (
    <>
      <ModalBase openModel={openModel} title={optoin?.title} handleSubmit={handleSubmit}>
        <div>{renderContentModal(optoin?.id, optoin?.key)}</div>
      </ModalBase>
      <div
        className="table-wrap"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: 'calc(100% - 40px)',
        }}
      >
        <div className="tool-bar">
          <div className="tool-bar__left">
            <button onClick={() => onCreate('create')} className="btn-add">
              <PlusOutlined />
              <strong>Thêm mới</strong>
            </button>
          </div>
        </div>
        <div
          className="m-top"
          style={{
            height: 'calc(100% - 120px)',
            overflow: 'auto',
          }}
        >
          <div className="list-file">
            {data.map((item, index) => (
              <div key={item.file_url} className="file-item">
                <div className="action-file">
                  <a href={item.file_url} target="_blank" rel="noreferrer">
                    <i className="fa-solid fa-eye"></i>
                  </a>

                  <i className="fa-solid fa-trash" onClick={() => handleDelete(item.id)}></i>
                </div>
                {item.file_type === 'pic' ? <img src={item.file_url} /> : <img src={ImgFile} />}
                <span>{item.original_name}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="wrap-pagination">
          {data !== undefined && (
            <Pagination
              onChange={ChangePage}
              current={current_page}
              defaultPageSize={30}
              total={total}
              showSizeChanger={false}
            />
          )}
        </div>
      </div>
    </>
  );
}
