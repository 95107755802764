import { ICreateLevel, IDataRes, IDeleteLevel, IEditLevel, ILevel } from '@interfaces/Level';
import httpRequest from './Api';

const Level = {
  getLevel({ ...rest }: Partial<{ time_range: any }>): Promise<IDataRes<ILevel>> {
    const url = '/list-levels';
    return httpRequest.get(url, { params: { ...rest } });
  },
  create({ ...rest }: ICreateLevel): Promise<{ messages: string }> {
    const url = '/create-level';
    return httpRequest.post(
      url,
      { ...rest },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  update({ ...rest }: IEditLevel): Promise<{ messages: string }> {
    const url = '/update-level';
    return httpRequest.post(
      url,
      { ...rest },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  delete({ ...rest }: IDeleteLevel): Promise<{ messages: string }> {
    const url = '/delete-level';
    return httpRequest.post(url, { ...rest });
  },
};

export default Level;
