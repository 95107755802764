import React, { FormEvent, useEffect, useState } from 'react';
import { Button, Col, Form, Input, Radio, Row, Select } from 'antd';
import { IUpdateUser, IUser } from '@interfaces/User';
import { getAllpointPrice } from '@store/pointPrice';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { getAllUser, updateUser } from '@store/userSlice';
import UploadFileImg from '@components/common/upload/UploadFileImg';
import UploadMutipleImage from '@components/common/upload/UploadMutipleImage';

export interface IFormEditComponent {
  data: IUser;
  handleCloseScreen: (type: string) => void;
  getData: (message?: string) => void;
}
export default function FormEditComponent({ data, handleCloseScreen, getData }: IFormEditComponent) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading, data: dataUser } = useAppSelector((state) => state.userSlice);
  const { data: dataPointPrice } = useAppSelector((state) => state.pointPriceSlice);
  const { data: dataLevel } = useAppSelector((state) => state.levelSlice);
  const pointItem = dataPointPrice.filter((item) => Number(item.price) === Number(data.point_price))[0];
  const levetItem = dataLevel.find((item) => item.level_name === data.level_name);
  const [dataForm, setDataForm] = useState<IUpdateUser>({
    user_id: data.id,
    avatar: data.avatar,
    'image_other[]': data.image_other || null,
    type_user: data.type_user,
    email: data.email,
    mobile: data.mobile,
    zalo: data.zalo,
    telegram: data.telegram,
    viber: data.viber,
    gender: data.gender,
    invite_code: data?.invite_code,
    nick_name: data.nick_name,
    real_name: data.real_name,
    point_price: {
      value: pointItem?.price,
      label: pointItem?.title,
    },
    is_withdraw: data.is_withdraw,
    vip_id: {
      value: levetItem?.id,
      label: levetItem?.level_name,
    },
  });

  const handleSubmint = (event: IUpdateUser) => {
    setDataForm({ ...dataForm, ...event });
    dispatch(
      updateUser({
        param: {
          ...dataForm,
          ...event,
          point_price: typeof event.point_price === 'object' ? event.point_price.value : event.point_price,
          vip_id: typeof event.vip_id === 'object' ? event.vip_id.value : event.vip_id,
        },
        callback: getData,
      }),
    );
  };
  const handleChangeImg = (url: any, name: string) => {
    setDataForm({ ...(dataForm as IUpdateUser), [name]: url });
  };

  const handleChangeImgMuti = (imgList: any) => {
    setDataForm({ ...(dataForm as IUpdateUser), 'image_other[]': imgList });
  };
  return (
    <Form
      form={form}
      className="form-user form-col"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      initialValues={dataForm}
      onFinish={handleSubmint}
      layout="horizontal"
      style={{ maxWidth: 960, width: '100%' }}
      size={'large'}
    >
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item label="Tải hình lên">
            <UploadFileImg edit={true} urlImg={data.avatar} nameImg={'avatar'} handleChangeImg={handleChangeImg} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item label="Giấy tời khác">
            <UploadMutipleImage defaultValue={data.image_other || []} handleChangeImg={handleChangeImgMuti} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item
            rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
            name="real_name"
            label="Họ và Tên"
          >
            <Input placeholder="Tên thật" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item
            rules={[{ required: false, message: 'Vui lòng không được để trống' }]}
            name="nick_name"
            label="Danh xưng"
          >
            <Input placeholder="Danh xưng" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item
            rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
            label="Giới tính"
            name="gender"
          >
            <Radio.Group>
              <Radio value={0}>Nam</Radio>
              <Radio value={1}>Nữ</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item
            rules={[{ required: false, message: 'Vui lòng không được để trống' }]}
            name="mobile"
            label="Điện thoại"
          >
            <Input placeholder="Điện thoại" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item rules={[{ required: false, message: 'Vui lòng không được để trống' }]} name="email" label="Email">
            <Input placeholder="Email" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item rules={[{ required: false, message: 'Vui lòng không được để trống' }]} name="zalo" label="Zalo">
            <Input placeholder="Zalo" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item
            rules={[{ required: false, message: 'Vui lòng không được để trống' }]}
            name="telegram"
            label="Telegram"
          >
            <Input placeholder="Telegram" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item rules={[{ required: false, message: 'Vui lòng không được để trống' }]} name="viber" label="Viber">
            <Input placeholder="Viber" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item
            rules={[{ required: false, message: 'Vui lòng không được để trống' }]}
            name="invite_code"
            label="Mã giới thiệu"
          >
            <Input placeholder="Mã giới thiệu" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item
            rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
            name="point_price"
            label="Tỷ giá điểm"
          >
            <Select
              placeholder="Tỷ giá điểm"
              options={dataPointPrice.map((value, index) => ({
                label: value.title,
                value: value.price,
                key: value.id,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item rules={[{ required: true, message: 'Vui lòng không được để trống' }]} name="vip_id" label="Vip">
            <Select
              placeholder="Cấp bật"
              options={dataLevel.map((value, index) => ({
                label: value.level_name,
                value: Number(value.id),
                key: value.id,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item rules={[{ required: false, message: 'Vui lòng không được để trống' }]} label=" " colon={false}>
            <Button style={{ width: '100%' }} type="primary" htmlType="submit">
              Cập nhật
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
