import { ICreateMenu, IDataRes, IDeleteMenu, IEditMenu, IMenu, IReqGetAllMenu } from '@interfaces/Menu';
import httpRequest from './Api';

const Menu = {
  getMenu({ ...rest }: Partial<IReqGetAllMenu>): Promise<IDataRes<IMenu>> {
    const url = '/list-menus';
    return httpRequest.get(url, { params: { ...rest } });
  },
  create({ ...rest }: ICreateMenu): Promise<{ messages: string }> {
    const url = '/create-menu';
    return httpRequest.post(
      url,
      { ...rest },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  update({ ...rest }: IEditMenu): Promise<{ messages: string }> {
    const url = '/update-menu';
    return httpRequest.post(
      url,
      { ...rest },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  delete({ ...rest }: IDeleteMenu): Promise<{ messages: string }> {
    const url = '/delete-menu';
    return httpRequest.post(url, { ...rest });
  },
};

export default Menu;
