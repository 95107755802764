import { PayloadCallBack } from '@interfaces/CommonInterface';
import {
  ICreateListPost,
  IDeleteListPost,
  IEditListPost,
  StateListPost,
  IDataListPost,
  IReqGetAllListPost,
} from '@interfaces/ListPost';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ListPost from '@services/ListPost';
import { BANK } from '@constants/text';

const initialState: StateListPost<IDataListPost> = {
  data: {
    listPost: [],
    current_page: BANK.PAGE,
    per_page: BANK.LIMIT,
    total: 1,
  },
  loading: false,
  error: '',
};

export const getAllListPost = createAsyncThunk(
  'ListPost/getAll',
  async (payload: Partial<IReqGetAllListPost>, { getState }) => {
    try {
      const { data: dataRes } = await ListPost.getListPost({ ...payload });
      const { data, current_page, total, per_page } = dataRes;
      return {
        listPost: [...data],
        current_page,
        total,
        per_page,
      };
    } catch (error) {
      console.log(error);
    }
  },
);

export const createListPost = createAsyncThunk(
  'ListPost/create',
  async (payload: PayloadCallBack<ICreateListPost>, { getState }) => {
    try {
      const { messages } = await ListPost.create({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const updateListPost = createAsyncThunk(
  'ListPost/edit',
  async (payload: PayloadCallBack<IEditListPost>, { getState }) => {
    try {
      const { messages } = await ListPost.update({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const deleteListPost = createAsyncThunk(
  'ListPost/delete',
  async (payload: PayloadCallBack<IDeleteListPost>, { getState }) => {
    try {
      const { messages } = await ListPost.delete({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

const postSlice = createSlice({
  name: 'ListPost',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('ListPost/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('ListPost/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('ListPost/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default postSlice.reducer;
