import GameAgentComponent from '@components/admin/manager-agent/game-agent';
import React from 'react';

export default function GameAgent() {
  return (
    <>
      <GameAgentComponent />
    </>
  );
}
