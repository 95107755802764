import React, { useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Switch, DatePicker } from 'antd';
import { useAppDispatch, useAppSelector } from '@hook/index';
import './style.scss';
import Editor from '@components/common/editor';
import { ICreateEvent, IEvent } from '@interfaces/Event';
import { createEvent, updateEvent } from '@store/eventSlice';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import UploadFileImg from '@components/common/upload/UploadFileImg';
import EditText from '@components/common/editor-text';

dayjs.extend(weekday);
dayjs.extend(localeData);
export interface IFormEditComponent {
  handleCloseScreen: (type: string) => void;
  getData: (message?: string) => void;
  data: IEvent;
}
const { RangePicker } = DatePicker;

export default function FormEditComponent(props: IFormEditComponent) {
  const { getData, data } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading, data: dataEvent } = useAppSelector((state) => state.eventSlice);
  const [dataForm, setDataForm] = useState<ICreateEvent>({
    title: data.title,
    subtitle: data.subtitle,
    content: data.content,
    type: {
      value: data.type,
      label: Object.values(dataEvent.activity_type)[Number(data.type) - 1],
    },
    apply_type: {
      value: data.apply_type,
      label: Object.values(dataEvent.activity_apply_type)[Number(data.apply_type) - 1],
    },
    apply_url: data.apply_url,
    apply_desc: data.apply_desc,
    start_at:
      data.start_at && data.end_at
        ? [dayjs(data.start_at, 'YYYY/MM/DD') || '', dayjs(data.end_at, 'YYYY/MM/DD') || '']
        : '',
    date_desc: data.date_desc as string,
    is_open: !!Number(data.is_open),
    is_hot: !!Number(data.is_hot),
    rule_content: data.rule_content,
    cover_image: data.cover_image,
    end_at: '',
  });

  const handleSubmint = (event: ICreateEvent) => {
    setDataForm({ ...(dataForm as ICreateEvent), ...event });
    dispatch(
      updateEvent({
        param: {
          ...dataForm,
          ...event,
          id: data.id,
          start_at: (event?.start_at && event?.start_at[0].format('DD-MM-YYYY')) || null,
          end_at: (event?.start_at && event?.start_at[1].format('DD-MM-YYYY')) || null,
          apply_type: typeof event.apply_type === 'object' ? event.apply_type.value : event.apply_type,
          type: typeof event.type === 'object' ? event.type.value : event.type,
          date_desc: '',
          is_open: event.is_open ? 1 : 0,
          is_hot: event.is_hot ? 1 : 0,
        },
        callback: getData,
      }),
    );
  };
  const handleChangeEditContent = (content: string) => {
    setDataForm({ ...(dataForm as ICreateEvent), content: content });
  };
  const handleChangeEditDes = (apply_desc: string) => {
    setDataForm({ ...(dataForm as ICreateEvent), apply_desc: apply_desc });
  };

  const handleChangeImg = (url: string, name: string) => {
    setDataForm({ ...(dataForm as ICreateEvent), [name]: url });
  };

  return (
    <>
      <Form
        form={form}
        className="form-event"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        initialValues={dataForm}
        onFinish={handleSubmint}
        style={{ maxWidth: 1200, width: '100%' }}
        size={'large'}
      >
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name="title"
              label="Tiêu đề"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: false, message: 'Vui lòng không được để trống' }]}
              name="subtitle"
              label="Phụ đề"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label="Tải hình lên">
              <UploadFileImg
                edit={true}
                nameImg={'cover_image'}
                urlImg={data.cover_image}
                handleChangeImg={handleChangeImg}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item rules={[{ required: false, message: 'Vui lòng không được để trống' }]} label="Nội dung">
              <EditText content={data.content} handleChangeEdit={handleChangeEditContent} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              label="Loại sự kiện"
              name="type"
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={Object.values(dataEvent?.activity_type).map((item, index) => ({
                  value: String(index + 1),
                  label: String(item),
                }))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name={'apply_type'}
              label="Các loại hoạt động"
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={Object.values(dataEvent?.activity_apply_type).map((item, index) => ({
                  value: String(index + 1),
                  label: String(item),
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col span={24}>
            <Form.Item
              rules={[{ required: false, message: 'Vui lòng không được để trống' }]}
              name="start_at"
              label="Thời gian"
            >
              <RangePicker placeholder={['Ngày bắt đầu', 'Ngày kết thúc']} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: false, message: 'Vui lòng không được để trống' }]}
              name={'apply_url'}
              label="Link liên kêt"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col span={24}>
            <Form.Item rules={[{ required: false, message: 'Vui lòng không được để trống' }]} label="Mô tả">
              <EditText content={data.apply_desc} handleChangeEdit={handleChangeEditDes} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col span={24}>
            <Form.Item rules={[{ required: false, message: 'Vui lòng không được để trống' }]} name="is_open" label="Mở">
              <Switch defaultChecked={!!Number(data.is_open)} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10} justify="start">
          <Col span={24}>
            <Form.Item
              rules={[{ required: false, message: 'Vui lòng không được để trống' }]}
              name="is_hot"
              label="Là nóng"
            >
              <Switch defaultChecked={!!Number(data.is_hot)} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: false, message: 'Vui lòng không được để trống' }]}
              name="rule_content"
              label="Vai trò nội dung"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label=" " colon={false}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                Cập nhật
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}
