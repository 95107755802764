import { PayloadCallBack } from '@interfaces/CommonInterface';
import {
  ICreateTransferPoint,
  IDeleteTransferPoint,
  IEditTransferPoint,
  StateTransferPoint,
  IDataTransferPoint,
  IReqGetAllTransferPoint,
} from '@interfaces/TransferPoint';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import TransferPoint from '@services/TransferPoint';
import { BANK_USER } from '@constants/text';

const initialState: StateTransferPoint<IDataTransferPoint> = {
  data: {
    listTransferPoint: [],
    current_page: BANK_USER.PAGE,
    per_page: BANK_USER.LIMIT,
    total: 1,
  },
  loading: false,
  error: '',
};

export const getAllTransferPoint = createAsyncThunk(
  'TransferPoint/getAll',
  async (payload: Partial<IReqGetAllTransferPoint>, { getState }) => {
    try {
      const { data: dataRes } = await TransferPoint.getTransferPoint({ ...payload });
      const { data, current_page, total, per_page } = dataRes;
      return {
        listTransferPoint: [...data],
        current_page,
        total,
        per_page,
      };
    } catch (error) {
      console.log(error);
    }
  },
);

export const createTransferPoint = createAsyncThunk(
  'TransferPoint/create',
  async (payload: PayloadCallBack<ICreateTransferPoint>, { getState }) => {
    try {
      const { messages } = await TransferPoint.create({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const updateTransferPoint = createAsyncThunk(
  'TransferPoint/edit',
  async (payload: PayloadCallBack<IEditTransferPoint>, { getState }) => {
    try {
      const { messages } = await TransferPoint.update({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const deleteTransferPoint = createAsyncThunk(
  'TransferPoint/delete',
  async (payload: PayloadCallBack<IDeleteTransferPoint>, { getState }) => {
    try {
      const { messages } = await TransferPoint.delete({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

const transferPointSlice = createSlice({
  name: 'TransferPoint',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('TransferPoint/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('TransferPoint/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('TransferPoint/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default transferPointSlice.reducer;
