import AgentComponent from '@components/admin/manager-agent/list-agent';
import React from 'react';

export default function AgentListPage() {
  return (
    <>
      <AgentComponent />
    </>
  );
}
