import { IDataCallback } from '@interfaces/CommonInterface';
import { IUserInfo } from '@interfaces/User';
import { IUserLogin, StateAuth } from '@interfaces/UserInterface';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import Auth from '@services/Auth';
import { isPlainObject } from 'lodash';
import Cookies from 'universal-cookie';

let localUser;
try {
  const cookies = new Cookies();

  const token = cookies.get('userToken') as string;
  const userData = cookies.get('userData');
  if (isPlainObject(userData)) {
    localUser = { ...userData, accessToken: token, isLogin: true };
  }
} catch (error) {}
const initialState: StateAuth<IUserInfo> = {
  data: localUser || {
    isLogin: false,
  },

  loading: false,
  error: '',
};

export const authLogin = createAsyncThunk('auth/login', async (payload: IDataCallback<IUserLogin>, { getState }) => {
  try {
    const res = await Auth.login(payload.data);
    if (res.messages === 'Invalid credentials') {
      payload.error();
      return { accessToken: res.access_token || '', isLogin: false };
    }
    const cookies = new Cookies();

    cookies.set('userToken', res.access_token || '', {
      httpOnly: false,
      sameSite: 'lax',
      // expires: new Date(res.expires_at),
      // maxAge: res.expires_in, // 10800s to 3 house
    });
    payload.callback();
    return { accessToken: res.access_token || '', isLogin: true };
  } catch (error) {
    payload.error();
    console.log(error);
  }
});

export const authLogout = createAsyncThunk(
  'auth/logout',
  async (payload: { isLogin: boolean; redirectLogout: () => void }, { getState }) => {
    try {
      await Auth.logout();
      const cookies = new Cookies();
      cookies.remove('userToken');
      cookies.remove('userData');
      payload.redirectLogout();
      return { id: 0, email: '', status: 0, isLogin: false, accessToken: '' };
    } catch (error) {
      console.log(error);
    }
  },
);

export const getCurrentUser = createAsyncThunk(
  'auth/getUserCurrent',
  async (paylod: { callback: () => void }, { getState }) => {
    try {
      const res = await Auth.getUserCurrent();
      return { ...res.data, isLogin: true };
    } catch (error) {
      const cookies = new Cookies();
      cookies.remove('userToken');
      cookies.remove('userData');
      paylod.callback();
      console.log(error);
    }
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    handleLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('auth/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          if (action.type === 'user/logout/pending') return;
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('auth/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          state.data = { ...state.data, ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('auth/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});
export const { handleLoading } = authSlice.actions;
export default authSlice.reducer;
