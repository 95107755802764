import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

type IUploadMutipleImage = {
  handleChangeImg: (imgList: any) => void;
  defaultValue?: any;
  limit?: number;
};
const UploadMutipleImage = (props: IUploadMutipleImage) => {
  const { handleChangeImg, limit, defaultValue } = props;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>(() =>
    Array.isArray(defaultValue)
      ? defaultValue.map((item) => ({
          url: item,
          thumbUrl: item,
          name: 'img',
          uid: item,
          status: 'done',
        }))
      : defaultValue
      ? [{ url: defaultValue, thumbUrl: defaultValue, name: 'img', uid: defaultValue, status: 'done' }]
      : [],
  );
  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    let url: string = '';
    if (!file.url && !file.preview) {
      url = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(url || file.url || defaultValue || '');
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleChange: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
    let imgConvert: any = [];
    for (let item of newFileList) {
      if (item.originFileObj) {
        imgConvert = [...imgConvert, item.originFileObj];
      } else {
        let res = await fetch(item?.url as string);
        let myBlob = await res.blob();
        const myFile = new File([myBlob], 'image.jpeg', { type: myBlob.type });
        imgConvert = [...imgConvert, myFile];
      }
    }
    setFileList(newFileList);
    handleChangeImg(imgConvert);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <>
      <Upload
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        className="uploadMui"
      >
        {fileList.length >= (limit || 6) ? null : uploadButton}
      </Upload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};

export default UploadMutipleImage;
