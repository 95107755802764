import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Button, Pagination, Space, Table } from 'antd';
import {
  CaretDownOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import ModalBase from '@components/common/modal/ModalBase';
import { IAdmin } from '@interfaces/listAdmin';
import { convert } from '@utils/convert';
import { useAppSelector } from '@hook/index';
import { IPermissions } from '@interfaces/User';
import { isCheckRole } from '@utils/helper';
import Active from '@components/common/role/Active';
import EditTable from '@components/common/role/EditTable';
import DeleteTable from '@components/common/role/DeleteTable';
import Delete from '../dialog/Delete';
import { ParamReq } from '@interfaces/CommonInterface';
export interface IListMember {
  data: IAdmin[];
  current_page: number;
  total: number;
  per_page: number;
  loading: boolean;
  handleChangeParam: (param: ParamReq) => void;
  onEdit: (data: IAdmin, type: string) => void;
  onCreate: (type: string) => void;
  getData: (message?: string) => void;
}

export default function TableData(props: IListMember) {
  const { data, current_page, total, per_page, loading, onEdit, onCreate, getData, handleChangeParam } = props;
  const [openModel, setOpenModal] = useState<boolean>(false);
  const [optoin, setOption] = useState({ id: 0, key: 0, title: '' });
  const { data: currentUser } = useAppSelector((state) => state.authSlice);
  const [role, setRole] = useState<IPermissions[]>([]);

  useEffect(() => {
    if (!currentUser.isLogin) return;
    setRole(convert.getRole(currentUser));
  }, [currentUser]);
  const handleShowOption = (id: number, item) => {
    setOpenModal(true);
    setOption({ id, key: item.key, title: item.label });
  };

  const handleSubmit = (value: boolean) => {
    setOpenModal(value);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDelete = (id: number) => {
    setOpenModal(true);
    setOption({ id, key: 2, title: 'Xác nhận' });
  };

  /** data for column */
  const columns: ColumnsType<IAdmin> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      width: 50,
    },
    {
      title: 'Tên người dùng',
      dataIndex: 'username',
      key: 'username',
      width: 150,
    },
    {
      title: 'Nhóm quyền',
      dataIndex: 'username',
      key: 'username',
      width: 150,
      render: (_, record) => <Space size="middle">{record?.roles[0]?.description}</Space>,
    },
    {
      title: 'SĐT',
      dataIndex: 'mobile',
      key: 'mobile',
      align: 'left',
      width: 150,
    },
    {
      title: 'Telegram',
      dataIndex: 'telegram',
      key: 'telegram',
      align: 'left',
      width: 150,
    },
    {
      title: 'Zalo',
      dataIndex: 'zalo',
      key: 'zalo',
      align: 'left',
      width: 150,
    },
    // {
    //   title: 'Vip',
    //   dataIndex: 'level_name',
    //   key: 'level_name',
    //   align: 'center',
    //   width: 150,
    //   render: (_, record) => <Space size="middle">{record?.level_name}</Space>,
    // },
    // {
    //   title: 'Tỷ giá',
    //   dataIndex: 'point_price',
    //   key: 'point_price',
    //   align: 'center',
    //   width: 150,
    //   render: (_, record) => <Space size="middle">{convert.money(record?.point_price, 'VNĐ')}</Space>,
    // },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          <Active permission={isCheckRole(role, 'active')} active={record.is_active}>
            <div className="status-col">
              {record.is_active ? (
                <span className="status-on btn-hover">
                  <CheckOutlined />
                </span>
              ) : (
                <span className="status-off btn-hover">
                  <CloseOutlined />
                </span>
              )}
            </div>
          </Active>
        </Space>
      ),
    },
    // {
    //   title: 'Rút tiền',
    //   dataIndex: 'is_withdraw',
    //   key: 'is_withdraw',
    //   align: 'center',
    //   width: 150,
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <Active permission={isCheckRole(role, 'withdraw')} active={record.is_withdraw}>
    //         <div className="status-col">
    //           {record.is_withdraw ? (
    //             <span className="status-on btn-hover">
    //               <CheckOutlined />
    //             </span>
    //           ) : (
    //             <span className="status-off btn-hover">
    //               <CloseOutlined />
    //             </span>
    //           )}
    //         </div>
    //       </Active>
    //     </Space>
    //   ),
    // },
    {
      title: 'Điều khiển',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 100,
      fixed: 'right',
      render: (_, record) => (
        <div className="group-action">
          <EditTable permission={isCheckRole(role, 'edit')}>
            <Button onClick={() => onEdit(record, 'edit')} type="primary" icon={<EditOutlined />} />
          </EditTable>
          <DeleteTable permission={isCheckRole(role, 'delete')}>
            <Button onClick={() => handleDelete(record.id)} type="primary" danger icon={<DeleteOutlined />} />
          </DeleteTable>
        </div>
      ),
    },
  ];

  const renderContentModal = (id: number, key?: number) => {
    return <Delete handleClose={handleClose} getData={getData} id={id} />;
  };

  const ChangePage = (pageItem: number, pageSize: number) => {
    handleChangeParam({ paginate: pageSize, page: pageItem });
  };
  return (
    <>
      <ModalBase openModel={openModel} title={optoin?.title} handleSubmit={handleSubmit}>
        <div>{renderContentModal(optoin?.id, optoin?.key)}</div>
      </ModalBase>
      <div className="table-wrap">
        <div className="tool-bar">
          <div className="tool-bar__left">
            <button onClick={() => onCreate('create')} className="btn-add">
              <PlusOutlined />
              <strong>Thêm mới</strong>
            </button>
          </div>
        </div>
        <div className="m-top">
          <Table
            className="table wrap-col"
            loading={loading}
            scroll={{ x: 960 }}
            pagination={false}
            columns={columns}
            dataSource={data}
            rowKey={(record) => record.id}
          />
          <div className="wrap-pagination">
            {data !== undefined && (
              <Pagination
                onChange={ChangePage}
                current={current_page}
                defaultPageSize={100}
                total={total}
                showSizeChanger={true}
                pageSizeOptions={[10, 20, 50, 100, 500, 1000]}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
