import React, { ReactElement, useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Button, Dropdown, MenuProps, Pagination, Space, Table } from 'antd';
import { IGameUser, IGames } from '@interfaces/GameUser';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import ModalBase from '@components/common/modal/ModalBase';
import Delete from '../dialog/Delete';
import ViewTable from '../dialog/ViewTable';
import { ParamReq } from '@interfaces/CommonInterface';
import { convert } from '@utils/convert';
import { IPermissions } from '@interfaces/User';
import { useAppSelector } from '@hook/index';
import { isCheckRole } from '@utils/helper';
import EditTable from '@components/common/role/EditTable';
import DeleteTable from '@components/common/role/DeleteTable';
import CreateTable from '@components/common/role/CreateTable';
export interface IListGameUser {
  data: IGameUser[];
  current_page: number;
  total: number;
  per_page: number;
  loading: boolean;
  maxHeight?: number;
  handleChangeParam: (param: ParamReq) => void;
  onEdit: (data: IGames, type: string) => void;
  onCreate: (type: string) => void;
  getData: (message?: string) => void;
}

export default function ListGameUser(props: IListGameUser) {
  const { data, current_page, total, maxHeight, loading, onEdit, onCreate, getData, handleChangeParam } = props;
  const [openModel, setOpenModal] = useState<boolean>(false);
  const [optoin, setOption] = useState<any>({ id: 0, key: 0, title: '' });
  const [role, setRole] = useState<IPermissions[]>([]);
  const { data: currentUser } = useAppSelector((state) => state.authSlice);
  useEffect(() => {
    if (!currentUser.isLogin) return;
    setRole(convert.getRole(currentUser));
  }, [currentUser]);
  const handleShowOption = (data: object, key: number) => {
    setOpenModal(true);
    setOption({ id: data, key, title: 'Danh sách game' });
  };

  const handleSubmit = (value: boolean) => {
    setOpenModal(value);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDelete = (id: number) => {
    setOpenModal(true);
    setOption({ id, key: 2, title: 'Xác nhận' });
  };

  /** data for column */
  const columns: ColumnsType<IGameUser> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      width: 50,
    },
    {
      title: 'Tên người dùng',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: 150,
      render: (_, record) => <div>{record.user.username}</div>,
    },
    {
      title: 'Tên truy cập',
      dataIndex: 'username',
      key: 'username',
      align: 'center',
      width: 150,
    },
    {
      title: 'Mật khẩu',
      dataIndex: 'password',
      key: 'password',
      align: 'center',
      width: 150,
    },
    {
      title: 'Điểm số',
      dataIndex: 'point',
      key: 'point',
      align: 'center',
      width: 150,
    },
    {
      title: 'Tên game',
      dataIndex: 'game',
      key: 'game',
      align: 'center',
      width: 150,
      render: (_, record) => <div>{record.game?.title}</div>,
    },
    {
      title: 'Cập nhật lúc',
      dataIndex: 'updated_at',
      key: 'updated_at',
      align: 'center',
      width: 250,
      render: (_, record) => <Space size="middle">{convert.convertDateFormat(record.updated_at)}</Space>,
    },
    {
      title: 'Tạo lúc',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'center',
      width: 250,
      render: (_, record) => <Space size="middle">{convert.convertDateFormat(record.created_at)}</Space>,
    },
    {
      title: 'Điều khiển',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 100,
      render: (_, record) => (
        <div className="group-action">
          <EditTable permission={isCheckRole(role, 'edit list game member')}>
            <Button onClick={() => onEdit(record, 'edit')} type="primary" icon={<EditOutlined />} />
          </EditTable>
          <DeleteTable permission={isCheckRole(role, 'delete list game member')}>
            <Button onClick={() => handleDelete(record.id)} type="primary" danger icon={<DeleteOutlined />} />
          </DeleteTable>
        </div>
      ),
    },
  ];

  const renderContentModal = (data: number | object, key?: number) => {
    switch (Number(key)) {
      case 1:
        const dataItem: IGameUser = data as IGameUser;
        return <ViewTable data={dataItem} handleClose={handleClose} />;
      default:
        return <Delete handleClose={handleClose} getData={getData} id={data as number} />;
    }
  };
  const ChangePage = (pageItem: number, pageSize: number) => {
    handleChangeParam({ paginate: pageSize, page: pageItem });
  };
  return (
    <>
      <ModalBase openModel={openModel} title={optoin?.title} handleSubmit={handleSubmit}>
        <div>{renderContentModal(optoin?.id, optoin?.key)}</div>
      </ModalBase>
      <div className="table-wrap">
        <div className="tool-bar">
          <div className="tool-bar__left">
            <CreateTable permission={isCheckRole(role, 'create list game member')}>
              <button onClick={() => onCreate('create')} className="btn-add">
                <PlusOutlined />
                <strong>Thêm mới</strong>
              </button>
            </CreateTable>
          </div>
        </div>
        <div className="m-top">
          <Table
            className="table wrap-col"
            loading={loading}
            scroll={maxHeight ? { x: 960, y: maxHeight } : { x: 960 }}
            pagination={false}
            columns={columns}
            dataSource={isCheckRole(role, 'view game member') || isCheckRole(role, 'view client') ? data : undefined}
            rowKey={(record) => record.id}
          />
          <div className="wrap-pagination">
            {data !== undefined && (
              <Pagination
                onChange={ChangePage}
                current={current_page}
                total={total}
                defaultPageSize={100}
                showSizeChanger={true}
                pageSizeOptions={[10, 20, 50, 100, 500, 1000]}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
