import { PayloadCallBack } from '@interfaces/CommonInterface';
import { DataRole, ICreateRole, IDeleteRole, IEditRole, StateRole } from '@interfaces/Role';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import Role from '@services/Role';

const initialState: StateRole<DataRole> = {
  data: {
    permissions: [],
    role: [],
  },
  loading: false,
  error: '',
};

export const getAllRole = createAsyncThunk('role/getAll', async (_, { getState }) => {
  try {
    const { roles, permissions } = await Role.getRole();
    return {
      permissions: [...permissions],
      role: [...roles],
    };
  } catch (error) {
    console.log(error);
  }
});

export const createRole = createAsyncThunk(
  'role/update',
  async (payload: PayloadCallBack<ICreateRole>, { getState }) => {
    try {
      const { messages } = await Role.create({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const updateRole = createAsyncThunk('role/update', async (payload: PayloadCallBack<IEditRole>, { getState }) => {
  try {
    const { messages } = await Role.update({ ...payload.param });
    payload.callback(messages);
  } catch (error) {
    console.log(error);
  }
});

export const deleteRole = createAsyncThunk(
  'role/delete',
  async (payload: PayloadCallBack<IDeleteRole>, { getState }) => {
    try {
      const { messages } = await Role.delete({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    handleLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('role/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('role/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('role/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});
export const { handleLoading } = roleSlice.actions;
export default roleSlice.reducer;
