import { FastBackwardOutlined } from '@ant-design/icons';
import { ISetting } from '@interfaces/Setting';
import * as React from 'react';

export interface IEditRowProps {
  data: ISetting;
  handleCloseScreen: (type: string) => void;
  getData: (message?: string) => void;
}

export default function EditRow(props: IEditRowProps) {
  const { data, handleCloseScreen, getData } = props;
  return (
    <div className="screen-option">
      <div className="header">
        <h2 className="title-screen">Chỉnh sửa</h2>
        <button className="btn-back" onClick={() => handleCloseScreen('table')}>
          <FastBackwardOutlined /> Trở về
        </button>
      </div>
      <div className="body-screen">Edit</div>
    </div>
  );
}
