import {
  ICreateBankUser,
  IDataRes,
  IDeleteBankUser,
  IEditBankUser,
  IBankUser,
  IReqGetAllBankUser,
} from '@interfaces/BankUser';
import httpRequest from './Api';

const BankUser = {
  getBankUser({ ...rest }: Partial<IReqGetAllBankUser>): Promise<IDataRes<IBankUser>> {
    const url = '/list-user-banks';
    return httpRequest.get(url, { params: { ...rest } });
  },
  create({ ...rest }: ICreateBankUser): Promise<{ messages: string }> {
    const url = '/create-user-bank';
    return httpRequest.post(url, { ...rest });
  },
  update({ ...rest }: IEditBankUser): Promise<{ messages: string }> {
    const url = '/update-user-bank';
    return httpRequest.post(url, { ...rest });
  },
  delete({ ...rest }: IDeleteBankUser): Promise<{ messages: string }> {
    const url = '/delete-user-bank';
    return httpRequest.post(url, { ...rest });
  },
};

export default BankUser;
