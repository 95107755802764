import RoleComponent from '@components/admin/role';
import React from 'react';

export default function Role() {
  return (
    <>
      <RoleComponent />
    </>
  );
}
