import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Switch } from 'antd';
import { useAppDispatch } from '@hook/index';
import './style.scss';
import { convert } from '@utils/convert';
import { IBonus, IEditBonus } from '@interfaces/Bonus';
import { updateBonus } from '@store/bonusSlice';
export interface IFormEditComponent {
  handleCloseScreen: (type: string) => void;
  getData: (message?: string) => void;
  data: IBonus;
}

const optionsBonus = [
  {
    value: '0',
    label: 'Số tiền',
  },
  {
    value: '1',
    label: 'Tỉ lệ phần trăm',
  },
];

export default function FormEditComponent(props: IFormEditComponent) {
  const { getData, data } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [dataForm, setDataForm] = useState<IEditBonus>({
    id: data.id,
    type_user: data.type_user,
    price: convert.moneyInput(parseInt(data.price)),
    bonus: data.type_bonus === 0 ? convert.moneyInput(parseInt(data.bonus)) : parseInt(data.bonus),
    content: data.content,
    status: data.status,
    type_bonus: {
      value: optionsBonus[Number(data.type_bonus)]?.value,
      label: optionsBonus[Number(data.type_bonus)]?.label,
    },
  });

  const handleChange = (event) => {
    if (Object.keys(event).includes('type_bonus')) {
      setDataForm({ ...(dataForm as IEditBonus), ...event, type_bonus: event.type_bonus });
      form.setFieldsValue({
        ...event,
        type_bonus: event.type_bonus,
        bonus: null,
      });
    } else if (event.price) {
      form.setFieldsValue({
        ...event,
        price: convert.moneyInput(parseInt((event.price === '' ? 0 : event.price).replace(/,/g, '').replace(/,/g, ''))),
      });
    } else if (event.bonus) {
      form.setFieldsValue({
        ...event,
        bonus: convert.moneyInput(parseInt((event.bonus === '' ? 0 : event.bonus).replace(/,/g, '').replace(/,/g, ''))),
      });
    }
  };
  const handleSubmint = (event: IEditBonus) => {
    setDataForm({ ...(dataForm as IEditBonus), ...event });
    dispatch(
      updateBonus({
        param: {
          ...dataForm,
          ...event,
          status: event.status ? 1 : 0,
          type_bonus: typeof event.type_bonus === 'object' ? event.type_bonus.value : event.type_bonus,
          price: String(event.price).replace(/\./g, '').replace(/,/g, ''),
          bonus: String(event.bonus).replace(/\./g, '').replace(/,/g, ''),
          type_user: 3,
        },
        callback: getData,
      }),
    );
  };
  return (
    <>
      <Form
        form={form}
        className="form-event"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={handleSubmint}
        style={{ maxWidth: 1200, width: '100%' }}
        initialValues={dataForm}
        onValuesChange={handleChange}
        size={'large'}
      >
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name="price"
              label="Giá tiền"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name={'type_bonus'}
              label="Loại phần thưởng"
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={optionsBonus}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              name="bonus"
              label="Giá trị phần thưởng"
              rules={[
                {
                  message: 'Giá trị phần trăm không hợp lệ',
                  validator: (_, value) => {
                    if (dataForm?.type_bonus === undefined || Number(dataForm?.type_bonus) === 1) {
                      if (Number(parseInt((value === '' ? 0 : value).replace(/\./g, '').replace(/,/g, ''))) <= 100) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject('Số điểm phải lớn hơn hoặc bằng 1');
                      }
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Input disabled={dataForm?.type_bonus === undefined} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name={'content'}
              label="Nội dung phần thưởng"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col span={24}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name="status"
              initialValue={true}
              label="Trạng thái"
            >
              <Switch defaultChecked />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label=" " colon={false}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                Cập nhật
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}
