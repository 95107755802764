import { ListResponse } from '@interfaces/CommonInterface';
import httpRequest from './Api';
import {
  ICreateUser,
  IDataRes,
  IDataResItem,
  IDeleteUser,
  IReqGetAllUser,
  IUpdateUser,
  IUser,
  IUserInfo,
} from '@interfaces/User';

export interface IChangePassword {
  user_id: string;
  password: string;
  password_confirmation: string;
}

const User = {
  getUser({ ...rest }: Partial<IReqGetAllUser>): Promise<IDataRes<IUser>> {
    const url = '/list-users';
    return httpRequest.get(url, { params: { ...rest } });
  },
  getUserInfo({ ...rest }: Partial<{ user_id: number }>): Promise<IDataResItem<IUserInfo>> {
    const url = '/user-info';
    return httpRequest.get(url, { params: { ...rest } });
  },
  changePassword(body: IChangePassword): Promise<{ messages: string }> {
    const url = '/change-password-user';
    return httpRequest.post(url, body);
  },
  create({ ...rest }: ICreateUser): Promise<{ messages: string }> {
    const url = '/create-user';
    return httpRequest.post(
      url,
      { ...rest },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  update({ ...rest }: IUpdateUser): Promise<{ messages: string }> {
    const url = '/update-user';
    return httpRequest.post(
      url,
      { ...rest },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  delete({ ...rest }: IDeleteUser): Promise<{ messages: string }> {
    const url = '/delete-user';
    return httpRequest.post(url, { ...rest });
  },
  toggle({ ...rest }: { user_id: string; key: string }): Promise<{ messages: string }> {
    const url = '/toggle-active-user';
    return httpRequest.post(url, { ...rest });
  },
};

export default User;
