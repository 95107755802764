import { PayloadCallBack } from '@interfaces/CommonInterface';
import { ICreateEvent, IDataEvent, IDeleteEvent, IReqGetAllEvent, IUpdateEvent, StateEvent } from '@interfaces/Event';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import Event from '@services/Event';

const initialState: StateEvent<IDataEvent> = {
  data: {
    listEvent: [],
    activity_type: null,
    activity_apply_type: null,
    current_page: 1,
    per_page: 1,
    total: 1,
  },
  loading: false,
  error: '',
};

export const getAllEvent = createAsyncThunk('event/getAll', async (payload: Partial<IReqGetAllEvent>, { getState }) => {
  try {
    const { data: dataRes, activity_apply_type, activity_type } = await Event.getEvent({ ...payload });
    const { data, current_page, total, per_page } = dataRes;
    return {
      listEvent: [...data],
      activity_apply_type,
      current_page,
      total,
      per_page,
      activity_type,
    };
  } catch (error) {
    console.log(error);
  }
});

export const createEvent = createAsyncThunk(
  'event/create',
  async (payload: PayloadCallBack<ICreateEvent>, { getState }) => {
    try {
      const { messages } = await Event.create({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const updateEvent = createAsyncThunk(
  'event/update',
  async (payload: PayloadCallBack<IUpdateEvent>, { getState }) => {
    try {
      const { messages } = await Event.update({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const deleteEvent = createAsyncThunk(
  'event/delete',
  async (payload: PayloadCallBack<IDeleteEvent>, { getState }) => {
    try {
      const { messages } = await Event.delete({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    handleLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('event/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('event/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('event/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});
export const { handleLoading } = eventSlice.actions;
export default eventSlice.reducer;
