import BankUserComponent from '@components/admin/manager-user/bank-user';
import React from 'react';

export default function BankUserPage() {
  return (
    <>
      <BankUserComponent />
    </>
  );
}
