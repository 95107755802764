import {
  ICreateTransferMoney,
  IDataRes,
  IDeleteTransferMoney,
  IEditTransferMoney,
  ITransferMoney,
  IReqGetAllTransferMoney,
  IUpdateTransferEvent,
} from '@interfaces/TransferMoney';
import httpRequest from './Api';

const TransferMoney = {
  getTransferMoney({ ...rest }: Partial<IReqGetAllTransferMoney>): Promise<IDataRes<ITransferMoney>> {
    const url = '/list-transfer-moneys';
    return httpRequest.get(url, { params: { ...rest } });
  },
  create({ ...rest }: ICreateTransferMoney): Promise<{ messages: string }> {
    const url = '/create-transfer-money';
    return httpRequest.post(url, { ...rest });
  },
  update({ ...rest }: IEditTransferMoney): Promise<{ messages: string }> {
    const url = '/transfer-money-status';
    return httpRequest.post(url, { ...rest });
  },
  delete({ ...rest }: IDeleteTransferMoney): Promise<{ messages: string }> {
    const url = '/delete-transfer-money';
    return httpRequest.post(url, { ...rest });
  },
  updateEvent({ ...rest }: IUpdateTransferEvent): Promise<{ messages: string }> {
    const url = '/update-transfer-event';
    return httpRequest.post(url, { ...rest });
  },
};

export default TransferMoney;
