import { useAppDispatch, useAppSelector } from '@hook/index';
import React, { useEffect, useState } from 'react';
import Container from '@components/common/container';
import './style.scss';
import EditRow from './table/Edit';
import CreateRow from './table/Create';
import { DataRole, IRole } from '@interfaces/Role';
import { getAllRole } from '@store/roleSlice';
import RoleList from './table/Role';
import showMessage from '@utils/helper';

export default function RoleComponent() {
  const dispatch = useAppDispatch();
  const { loading, data } = useAppSelector((state) => state.roleSlice);
  const [type, setType] = useState<string>('table');
  const [editData, setEditData] = useState<IRole>();
  const getData = (message?: string) => {
    showMessage(message);
    dispatch(getAllRole());
    handleChangTypeScreen('table');
  };
  useEffect(() => {
    getData();
  }, []);
  type IContent = { type: string; component: React.ReactNode };
  const handleChangTypeScreen = (typeCurrent: string) => {
    setType(typeCurrent);
  };

  const handleEdit = (data: IRole, type: string) => {
    setEditData(data);
    setType(type);
  };

  const TableRender = () => {
    return <RoleList getData={getData} data={data} onEdit={handleEdit} onCreate={handleChangTypeScreen} />;
  };

  const EditRowRender = () => {
    if (editData)
      return (
        <>
          <EditRow data={editData} handleCloseScreen={handleChangTypeScreen} getData={getData} />
        </>
      );
  };

  const CreateRowRender = () => {
    return (
      <>
        <CreateRow handleCloseScreen={handleChangTypeScreen} getData={getData} />
      </>
    );
  };
  const Content: IContent[] = [
    {
      type: 'table',
      component: TableRender(),
    },
    {
      type: 'create',
      component: CreateRowRender(),
    },
    {
      type: 'edit',
      component: EditRowRender(),
    },
  ];
  return <Container screen={Content} type={type} />;
}
