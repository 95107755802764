import { PayloadCallBack } from '@interfaces/CommonInterface';
import {
  ICreateGameUser,
  IDeleteGameUser,
  IEditGameUser,
  StateGameUser,
  IDataGameUser,
  IReqGetAllGameUser,
} from '@interfaces/GameUser';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import GameUser from '@services/GameUser';
import { BANK_USER } from '@constants/text';

const initialState: StateGameUser<IDataGameUser> = {
  data: {
    listGameUser: [],
    current_page: BANK_USER.PAGE,
    per_page: BANK_USER.LIMIT,
    total: 1,
  },
  loading: false,
  error: '',
};

export const getAllGameUser = createAsyncThunk(
  'GameUser/getAll',
  async (payload: Partial<IReqGetAllGameUser>, { getState }) => {
    try {
      const { data: dataRes } = await GameUser.getGameUser({ ...payload });
      const { data, current_page, total, per_page } = dataRes;
      return {
        listGameUser: [...data],
        current_page,
        total,
        per_page,
      };
    } catch (error) {
      console.log(error);
    }
  },
);

export const createGameUser = createAsyncThunk(
  'GameUser/create',
  async (payload: PayloadCallBack<ICreateGameUser>, { getState }) => {
    try {
      let param = payload.param;
      delete param.money;
      const { messages } = await GameUser.create({ ...param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const updateGameUser = createAsyncThunk(
  'GameUser/edit',
  async (payload: PayloadCallBack<IEditGameUser>, { getState }) => {
    try {
      const { messages } = await GameUser.update({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const deleteGameUser = createAsyncThunk(
  'GameUser/delete',
  async (payload: PayloadCallBack<IDeleteGameUser>, { getState }) => {
    try {
      const { messages } = await GameUser.delete({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

const gameUserSlice = createSlice({
  name: 'GameUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('GameUser/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('GameUser/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('GameUser/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default gameUserSlice.reducer;
