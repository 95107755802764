import axios, { AxiosRequestConfig } from 'axios';
import { logout } from '../../utils/index';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const httpRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL + 'admin/v1',
});

let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

const refreshToken = async () => {
  try {
    const response = await httpRequest.get('/refresh-token');
    const { access_token } = response.data;
    cookies.set('userToken', access_token || '', {
      httpOnly: false,
      sameSite: 'lax',
    });
    updateToken(access_token);
    return access_token;
  } finally {
    isRefreshing = false;
    refreshSubscribers.forEach((callback) => callback(cookies.get('userToken')));
    refreshSubscribers = [];
  }
};

const updateToken = (token: string) => {
  httpRequest.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

httpRequest.interceptors.request.use(
  function (config: any) {
    const storagedToken = cookies.get('userToken');
    if (storagedToken) {
      config.headers.Authorization = `Bearer ${storagedToken}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

httpRequest.interceptors.response.use(
  function (res) {
    return res.data;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      logout();
    }
    return Promise.reject(error);
  },
);

export default httpRequest;
