import { PayloadCallBack } from '@interfaces/CommonInterface';
import { ICreateGame, IDeleteGame, IEditGame, IGame, IReqGetAllGame, StateGame } from '@interfaces/Game';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Game from '@services/Game';

const initialState: StateGame<IGame> = {
  data: [],
  loading: false,
  error: '',
};

export const getAllGame = createAsyncThunk(
  'Game/getAll',
  async (payload: Partial<{ time_range: any }>, { getState }) => {
    try {
      const { data } = await Game.getGame({ ...payload });
      return data.data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const createGame = createAsyncThunk(
  'Game/create',
  async (payload: PayloadCallBack<ICreateGame>, { getState }) => {
    try {
      const { messages } = await Game.create({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const updateGame = createAsyncThunk('Game/edit', async (payload: PayloadCallBack<IEditGame>, { getState }) => {
  try {
    const { messages } = await Game.update({ ...payload.param });
    payload.callback(messages);
  } catch (error) {
    console.log(error);
  }
});

export const deleteGame = createAsyncThunk(
  'Game/delete',
  async (payload: PayloadCallBack<IDeleteGame>, { getState }) => {
    try {
      const { messages } = await Game.delete({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

const gameSlice = createSlice({
  name: 'Game',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('Game/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Game/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = [...action.payload];
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Game/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default gameSlice.reducer;
