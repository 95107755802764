import { Action, configureStore, getDefaultMiddleware, ThunkAction } from '@reduxjs/toolkit';
import authSlice from './authSlice';
import userSlice from './userSlice';
import pointPriceSlice from './pointPrice';
import eventSlice from './eventSlice';
import levelSlice from './levelSlice';
import bannerSlice from './bannerSlice';
import bankSlice from './bankSlice';
import roleSlice from './roleSlice';
import bankUserSlice from './bankUserSlice';
import bankCardSlice from './bankCardSlice';
import gameSlice from './gameSlice';
import transferMoneySlice from './transferMoneySlice';
import pointUserSlice from './pointUserSlice';
import transferPointSlice from './transferPointSlice';
import gameUserSlice from './gameUserSlice';
import inviteUserSlice from './inviteUserSlice';
import menuSlice from './menuSlice';
import pageSlice from './pageSlice';
import categorySlice from './categorySlice';
import postSlice from './postSlice';
import adminSlice from './adminSlice';
import logSlice from './logSlice';
import bonusSlice from './bonusSlice';
import fileSlice from './fileSlice';
import settingSlice from './settingSlice';
import notifySlice from './notifySlice';
import profitStatisticsSlice from './profitStatisticsSlice';
export const store = configureStore({
  reducer: {
    authSlice: authSlice,
    userSlice: userSlice,
    pointPriceSlice: pointPriceSlice,
    eventSlice: eventSlice,
    levelSlice: levelSlice,
    bannerSlice: bannerSlice,
    bankSlice: bankSlice,
    roleSlice: roleSlice,
    bankUserSlice: bankUserSlice,
    bankCardSlice: bankCardSlice,
    gameSlice: gameSlice,
    transferMoneySlice: transferMoneySlice,
    pointUserSlice: pointUserSlice,
    transferPointSlice: transferPointSlice,
    gameUserSlice: gameUserSlice,
    inviteUserSlice: inviteUserSlice,
    menuSlice: menuSlice,
    pageSlice: pageSlice,
    categorySlice: categorySlice,
    postSlice: postSlice,
    adminSlice: adminSlice,
    logSlice: logSlice,
    bonusSlice: bonusSlice,
    fileSlice: fileSlice,
    settingSlice: settingSlice,
    notifySlice: notifySlice,
    profitStatisticslice: profitStatisticsSlice,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
