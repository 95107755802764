import { useAppDispatch } from '@hook/index';
import { ParamReq } from '@interfaces/CommonInterface';
import { Col, Form, Input, Row, Select } from 'antd';
import React, { useState } from 'react';
import { DateRangePicker } from 'rsuite';
import { startOfDay, endOfDay, addDays, subDays } from 'date-fns';
import { RangeType } from 'rsuite/esm/DateRangePicker';
import { convert } from '@utils/convert';
import { debounce } from 'lodash';
export interface IFormSearchProps {
  handleChangeParam: (paramCurrent: ParamReq) => void;
  paramCurrent?: ParamReq;
}

const activeOption = [
  { value: '1', label: 'Hoạt động' },
  { value: '0', label: 'Không hoạt động' },
];

const userOption = [
  { value: '1', label: 'Quản trị viên' },
  { value: '2', label: 'Đại lý' },
  { value: '3', label: 'Thành viên' },
];
export default function FormSearch(props: IFormSearchProps) {
  const { handleChangeParam, paramCurrent } = props;
  const [param, setParam] = useState({});
  const [form] = Form.useForm();
  // const handleChange = (_, option) => {
  //   handleChangeParam({ [option.name]: option.value });
  // };
  const Ranges: RangeType[] = [
    {
      label: 'Hôm nay',
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      label: 'Hôm qua',
      value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))],
    },
    {
      label: '7 ngày qua',
      value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
    },
    {
      label: '15 ngày qua',
      value: [startOfDay(subDays(new Date(), 14)), endOfDay(new Date())],
    },
    {
      label: '30 ngày qua',
      value: [startOfDay(subDays(new Date(), 29)), endOfDay(new Date())],
    },
    {
      label: '90 ngày qua',
      value: [startOfDay(subDays(new Date(), 89)), endOfDay(new Date())],
    },
  ];
  const handleChange = (event) => {
    if (event.is_active) {
      setParam({ ...param, is_active: event.is_active });
      handleChangeParam({ ...param, is_active: event.is_active });
    } else if (event.type_user) {
      setParam({ ...param, type_user: event.type_user });
      handleChangeParam({ ...param, type_user: event.type_user });
    } else if (event.keysearch) {
      debouncedOnChange(event);
    } else {
      setParam({ ...param, ...event });
      handleChangeParam({ ...param, ...event });
    }
  };

  const handleSearch = (event) => {
    setParam({ ...param, keysearch: event.keysearch });
    handleChangeParam({ ...param, keysearch: event.keysearch });
  };

  const handleChangeTime = (event) => {
    if (event === null) {
      setParam({ ...param, time_range: '' });
      handleChangeParam({
        ...param,
        time_range: '',
      });
      return;
    }
    setParam({ ...param, time_range: convert.formatDate(event[0]) + ' - ' + convert.formatDate(event[1]) });
    handleChangeParam({
      ...param,
      time_range: convert.formatDate(event[0]) + ' - ' + convert.formatDate(event[1]),
    });
  };

  const debouncedOnChange = debounce(handleSearch, 1500);
  return (
    <div className="box-wrap custom-col custom-m-x">
      <Form
        form={form}
        className="search-group"
        name="advanced_search"
        layout="horizontal"
        size={'large'}
        onValuesChange={handleChange}
      >
        <div className="row">
          <div className="col">
            <Form.Item name="is_active">
              <Select
                defaultValue={paramCurrent?.is_active}
                placeholder="Trạng thái kích hoạt"
                allowClear
                options={activeOption}
              />
            </Form.Item>
          </div>
          <div className="col">
            <Form.Item name="keysearch">
              <Input placeholder="Tìm kiếm ..." />
            </Form.Item>
          </div>
          <div className="col">
            <div className="date-custom">
              <DateRangePicker
                ranges={Ranges}
                className="date-custom-style"
                format="yyyy-MM-dd"
                onChange={handleChangeTime}
                style={{ width: 300 }}
                placeholder="Thời gian tạo"
              />
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
