import React, { FormEvent, useEffect, useState } from 'react';
import { Button, Col, Form, Input, Radio, Row, Select } from 'antd';
import { ICreateUser, IUpdateUser, IUser } from '@interfaces/User';
import { getAllpointPrice } from '@store/pointPrice';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { createUser, getAllUser, updateUser } from '@store/userSlice';
import UploadMutipleImage from '@components/common/upload/UploadMutipleImage';
import UploadFileImg from '@components/common/upload/UploadFileImg';

export interface IFormCreateComponent {
  handleCloseScreen: (type: string) => void;
  getData: (message?: string) => void;
}
export default function FormCreateComponent({ handleCloseScreen, getData }: IFormCreateComponent) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const pointPrice = useAppSelector((state) => state.pointPriceSlice);
  const { loading, data: dataUser } = useAppSelector((state) => state.userSlice);
  const { data: dataLevel } = useAppSelector((state) => state.levelSlice);
  const [dataForm, setDataForm] = useState<ICreateUser>();

  const handleSubmint = (event: ICreateUser) => {
    setDataForm(event);
    dispatch(createUser({ param: { ...dataForm, ...event, type_user: 2 }, callback: getData }));
  };
  useEffect(() => {
    dispatch(getAllpointPrice({ type_user: 0 }));
  }, []);
  const handleChangeImg = (url: any, name: string) => {
    setDataForm({ ...(dataForm as ICreateUser), [name]: url });
  };

  const handleChangeImgMuti = (imgList: any) => {
    setDataForm({ ...(dataForm as ICreateUser), 'image_other[]': imgList });
  };
  return (
    <Form
      form={form}
      className="form-user form-col"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      onFinish={handleSubmint}
      layout="horizontal"
      style={{ maxWidth: 960, width: '100%' }}
      size={'large'}
    >
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item label="Ảnh đại diện">
            <UploadFileImg nameImg={'avatar'} handleChangeImg={handleChangeImg} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item label="Giấy tời khác">
            <UploadMutipleImage handleChangeImg={handleChangeImgMuti} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item
            rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
            name="real_name"
            label="Họ và Tên"
          >
            <Input placeholder="Tên thật" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item
            rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
            name="nick_name"
            label="Danh xưng"
          >
            <Input placeholder="Danh xưng" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item
            rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
            name="username"
            label="Tên người dùng"
          >
            <Input placeholder="Tên người dùng" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item
            rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
            label="Giới tính"
            initialValue={0}
            name="gender"
          >
            <Radio.Group defaultValue={0}>
              <Radio value={0}>Nam</Radio>
              <Radio value={1}>Nữ</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item
            rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
            name="password"
            label="Mật khẩu"
          >
            <Input.Password placeholder="Mật khẩu" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item
            rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
            name="password_confirm"
            label="Xác nhận mật khẩu"
          >
            <Input placeholder="Xác nhận mật khẩu" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item name="mobile" label="Điện thoại">
            <Input placeholder="Điện thoại" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item name="email" label="Email">
            <Input placeholder="Email" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item name="zalo" label="Zalo">
            <Input placeholder="Zalo" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item name="telegram" label="Telegram">
            <Input placeholder="Telegram" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item name="viber" label="Viber">
            <Input placeholder="Viber" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item style={{ width: '100%' }} name="invite_code" label="Mã giới thiệu">
            <Input placeholder="Mã giới thiệu" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item
            rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
            name="point_price"
            label="Giá/điểm"
          >
            <Select
              placeholder="Giá/điểm"
              options={pointPrice.data.map((value, index) => ({
                label: value.title,
                value: Number(value.price),
                key: value.id,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item rules={[{ required: true, message: 'Vui lòng không được để trống' }]} name="vip_id" label="Vip">
            <Select
              placeholder="Cấp bật"
              options={dataLevel.map((value, index) => ({
                label: value.level_name,
                value: Number(value.id),
                key: value.id,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item label=" " colon={false}>
            <Button style={{ width: '100%' }} type="primary" htmlType="submit">
              Thêm mới
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
