import React, { useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Switch, DatePicker } from 'antd';
import { useAppDispatch, useAppSelector } from '@hook/index';
import './style.scss';
import Editor from '@components/common/editor';
import { ICreateEvent } from '@interfaces/Event';
import { createEvent } from '@store/eventSlice';
import UploadFileImg from '@components/common/upload/UploadFileImg';
import EditText from '@components/common/editor-text';
export interface IFormCreateComponent {
  handleCloseScreen: (type: string) => void;
  getData: (message?: string) => void;
}

export default function FormCreateComponent(props: IFormCreateComponent) {
  const { getData } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading, data } = useAppSelector((state) => state.eventSlice);
  const [dataForm, setDataForm] = useState<ICreateEvent>();

  const { RangePicker } = DatePicker;

  const handleSubmint = (event: ICreateEvent) => {
    setDataForm({ ...(dataForm as ICreateEvent), ...event });

    dispatch(
      createEvent({
        param: {
          ...dataForm,
          ...event,
          start_at: (event?.start_at && event?.start_at[0].format('DD-MM-YYYY')) || null,
          end_at: (event?.start_at && event?.start_at[1].format('DD-MM-YYYY')) || null,
          is_open: event.is_open ? 1 : 0,
          is_hot: event.is_hot ? 1 : 0,
          date_desc: '',
        },
        callback: getData,
      }),
    );
  };
  const handleChangeEditContent = (content: string) => {
    setDataForm({ ...(dataForm as ICreateEvent), content: content });
  };
  const handleChangeEditDes = (apply_desc: string) => {
    setDataForm({ ...(dataForm as ICreateEvent), apply_desc: apply_desc });
  };

  const handleChangeImg = (url: any, name: string) => {
    setDataForm({ ...(dataForm as ICreateEvent), [name]: url });
  };
  return (
    <>
      <Form
        form={form}
        className="form-event"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={handleSubmint}
        style={{ maxWidth: 1200, width: '100%' }}
        size={'large'}
      >
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name="title"
              label="Tiêu đề"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: false, message: 'Vui lòng không được để trống' }]}
              name="subtitle"
              label="Phụ đề"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label="Tải hình lên">
              <UploadFileImg nameImg={'cover_image'} handleChangeImg={handleChangeImg} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col span={24}>
            <Form.Item rules={[{ required: false, message: 'Vui lòng không được để trống' }]} label="Nội dung">
              <EditText handleChangeEdit={handleChangeEditContent} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              label="Loại sự kiện"
              name="type"
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={Object.values(data.activity_type).map((item, index) => ({
                  value: String(index + 1),
                  label: String(item),
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name={'apply_type'}
              label="Các loại hoạt động"
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={Object.values(data.activity_apply_type).map((item, index) => ({
                  value: String(index + 1),
                  label: String(item),
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col span={24}>
            <Form.Item
              rules={[{ required: false, message: 'Vui lòng không được để trống' }]}
              name="start_at"
              label="Thời gian"
            >
              <RangePicker placeholder={['Ngày bắt đầu', 'Ngày kết thúc']} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: false, message: 'Vui lòng không được để trống' }]}
              name={'apply_url'}
              label="Cách áp dụng"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col span={24}>
            <Form.Item rules={[{ required: false, message: 'Vui lòng không được để trống' }]} label="Mô tả">
              <EditText handleChangeEdit={handleChangeEditDes} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col span={24}>
            <Form.Item
              rules={[{ required: false, message: 'Vui lòng không được để trống' }]}
              name="is_open"
              initialValue={true}
              label="Mở Link"
            >
              <Switch defaultChecked />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10} justify="start">
          <Col span={24}>
            <Form.Item
              rules={[{ required: false, message: 'Vui lòng không được để trống' }]}
              name="is_hot"
              label="Hot"
              initialValue={true}
            >
              <Switch defaultChecked />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: false, message: 'Vui lòng không được để trống' }]}
              name="rule_content"
              label="Vai trò nội dung"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label=" " colon={false}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                Thêm mới
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}
