import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Switch } from 'antd';
import { useAppDispatch } from '@hook/index';
import './style.scss';

import { updateEvent } from '@store/eventSlice';
import UploadFileImg from '@components/common/upload/UploadFileImg';
import { IUpdateSetting } from '@interfaces/Setting';
import { updateSetting } from '@store/settingSlice';

export interface ITabs {
  getData: (message?: string) => void;
  data: any;
}
export default function Tab3(props: ITabs) {
  const { getData, data } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  let dataConvert: any = {};
  data.forEach((item) => {
    dataConvert = { ...dataConvert, [item.key]: item.value };
  });

  const [dataForm, setDataForm] = useState<any>({ ...dataConvert });
  const handleSubmint = (event: IUpdateSetting) => {
    setDataForm({ ...(dataForm as IUpdateSetting), ...event });
    dispatch(
      updateSetting({
        param: { ...dataForm, ...event, group: 2 },
        callback: getData,
      }),
    );
  };

  const handleChangeImg = (url: string, name: string) => {
    setDataForm({ ...(dataForm as IUpdateSetting), [name]: url });
  };

  return (
    <>
      <Form
        form={form}
        className="form-event"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        initialValues={dataForm}
        onFinish={handleSubmint}
        style={{ maxWidth: 1200, width: '100%' }}
        size={'large'}
      >
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name="zalo" label="Zalo">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label="Zalo QRCode" rules={[{ required: true, message: 'Vui lòng không được để trống' }]}>
              {
                <UploadFileImg
                  edit={true}
                  nameImg={'zalo_qrcode'}
                  urlImg={dataConvert.zalo_qrcode}
                  handleChangeImg={handleChangeImg}
                />
              }
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name="telegram" label="Telegram">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label="Telegram QRCode">
              {
                <UploadFileImg
                  edit={true}
                  nameImg={'telegram_qrcode'}
                  urlImg={dataConvert.telegram_qrcode}
                  handleChangeImg={handleChangeImg}
                />
              }
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name="viber" label="Viber">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label="Viber QRCode">
              {
                <UploadFileImg
                  edit={true}
                  nameImg={'viber_qrcode'}
                  urlImg={dataConvert.viber_qrcode}
                  handleChangeImg={handleChangeImg}
                />
              }
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name="skype" label="Skype">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label="Skype QRCode" rules={[{ required: true, message: 'Vui lòng không được để trống' }]}>
              {
                <UploadFileImg
                  edit={true}
                  nameImg={'skype_qrcode'}
                  urlImg={data[1]?.value}
                  handleChangeImg={handleChangeImg}
                />
              }
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name="facebook" label="Facebook">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label="Facebook QRCode">
              {
                <UploadFileImg
                  edit={true}
                  nameImg={'facebook_qrcode'}
                  urlImg={dataConvert.facebook_qrcode}
                  handleChangeImg={handleChangeImg}
                />
              }
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col span={24}>
            <Form.Item name="web_status" label="Trạng thái website">
              <Switch defaultChecked={!!Number(dataConvert.web_status)} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label=" " colon={false}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                Cập nhật
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}
