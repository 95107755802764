import { PayloadCallBack } from '@interfaces/CommonInterface';
import { ICreateLevel, IDeleteLevel, IEditLevel, ILevel, StateLevel } from '@interfaces/Level';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Level from '@services/Level';

const initialState: StateLevel<ILevel> = {
  data: [],
  loading: false,
  error: '',
};

export const getAllLevel = createAsyncThunk(
  'Level/getAll',
  async (payload: Partial<{ time_range: any }>, { getState }) => {
    try {
      const { data } = await Level.getLevel({ ...payload });
      return data.data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const createLevel = createAsyncThunk(
  'Level/create',
  async (payload: PayloadCallBack<ICreateLevel>, { getState }) => {
    try {
      const { messages } = await Level.create({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const updateLevel = createAsyncThunk(
  'Level/edit',
  async (payload: PayloadCallBack<IEditLevel>, { getState }) => {
    try {
      const { messages } = await Level.update({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const deleteLevel = createAsyncThunk(
  'Level/delete',
  async (payload: PayloadCallBack<IDeleteLevel>, { getState }) => {
    try {
      const { messages } = await Level.delete({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

const levelSlice = createSlice({
  name: 'level',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('Level/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Level/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = [...action.payload];
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Level/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default levelSlice.reducer;
