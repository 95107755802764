import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Switch } from 'antd';
import { useAppDispatch, useAppSelector } from '@hook/index';
import './style.scss';
import { ICreateGameUser } from '@interfaces/GameUser';
import { createGameUser } from '@store/gameUserSlice';
import { getAllUser } from '@store/userSlice';
import { getAllGame } from '@store/gameSlice';
import { getAllpointPrice } from '@store/pointPrice';
import { IUser } from '@interfaces/User';
import { convert } from '@utils/convert';
export interface IFormCreateComponent {
  id?: number;
  handleCloseScreen: (type: string) => void;
  getData: (message?: string) => void;
}

export default function FormCreateComponent(props: IFormCreateComponent) {
  const { getData, id } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [dataForm, setDataForm] = useState<ICreateGameUser>();
  const [point, setPoint] = useState<string>('');
  const { loading: loadingUser, data: dataUser } = useAppSelector((state) => state.userSlice);
  const { loading: loadingGame, data: dataGame } = useAppSelector((state) => state.gameSlice);
  const { data: dataPoint } = useAppSelector((state) => state.pointPriceSlice);

  useEffect(() => {
    setPoint(dataUser.listUser.find((item) => item.id === id)?.point_price || '');
  }, [id]);

  const handleSubmint = (event: ICreateGameUser) => {
    setDataForm({ ...(dataForm as ICreateGameUser), ...event });
    dispatch(
      createGameUser({
        param: {
          ...dataForm,
          ...event,
          user_id: id || event?.user_id,
          point: parseInt(String(event.point).replace(/\./g, '').replace(/,/g, '')),
        },
        callback: getData,
      }),
    );
  };
  const handleChange = (event) => {
    if (Object.keys(event)[0] === 'user_id') {
      setPoint(dataUser.listUser.find((item) => item.id === event.user_id)?.point_price || '');
    } else if (event.money) {
      if (!Number(event.money) && event.money.length === 1) {
        form.setFieldsValue({
          ...event,
          money: 0,
          point: 0,
        });
      } else {
        form.setFieldsValue({
          ...event,
          money: convert.moneyInput(
            parseInt((event.money === '' ? 0 : event.money).replace(/,/g, '').replace(/,/g, '')),
          ),
          point: convert.moneyInput(parseInt(event.money.replace(/,/g, '').replace(/,/g, '')) / Number(point)),
        });
      }
    } else if (event.point) {
      if (!Number(event.point) && event.point.length === 1) {
        form.setFieldsValue({
          ...event,
          money: 0,
          point: 0,
        });
      } else {
        form.setFieldsValue({
          ...event,
          money: convert.moneyInput(parseInt(event.point.replace(/,/g, '').replace(/,/g, '')) * Number(point)),
          point: convert.moneyInput(parseInt(event.point.replace(/,/g, '').replace(/,/g, ''))),
        });
      }
    }
  };

  return (
    <>
      <Form
        form={form}
        className="form-event"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={handleSubmint}
        onValuesChange={handleChange}
        style={{ maxWidth: 1200, width: '100%' }}
        size={'large'}
      >
        {!id && (
          <Row gutter={10} justify="start">
            <Col xs={{ span: 24 }} sm={{ span: 20 }}>
              <Form.Item
                rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
                name={'user_id'}
                label="Tên người dùng"
              >
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={dataUser.listUser.map((item) => ({ label: item.username, value: item.id }))}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name={'game_id'}
              label="Trò chơi"
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={dataGame.map((item) => ({ label: item.title, value: item.id }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name="username"
              label="Tên đăng nhập"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name={'password'}
              label="Mật khẩu"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item rules={[{ required: true, message: 'Vui lòng không được để trống' }]} name="point" label="Điểm">
              <Input disabled={!point && !id} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name="money"
              label="Số tiền"
            >
              <Input disabled={!point && !id} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label=" " colon={false}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                Thêm mới
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}
