import { IDataRes, IProfitStatistics, IReqGetAllProfitStatistics } from '@interfaces/ProfitStatistics';
import httpRequest from './Api';

const ProfitStatistics = {
  getProfitStatistics({ ...rest }: Partial<IReqGetAllProfitStatistics>): Promise<IDataRes<IProfitStatistics>> {
    const url = '/profit-statistics';
    return httpRequest.get(url, { params: { ...rest } });
  },
};

export default ProfitStatistics;
