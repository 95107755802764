import { PayloadCallBack } from '@interfaces/CommonInterface';
import { IDeleteNotify, StateNotify, IDataNotify, IReqGetAllNotify } from '@interfaces/Notify';

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import Notify from '@services/Notify';
import { BANK_USER } from '@constants/text';

const initialState: StateNotify<IDataNotify> = {
  data: {
    listNotify: [],
    current_page: BANK_USER.PAGE,
    per_page: BANK_USER.LIMIT,
    total: 1,
    time_notify: '',
    ring_notify: '',
  },
  newNotify: [],
  loading: false,

  error: '',
};

export const getAllNotify = createAsyncThunk(
  'Notify/getAll',
  async (payload: Partial<IReqGetAllNotify>, { getState }) => {
    try {
      const { data: dataRes } = await Notify.getNotify({ ...payload });
      const { data, current_page, total, per_page } = dataRes;
      return {
        listNotify: [...data],
        current_page,
        total,
        per_page,
      };
    } catch (error) {
      console.log(error);
    }
  },
);

export const getAllNotifyRing = createAsyncThunk('Notify/getAll', async (_, { getState }) => {
  try {
    const { data } = await Notify.getNotifyRing();
    return { time_notify: data.time_notify, ring_notify: data.ring_notify };
  } catch (error) {
    console.log(error);
  }
});

const notifylice = createSlice({
  name: 'Notify',
  initialState,
  reducers: {
    newNotify: (state, action: PayloadAction<any>) => {
      state.newNotify = [...state.newNotify, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('Notify/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Notify/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...state.data, ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Notify/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});
export const { newNotify } = notifylice.actions;
export default notifylice.reducer;
