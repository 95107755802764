import React, { useState } from 'react';
import { Button, Checkbox, Col, Form, Input, Row, Switch } from 'antd';
import { useAppDispatch, useAppSelector } from '@hook/index';
import './style.scss';
import { ICreateRole, IEditRole, IRole } from '@interfaces/Role';
import { convert } from '@utils/convert';
import { createRole, updateRole } from '@store/roleSlice';

export interface IFormEditComponent {
  data: IRole;
  handleCloseScreen: (type: string) => void;
  getData: (message?: string) => void;
}
export default function FormEditComponent({ data, handleCloseScreen, getData }: IFormEditComponent) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading, data: dataRole } = useAppSelector((state) => state.roleSlice);
  let listPermission: { [key: string]: any } = {};
  for (let itemPermission of convert.filterRoleWithRoute(data.permissions)) {
    listPermission = { ...listPermission, [itemPermission.name_route]: itemPermission.group.map((item) => item.id) };
  }
  const [dataForm, setDataForm] = useState<IEditRole>({
    role_id: data.id,
    description: data.description,
    permissions: [],
    ...listPermission,
  });

  const handleSubmint = (event: ICreateRole & { [key: string]: number[] }) => {
    let permission: number[] = [];
    for (let value of Object.values(event)) {
      if (typeof value === 'object') {
        permission = [...permission, ...(value as number[])];
      }
    }
    setDataForm({
      ...(dataForm as ICreateRole),
      description: event.description,
      permissions: permission,
    });
    dispatch(
      updateRole({
        param: {
          ...dataForm,
          description: event.description,
          permissions: permission,
        },
        callback: getData,
      }),
    );
  };

  return (
    <Form
      form={form}
      className="form-role"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      layout="horizontal"
      initialValues={dataForm}
      onFinish={handleSubmint}
      style={{ maxWidth: 1200, width: '100%' }}
      size={'large'}
    >
      <Row gutter={10} justify="start">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Form.Item
            rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
            name="description"
            label="Mô tả"
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      {convert.filterRoleWithRoute(dataRole.permissions).map((item, index) => {
        return (
          <Row gutter={10} justify="start" key={item.name_route}>
            <Col span={24}>
              <Form.Item
                rules={[{ required: false, message: 'Vui lòng không được để trống' }]}
                name={item.name_route}
                label={item.name}
              >
                <Checkbox.Group options={item.group.map((subItem) => ({ label: subItem.label, value: subItem.id }))} />
              </Form.Item>
            </Col>
          </Row>
        );
      })}

      <Row gutter={10} justify="start">
        <Col span={12}>
          <Form.Item rules={[{ required: false, message: 'Vui lòng không được để trống' }]} label=" " colon={false}>
            <Button style={{ width: '100%' }} type="primary" htmlType="submit">
              Cập nhật
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
