import { PlusOutlined } from '@ant-design/icons';
import * as React from 'react';

export interface ICreateTableProps {
  children: React.ReactNode;
  permission: boolean;
}

export default function CreateTable(props: ICreateTableProps) {
  const { children, permission } = props;
  return (
    <>
      {permission ? (
        children
      ) : (
        <>
          <button className="btn-add btn-disable">
            <PlusOutlined />
            <strong>Thêm mới </strong>
          </button>
        </>
      )}
    </>
  );
}
