import { useAppDispatch, useAppSelector } from '@hook/index';
import { getAllUser } from '@store/userSlice';
import React, { useEffect, useState } from 'react';
import Container from '@components/common/container';
import './style.scss';
import { USER } from '@constants/text';
import showMessage from '@utils/helper';
import DataTable from './table/DataTable';
import { getAllProfitStatistics } from '@store/profitStatisticsSlice';
import FormSearch from './form/search';
import { ParamReq } from '@interfaces/CommonInterface';

export default function ProfitStatisticsComponent() {
  const dispatch = useAppDispatch();
  const { loading, data } = useAppSelector((state) => state.profitStatisticslice);
  const [param, setParam] = useState<ParamReq>();
  const [type, setType] = useState<string>('table');
  const getData = (message?: string) => {
    showMessage(message);
    dispatch(
      getAllProfitStatistics({
        paginate: param?.paginate || USER.LIMIT,
        page: param?.page || USER.PAGE,
        type_user: param?.type_user || '',
        time_range: param?.time_range || '',
      }),
    );
    handleChangTypeScreen('table');
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (!param) return;
    getData();
  }, [param]);
  type IContent = { type: string; component: React.ReactNode };

  const handleChangTypeScreen = (typeCurrent: string) => {
    setType(typeCurrent);
  };
  const handleChangeParam = (paramChange: ParamReq) => {
    setParam({ ...param, ...paramChange });
  };
  const TableRender = () => {
    return (
      <>
        <FormSearch handleChangeParam={handleChangeParam} paramCurrent={param} />
        <DataTable
          getData={getData}
          data={data.listProfitStatistics}
          current_page={data.current_page}
          total={data.total}
          per_page={data.per_page}
          handleChangeParam={handleChangeParam}
          loading={loading}
          totalNetProfit={data.totalNetProfit}
          totalRecharge={data.totalRecharge}
          totalWithdraw={data.totalWithdraw}
        />
      </>
    );
  };

  const Content: IContent[] = [
    {
      type: 'table',
      component: TableRender(),
    },
  ];
  return <Container screen={Content} type={type} />;
}
