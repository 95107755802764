import { PayloadCallBack } from '@interfaces/CommonInterface';
import {
  ICreateBanner,
  IDeleteBanner,
  IEditBanner,
  StateBanner,
  IDataBanner,
  IReqGetAllBanner,
} from '@interfaces/Banner';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Banner from '@services/Banner';
import { BANNER } from '@constants/text';

const initialState: StateBanner<IDataBanner> = {
  data: {
    listBanner: [],
    current_page: BANNER.PAGE,
    per_page: BANNER.LIMIT,
    total: 1,
  },
  loading: false,
  error: '',
};

export const getAllBanner = createAsyncThunk(
  'banner/getAll',
  async (payload: Partial<IReqGetAllBanner>, { getState }) => {
    try {
      const { data: dataRes } = await Banner.getBanner({ ...payload });
      const { data, current_page, total, per_page } = dataRes;
      return {
        listBanner: [...data],
        current_page,
        total,
        per_page,
      };
    } catch (error) {
      console.log(error);
    }
  },
);

export const createBanner = createAsyncThunk(
  'Banner/create',
  async (payload: PayloadCallBack<ICreateBanner>, { getState }) => {
    try {
      const { messages } = await Banner.create({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const updateBanner = createAsyncThunk(
  'Banner/edit',
  async (payload: PayloadCallBack<IEditBanner>, { getState }) => {
    try {
      const { messages } = await Banner.update({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const deleteBanner = createAsyncThunk(
  'Banner/delete',
  async (payload: PayloadCallBack<IDeleteBanner>, { getState }) => {
    try {
      const { messages } = await Banner.delete({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

const bannerSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('banner/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('banner/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('banner/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default bannerSlice.reducer;
