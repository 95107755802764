import { IDataRes, IDeleteSetting, IUpdateSetting, ISetting } from '@interfaces/Setting';
import httpRequest from './Api';

const Setting = {
  getSetting(): Promise<IDataRes<ISetting>> {
    const url = '/list-system-settings';
    return httpRequest.get(url);
  },
  update({ ...rest }: IUpdateSetting): Promise<{ messages: string }> {
    const url = '/update-system-setting';
    return httpRequest.post(
      url,
      { ...rest },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
};

export default Setting;
