import { useAppDispatch, useAppSelector } from '@hook/index';
import React, { useEffect, useState } from 'react';
import Container from '@components/common/container';
import './style.scss';
import { USER } from '@constants/text';
import EditRow from './table/Edit';
import CreateRow from './table/Create';
import ListPointPrice from './table/PointPrice';
import { getAllpointPrice } from '@store/pointPrice';
import { IPointPrice } from '@interfaces/PointPrice';
import { ParamReq } from '@interfaces/CommonInterface';
import FormSearch from './form/search';
import showMessage from '@utils/helper';

export default function PointPriceComponent() {
  const dispatch = useAppDispatch();
  const { loading, data } = useAppSelector((state) => state.pointPriceSlice);
  const [type, setType] = useState<string>('table');
  const [param, setParam] = useState<ParamReq>();
  const [editData, setEditData] = useState<IPointPrice>();
  const getData = (message?: string) => {
    showMessage(message);
    dispatch(
      getAllpointPrice({
        type_user: param?.type_user || '',
        time_range: param?.time_range || '',
      }),
    );
    handleChangTypeScreen('table');
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!param) return;
    getData();
  }, [param]);
  type IContent = { type: string; component: React.ReactNode };

  const handleChangTypeScreen = (typeCurrent: string) => {
    setType(typeCurrent);
  };

  const handleChangeParam = (paramChange: ParamReq) => {
    setParam({ ...param, ...paramChange });
  };

  const handleEdit = (data: IPointPrice, type: string) => {
    setEditData(data);
    setType(type);
  };

  const TableRender = () => {
    return (
      <>
        <FormSearch handleChangeParam={handleChangeParam} paramCurrent={param} />
        <ListPointPrice
          getData={getData}
          data={data}
          loading={loading}
          onEdit={handleEdit}
          onCreate={handleChangTypeScreen}
        />
      </>
    );
  };

  const EditRowRender = () => {
    if (editData)
      return (
        <>
          <EditRow data={editData} handleCloseScreen={handleChangTypeScreen} getData={getData} />
        </>
      );
  };

  const CreateRowRender = () => {
    return (
      <>
        <CreateRow handleCloseScreen={handleChangTypeScreen} getData={getData} />
      </>
    );
  };
  const Content: IContent[] = [
    {
      type: 'table',
      component: TableRender(),
    },
    {
      type: 'create',
      component: CreateRowRender(),
    },
    {
      type: 'edit',
      component: EditRowRender(),
    },
  ];
  return <Container screen={Content} type={type} />;
}
