import { useAppDispatch, useAppSelector } from '@hook/index';
import { getAllUser } from '@store/userSlice';
import React, { useEffect, useState } from 'react';
import Container from '@components/common/container';
import './style.scss';
import { AGENT, USER } from '@constants/text';
import showMessage from '@utils/helper';
import { IInviteUser } from '@interfaces/InviteUser';
import ListInviteUser from './table/InviteUser';
import { getAllInviteUser } from '@store/inviteUserSlice';
import { ParamReq } from '@interfaces/CommonInterface';
import FormSearch from './form/search';
import { getAllGame } from '@store/gameSlice';

export default function InviteAgentComponent() {
  const dispatch = useAppDispatch();
  const { loading, data } = useAppSelector((state) => state.inviteUserSlice);
  const { loading: loadingUser, data: dataUser } = useAppSelector((state) => state.userSlice);
  const [type, setType] = useState<string>('table');
  const [param, setParam] = useState<ParamReq>();
  const [editData, setEditData] = useState<IInviteUser>();
  const getData = (message?: string) => {
    showMessage(message);
    dispatch(
      getAllInviteUser({
        paginate: USER.LIMIT,
        page: USER.PAGE,
        user_id: param?.user_id || '',
        invite_id: param?.invite_id || '',
        type_user: AGENT.type_user,
        time_range: param?.time_range || '',
      }),
    );
    handleChangTypeScreen('table');
  };
  useEffect(() => {
    getData();
    dispatch(getAllUser({}));
  }, []);

  useEffect(() => {
    if (!param) return;
    getData();
  }, [param]);
  type IContent = { type: string; component: React.ReactNode };

  const handleChangTypeScreen = (typeCurrent: string) => {
    setType(typeCurrent);
  };
  const handleChangeParam = (paramChange: ParamReq) => {
    setParam({ ...param, ...paramChange });
  };
  const TableRender = () => {
    return (
      <>
        <FormSearch handleChangeParam={handleChangeParam} paramCurrent={param} dataUser={dataUser.listUser} />
        <ListInviteUser
          getData={getData}
          data={data.listInviteUser}
          current_page={data.current_page}
          total={data.total}
          per_page={data.per_page}
          loading={loading}
          onCreate={handleChangTypeScreen}
          handleChangeParam={handleChangeParam}
        />
      </>
    );
  };

  const Content: IContent[] = [
    {
      type: 'table',
      component: TableRender(),
    },
  ];
  return <Container screen={Content} type={type} />;
}
