import httpRequest from './Api';

const Statisticals = {
  getStatisticals(): Promise<any> {
    const url = '/list-statisticals';
    return httpRequest.get(url);
  },
};

export default Statisticals;
