import EventComponent from '@components/admin/event';
import React from 'react';

export default function EventPage() {
  return (
    <>
      <EventComponent />
    </>
  );
}
