import { ListResponse } from '@interfaces/CommonInterface';
import httpRequest from './Api';
import { ICreateEvent, IDataRes, IDeleteEvent, IEvent, IReqGetAllEvent, IUpdateEvent } from '@interfaces/Event';

export interface IChangePassword {
  user_id: string;
  password: string;
  password_confirmation: string;
}

const Event = {
  getEvent({ ...rest }: Partial<IReqGetAllEvent>): Promise<IDataRes<IEvent>> {
    const url = '/list-events';
    return httpRequest.get(url, { params: { ...rest } });
  },
  create({ ...rest }: ICreateEvent): Promise<{ messages: string }> {
    const url = '/create-event';
    return httpRequest.post(
      url,
      { ...rest },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  update({ ...rest }: IUpdateEvent): Promise<{ messages: string }> {
    const url = '/update-event';
    return httpRequest.post(
      url,
      { ...rest },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  delete({ ...rest }: IDeleteEvent): Promise<{ messages: string }> {
    const url = '/delete-event';
    return httpRequest.post(url, { ...rest });
  },
};

export default Event;
