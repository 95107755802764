import { IDataRes, IInviteUser, IReqGetAllInviteUser } from '@interfaces/InviteUser';
import httpRequest from './Api';

const InviteUser = {
  getInviteUser({ ...rest }: Partial<IReqGetAllInviteUser>): Promise<IDataRes<IInviteUser>> {
    const url = '/list-invite-users';
    return httpRequest.get(url, { params: { ...rest } });
  },
};

export default InviteUser;
