import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Button, Pagination, Space, Table } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { MenuTransaction } from '@constants/menu';
import ModalBase from '@components/common/modal/ModalBase';
import { convert } from '@utils/convert';
import Delete from '../dialog/Delete';
import { IBankCard } from '@interfaces/BankCard';
import { ParamReq } from '@interfaces/CommonInterface';
import { IPermissions } from '@interfaces/User';
import { useAppSelector, useAppDispatch } from '@hook/index';
import EditTable from '@components/common/role/EditTable';
import { isCheckRole } from '@utils/helper';
import DeleteTable from '@components/common/role/DeleteTable';
import CreateTable from '@components/common/role/CreateTable';
import { updateStatusBankCard } from '@store/bankCardSlice';

export interface IListMember {
  data: IBankCard[];
  current_page: number;
  total: number;
  per_page: number;
  loading: boolean;
  handleChangeParam: (param: ParamReq) => void;
  onEdit: (data: IBankCard, type: string) => void;
  onCreate: (type: string) => void;
  getData: (messgae?: string) => void;
}

export default function ListBankCard(props: IListMember) {
  const { data, current_page, total, per_page, loading, onEdit, onCreate, getData, handleChangeParam } = props;
  const [openModel, setOpenModal] = useState<boolean>(false);
  const [option, setOption] = useState({ id: 0, key: 0, title: '' });
  const [role, setRole] = useState<IPermissions[]>([]);
  const { data: currentUser } = useAppSelector((state) => state.authSlice);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!currentUser.isLogin) return;
    setRole(convert.getRole(currentUser));
  }, [currentUser]);
  const handleShowOption = (id: number, item) => {
    setOpenModal(true);
    setOption({ id, key: item.key, title: item.label });
  };

  const handleSubmit = (value: boolean) => {
    setOpenModal(value);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDelete = (id: number) => {
    setOpenModal(true);
    setOption({ id, key: 2, title: 'Xác nhận' });
  };

  const handleToggle = async (id: number, status: number) => {
    dispatch(
      updateStatusBankCard({
        param: {
          id: id,
          status: status,
        },
        callback: getData,
      }),
    );
  };

  /** data for column */
  const columns: ColumnsType<IBankCard> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      width: 50,
    },

    {
      title: 'Tên ngân hàng',
      dataIndex: 'bank_type',
      key: 'bank_type',
      align: 'center',
      width: 200,
    },
    {
      title: 'Số tài khoản',
      dataIndex: 'card_no',
      key: 'card_no',
      width: 200,
    },
    {
      title: 'Tên chủ thẻ',
      dataIndex: 'owner_name',
      key: 'owner_name',
      align: 'center',
      width: 200,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 100,
      render: (_, record) => (
        <div className="status-col">
          {record.status ? (
            <span className="status-on btn-hover" onClick={() => handleToggle(record.id, 0)}>
              <CheckOutlined />
            </span>
          ) : (
            <span className="status-off btn-hover" onClick={() => handleToggle(record.id, 1)}>
              <CloseOutlined />
            </span>
          )}
        </div>
      ),
    },
    {
      title: 'Địa chỉ ngân hàng',
      dataIndex: 'bank_address',
      key: 'bank_address',
      align: 'center',
      width: 350,
    },
    {
      title: 'Tạo lúc	',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'center',
      width: 200,
      render: (_, record) => <Space size="middle">{convert.convertDateFormat(record.created_at)}</Space>,
    },
    {
      title: 'Cập nhật lúc',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: 200,
      render: (_, record) => <Space size="middle">{convert.convertDateFormat(record.updated_at)}</Space>,
    },
    {
      title: 'Điều khiển',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 100,
      render: (_, record) => (
        <div className="group-action">
          <EditTable permission={isCheckRole(role, 'edit')}>
            <Button onClick={() => onEdit(record, 'edit')} type="primary" icon={<EditOutlined />} />
          </EditTable>
          <DeleteTable permission={isCheckRole(role, 'delete')}>
            <Button onClick={() => handleDelete(record.id)} type="primary" danger icon={<DeleteOutlined />} />
          </DeleteTable>
        </div>
      ),
    },
  ];

  const renderContentModal = (id: number, key?: number) => {
    return <Delete handleClose={handleClose} getData={getData} id={id} />;
  };
  const ChangePage = (pageItem: number, pageSize: number) => {
    handleChangeParam({ paginate: pageSize, page: pageItem });
  };
  return (
    <>
      <ModalBase openModel={openModel} title={option?.title} handleSubmit={handleSubmit}>
        <div>{renderContentModal(option?.id, option?.key)}</div>
      </ModalBase>
      <div className="table-wrap">
        <div className="tool-bar">
          <div className="tool-bar__left">
            <CreateTable permission={isCheckRole(role, 'create')}>
              <button onClick={() => onCreate('create')} className="btn-add">
                <PlusOutlined />
                <strong>Thêm mới</strong>
              </button>
            </CreateTable>
          </div>
        </div>
        <div className="m-top">
          <Table
            className="table wrap-col"
            loading={loading}
            scroll={{ x: 960 }}
            pagination={false}
            columns={columns}
            dataSource={isCheckRole(role, 'view bank card') ? data : undefined}
            rowKey={(record) => record.id}
          />
          <div className="wrap-pagination">
            {data !== undefined && (
              <Pagination
                onChange={ChangePage}
                current={current_page}
                defaultPageSize={100}
                total={total}
                showSizeChanger={true}
                pageSizeOptions={[10, 20, 50, 100, 500, 1000]}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
