import PointPriceComponent from '@components/admin/point-price';
import React from 'react';

export default function PointPrice() {
  return (
    <>
      <PointPriceComponent />
    </>
  );
}
