import { FastBackwardOutlined } from '@ant-design/icons';
import { IRole } from '@interfaces/Role';
import React, { useState } from 'react';
import FormEditComponent from '../form/Edit';

export interface IEditRowProps {
  data: IRole;
  handleCloseScreen: (type: string) => void;
  getData: (message?: string) => void;
}

export default function EditRow(props: IEditRowProps) {
  const { data, handleCloseScreen, getData } = props;

  return (
    <>
      <div className="screen-option">
        <div className="header">
          <h2 className="title-screen">Chỉnh sửa</h2>
          <button className="btn-back" onClick={() => handleCloseScreen('table')}>
            <FastBackwardOutlined /> Trở về
          </button>
        </div>
        <div className="body-screen">
          <FormEditComponent data={data} handleCloseScreen={handleCloseScreen} getData={getData} />
        </div>
      </div>
    </>
  );
}
