import { useAppDispatch, useAppSelector } from '@hook/index';
import { getAllUser } from '@store/userSlice';
import React, { useEffect, useState } from 'react';
import Container from '@components/common/container';
import './style.scss';
import { USER } from '@constants/text';
import EditRow from './table/Edit';
import CreateRow from './table/Create';
import showMessage from '@utils/helper';
import { IFile } from '@interfaces/File';
import { getAllFile } from '@store/fileSlice';
import DataTable from './table/DataTable';
import { ParamReq } from '@interfaces/CommonInterface';

export default function FileComponent() {
  const dispatch = useAppDispatch();
  const { loading, data } = useAppSelector((state) => state.fileSlice);
  const [type, setType] = useState<string>('table');
  const [param, setParam] = useState<ParamReq>();
  const [editData, setEditData] = useState<IFile>();
  const getData = (message?: string) => {
    showMessage(message);
    dispatch(
      getAllFile({
        paginate: param?.paginate || 30,
        page: param?.page || USER.PAGE,
      }),
    );
    handleChangTypeScreen('table');
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!param) return;
    getData();
  }, [param]);
  type IContent = { type: string; component: React.ReactNode };

  const handleChangTypeScreen = (typeCurrent: string) => {
    setType(typeCurrent);
  };

  const handleEdit = (data: IFile, type: string) => {
    setEditData(data);
    setType(type);
  };
  const handleChangeParam = (paramChange: ParamReq) => {
    setParam({ ...param, ...paramChange });
  };
  const TableRender = () => {
    return (
      <>
        <DataTable
          getData={getData}
          data={data.listFile}
          current_page={data.current_page}
          total={data.total}
          per_page={data.per_page}
          loading={loading}
          onEdit={handleEdit}
          onCreate={handleChangTypeScreen}
          handleChangeParam={handleChangeParam}
        />
      </>
    );
  };

  const EditRowRender = () => {
    if (editData)
      return (
        <>
          <EditRow data={editData} handleCloseScreen={handleChangTypeScreen} getData={getData} />
        </>
      );
  };

  const CreateRowRender = () => {
    return (
      <>
        <CreateRow handleCloseScreen={handleChangTypeScreen} getData={getData} />
      </>
    );
  };
  const Content: IContent[] = [
    {
      type: 'table',
      component: TableRender(),
    },
    {
      type: 'create',
      component: CreateRowRender(),
    },
    {
      type: 'edit',
      component: EditRowRender(),
    },
  ];
  return <Container screen={Content} type={type} />;
}
