import { useAppDispatch, useAppSelector } from '@hook/index';
import React, { useEffect, useState } from 'react';
import Container from '@components/common/container';
import { IGameUser } from '@interfaces/GameUser';
import './style.scss';
import { USER } from '@constants/text';
import { getAllGameUser } from '@store/gameUserSlice';
import { ParamReq } from '@interfaces/CommonInterface';
import showMessage from '@utils/helper';
import EditRow from '../../game-agent/table/Edit';
import ListGameUser from '@components/admin/manager-user/game-user/table/GameUser';
import CreateRow from '../../game-agent/table/Create';
import { getAllGame } from '@store/gameSlice';
export interface IGameUserModalProps {
  id: number;
  openModel: boolean;
}

export default function GameUserModal({ id, openModel }: IGameUserModalProps) {
  const dispatch = useAppDispatch();
  const { loading, data } = useAppSelector((state) => state.gameUserSlice);
  const [type, setType] = useState<string>('table');
  const [param, setParam] = useState<ParamReq>();
  const [editData, setEditData] = useState<IGameUser>();
  const getData = (message?: string) => {
    showMessage(message);
    dispatch(
      getAllGameUser({
        paginate: param?.paginate || USER.LIMIT,
        page: param?.page || USER.PAGE,
        user_id: id || 1,
      }),
    );
    handleChangTypeScreen('table');
  };
  useEffect(() => {
    getData();
    dispatch(getAllGame({}));
  }, [id]);

  useEffect(() => {
    if (!param) return;
    getData();
  }, [param]);
  type IContent = { type: string; component: React.ReactNode };

  const handleChangTypeScreen = (typeCurrent: string) => {
    setType(typeCurrent);
  };

  const handleEdit = (data: IGameUser, type: string) => {
    setEditData(data);
    setType(type);
  };
  const handleChangeParam = (paramChange: ParamReq) => {
    setParam({ ...param, ...paramChange });
  };

  const TableRender = () => {
    return (
      <>
        <ListGameUser
          getData={getData}
          data={data.listGameUser}
          current_page={data.current_page}
          total={data.total}
          per_page={data.per_page}
          loading={loading}
          onEdit={handleEdit}
          handleChangeParam={handleChangeParam}
          onCreate={handleChangTypeScreen}
          maxHeight={400}
        />
      </>
    );
  };

  const EditRowRender = () => {
    if (editData)
      return (
        <>
          <EditRow data={editData} handleCloseScreen={handleChangTypeScreen} getData={getData} id={id} />
        </>
      );
  };

  const CreateRowRender = () => {
    return (
      <>
        <CreateRow handleCloseScreen={handleChangTypeScreen} getData={getData} id={id} />
      </>
    );
  };
  const Content: IContent[] = [
    {
      type: 'table',
      component: TableRender(),
    },
    {
      type: 'create',
      component: CreateRowRender(),
    },
    {
      type: 'edit',
      component: EditRowRender(),
    },
  ];
  return <Container noLoading={true} screen={Content} type={type} />;
}
