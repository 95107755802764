import { ListResponse } from '@interfaces/CommonInterface';
import httpRequest from './Api';
import {
  ICreatePointPrice,
  IDataRes,
  IDeletePointPrice,
  IEditPointPrice,
  IPointPrice,
  IReqGetAllPointPrice,
} from '@interfaces/PointPrice';

const PointPrice = {
  getPointPrice({ ...rest }: Partial<IReqGetAllPointPrice>): Promise<IDataRes<IPointPrice>> {
    const url = '/list-point-prices';
    return httpRequest.get(url, { params: { ...rest } });
  },
  create({ ...rest }: ICreatePointPrice): Promise<{ messages: string }> {
    const url = '/create-point-price';
    return httpRequest.post(url, { ...rest });
  },
  update({ ...rest }: IEditPointPrice): Promise<{ messages: string }> {
    const url = '/update-point-price';
    return httpRequest.post(url, { ...rest });
  },
  delete({ ...rest }: IDeletePointPrice): Promise<{ messages: string }> {
    const url = '/delete-point-price';
    return httpRequest.post(url, { ...rest });
  },
};

export default PointPrice;
