import React, { ReactElement, useState } from 'react';
import { BankOutlined, CheckCircleOutlined, SettingOutlined, UploadOutlined } from '@ant-design/icons';
import { ReactNode } from 'react';
import { MenuProps } from 'antd';
export type ListMenu = {
  path: string;
  icon: ReactNode;
  name: string;
  key?: string;
  children?: {
    path: string;
    name: string;
    key?: string;
    icon?: ReactNode;
  }[];
};
export const HOME_SIDER_MENU_LIST: ListMenu[] = [
  {
    path: '/dashboard',
    icon: <i className="fa-solid fa-house"></i>,
    key: 'home',
    name: 'Trang chủ',
  },
  {
    path: '/members',
    icon: <i className="fa-solid fa-user-group"></i>,
    key: 'members',
    name: 'Quản lí thành viên',
    children: [
      {
        path: '/clients',
        name: 'Danh sách thành viên',
      },
      {
        path: '/recharge-money-members',
        name: 'Nạp tiền',
      },
      {
        path: '/withdraw-money-members',
        name: 'Rút tiền',
      },
      {
        path: '/transfer-point-members',
        name: 'Chuyển điểm',
      },
      {
        path: '/bank-members',
        name: 'Danh sách ngân hàng',
      },
      {
        path: '/game-members',
        name: 'Danh sách tài khoản cược',
      },
      {
        path: '/invite-members',
        name: 'Danh sách giới thiệu',
      },
      {
        path: '/bonus-members',
        name: 'Quản lí Bonus',
      },
    ],
  },
  {
    path: '/partners',
    icon: <i className="fa-solid fa-user-group"></i>,
    key: '/partners',
    name: 'Quản lý đại lý',
    children: [
      {
        path: '/agents',
        name: 'Danh sách đại lý',
      },

      {
        path: '/recharge-money-agents',
        name: 'Nạp tiền',
      },
      {
        path: '/withdraw-money-agents',
        name: 'Rút tiền',
      },
      {
        path: '/transfer-point-agents',
        name: 'Chuyển điểm',
      },
      {
        path: '/bank-agents',
        name: 'Danh sách ngân hàng',
      },
      {
        path: '/game-agents',
        name: 'Danh sách tài khoản cược',
      },
      {
        path: '/invite-agents',
        name: 'Danh sách giới thiệu',
      },
      {
        path: '/bonus-agents',
        name: 'Quản lí Bonus',
      },
    ],
  },
  // {
  //   path: '/list-agent',
  //   icon: <i className="fa-solid fa-users"></i>,
  //   key: 'agent',
  //   name: 'Danh sách đại lí',
  // },
  {
    path: '/finances',
    icon: <BankOutlined />,
    key: 'finances',
    name: 'Quản lí tài chính',
    children: [
      {
        path: '/point-prices',
        name: 'Giá/Điểm',
      },
      {
        path: '/bank-cards',
        name: 'Thẻ ngân hàng',
      },
      {
        path: '/banks',
        name: 'Danh sách ngân hàng',
      },
      {
        path: '/games',
        name: 'Danh sách nhà cái',
      },
      {
        path: '/profit-statistics',
        name: 'Thống kê lợi nhuận',
      },
    ],
  },
  {
    path: '/promotions',
    icon: <i className="fa-solid fa-percent"></i>,
    key: 'promotions',
    name: 'Quản lí khuyến mãi',
    children: [
      {
        path: '/events',
        name: 'Danh sách sự kiện',
      },
      {
        path: '/levels',
        name: 'Danh sách VIP',
      },
    ],
  },
  {
    path: '/permissions',
    icon: <i className="fa-solid fa-lock"></i>,
    key: 'permissions',
    name: 'Thiết lập quyền hạn',
    children: [
      {
        path: '/admins',
        name: 'Danh sách quản trị viên',
      },
      {
        path: '/roles',
        name: 'Phân quyền',
      },
    ],
  },
  {
    path: '/news',
    icon: <i className="fa-regular fa-newspaper"></i>,
    key: 'news',
    name: 'Quản lý tin tức',
    children: [
      {
        path: '/categories',
        name: 'Danh mục bài viết',
      },
      {
        path: '/posts',
        name: 'Danh sách bài viết',
      },
    ],
  },
  {
    path: '/pages',
    icon: <i className="fa-solid fa-pager"></i>,
    key: 'pages',
    name: 'Quản lý trang',
  },
  {
    path: '/settings',
    icon: <SettingOutlined />,
    key: 'settings',
    name: 'Cài đặt hệ thống',
    children: [
      {
        path: '/configs',
        name: 'Cài đặt chung',
      },

      {
        path: '/menus',
        name: 'Danh sách menu',
      },
      {
        path: '/files',
        name: 'Quản lí file',
      },
      {
        path: '/cronjobs',
        name: 'Quản lý cronjob',
      },
      {
        path: '/banners',
        name: 'Quản lý banner',
      },
      {
        path: '/logs',
        name: 'Quản lý logs',
      },
    ],
  },
];

export const MenuTransaction: MenuProps['items'] = [
  {
    label: 'Thông tin thành viên',
    key: '0',
  },
  {
    label: 'Đổi mật khẩu',
    key: '1',
  },
];

export const SettingMenu: any = [
  {
    key: 'logo',
    label: 'Logo',
  },
  {
    key: 'favicon',
    label: 'Favicon',
  },
  {
    key: 'thumbail',
    label: 'Thumbail',
  },
  {
    key: 'name',
    label: 'Tên',
  },
  {
    key: 'author',
    label: 'Chủ quản',
  },
  {
    key: 'mobile',
    label: 'Số điện thoại 1',
  },
  {
    key: 'phone',
    label: 'Số điện thoại 2',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'address',
    label: 'Địa chỉ',
  },
  {
    key: 'about',
    label: 'Giới thiệu',
  },
  {
    key: 'contact',
    label: 'Liên hệ',
  },
  {
    key: 'marquee',
    label: 'Thông báo',
  },
  {
    key: 'seo_title',
    label: 'Seo Title',
  },
  {
    key: 'seo_description',
    label: 'Seo Description',
  },
  {
    key: 'seo_keywords',
    label: 'Seo Keywords',
  },
  {
    key: 'copyright',
    label: 'Copyright',
  },
  {
    key: 'zalo',
    label: 'Zalo',
  },
  {
    key: 'zalo_qrcode',
    label: 'Zalo QRCode',
  },
  {
    key: 'telegram',
    label: 'Telegram',
  },
  {
    key: 'telegram_qrcode',
    label: 'Telegram QRCode',
  },
  {
    key: 'viber',
    label: 'Viber',
  },
  {
    key: 'viber_qrcode',
    label: 'Viber QRCode',
  },
  {
    key: 'skype',
    label: 'Skype',
  },
  {
    key: 'skype_qrcode',
    label: 'Skype QRCode',
  },
  {
    key: 'facebook',
    label: 'Facebook',
  },
  {
    key: 'facebook_qrcode',
    label: 'Facebook QRCode',
  },
  {
    key: 'web_status',
    label: 'Trạng thái website',
  },
  {
    key: 'support',
    label: 'Hỗ trợ trực tuyến',
  },
  {
    key: 'group_telegram_notify',
    label: 'Group thông báo',
  },
  {
    key: 'group_telegram_bot',
    label: 'Group bot',
  },
  {
    key: 'group_telegram_support',
    label: 'Group hỗ trợ',
  },
  {
    key: 'channel_telegram_notify',
    label: 'Channel thông báo',
  },
  {
    key: 'channel_telegram_bot',
    label: 'Channel bot',
  },
  {
    key: 'channel_telegram_new',
    label: 'Channel tin tức',
  },
  {
    key: 'bot_telegram',
    label: 'Bot telegram',
  },
  {
    key: 'member_bonus',
    label: 'Tỷ lệ bonus member',
  },
  {
    key: 'agent_bonus_1',
    label: 'Tỷ lệ bonus đại lý bậc 1',
  },
  {
    key: 'agent_bonus_2',
    label: 'Tỷ lệ bonus đại lý bậc 2',
  },
  {
    key: 'agent_bonus_3',
    label: 'Tỷ lệ bonus đại lý bậc 3',
  },
  {
    key: 'agent_bonus_4',
    label: 'Tỷ lệ bonus đại lý bậc 4',
  },
  {
    key: 'time_notify',
    label: 'Thời gian rung chuông notify',
  },
  {
    key: 'reason_withdrawal',
    label: 'Thông báo cấm rút tiền',
  },
  {
    key: 'ring_notify',
    label: 'Chuông notify',
  },
];
