import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { Button, Space, Table } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import Statisticals from '@services/Statisticals';
export interface IListMember {}

type dataUser = { id: number; username: string; game: string; point: number; transfer: string; status: string };

export default function ListUserDashboard(props: IListMember) {
  const [data, setData] = React.useState<dataUser[]>([]);
  React.useEffect(() => {
    const getData = async () => {
      const res = await Statisticals.getStatisticals();
      const result: dataUser[] = [];
      res?.listTransferMoney.map((el) => {
        result.push({
          id: el.id,
          username: el.user.username,
          game: el.game?.title,
          point: el.point,
          transfer: el.transfer_title,
          status: el.status_title,
        });
      });
      setData(result);
    };
    getData();
  }, []);

  /** data for column */
  const columns: ColumnsType<dataUser> = [
    {
      title: 'Tên người dùng',
      dataIndex: 'username',
      key: 'username',
      align: 'center',
    },
    {
      title: 'Game',
      dataIndex: 'game',
      key: 'game',
      align: 'center',
    },
    {
      title: 'Số điểm',
      dataIndex: 'point',
      key: 'point',
      align: 'center',
    },
    {
      title: 'Loại',
      dataIndex: 'transfer',
      key: 'transfer',
      align: 'center',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
    },
    // {
    //   title: 'Điều khiển',
    //   dataIndex: 'action',
    //   key: 'action',
    //   align: 'center',
    //   render: (_, record) => (
    //     <div className="group-action">
    //       <Button type="primary" icon={<EditOutlined />} />
    //       <Button type="primary" danger icon={<DeleteOutlined />} />
    //     </div>
    //   ),
    // },
  ];

  return (
    <>
      <div className="table-wrap">
        <div>
          <div className="title">Danh sách Nạp/Rút</div>
          <Table
            className="table"
            // loading={loading}
            scroll={{ x: 960 }}
            pagination={false}
            columns={columns}
            dataSource={data}
            rowKey={(record) => record?.id}
          />
        </div>
      </div>
    </>
  );
}
