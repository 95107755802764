import React, { ReactElement, useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Button, Dropdown, MenuProps, Pagination, Space, Table } from 'antd';
import { MenuTransaction } from '@constants/menu';
import ModalBase from '@components/common/modal/ModalBase';
import { IProfitStatistics } from '@interfaces/ProfitStatistics';
import { ParamReq } from '@interfaces/CommonInterface';
import { convert } from '@utils/convert';
export interface IListMember {
  data: IProfitStatistics[];
  current_page: number;
  total: number;
  per_page: number;
  loading: boolean;
  getData: (message?: string) => void;
  handleChangeParam: (param: ParamReq) => void;
  totalNetProfit: any;
  totalRecharge: any;
  totalWithdraw: any;
}

export default function DataTable(props: IListMember) {
  const {
    data,
    current_page,
    total,
    per_page,
    loading,
    totalNetProfit,
    totalRecharge,
    totalWithdraw,
    getData,
    handleChangeParam,
  } = props;

  /** data for column */
  const columns: ColumnsType<IProfitStatistics> = [
    {
      title: 'ID',
      dataIndex: 'user_id',
      key: 'user_id',
    },

    {
      title: 'Tên đăng nhập',
      dataIndex: 'username',
      key: 'username',
      align: 'center',
      render: (_, record) => <Space size="middle">{record.user?.username}</Space>,
    },
    {
      title: 'Loại tài khoản',
      dataIndex: 'user_type',
      key: 'user_type',
      align: 'center',
      render: (_, record) => <Space size="middle">{record.user?.user_type}</Space>,
    },
    {
      title: 'Tổng nạp',
      dataIndex: 'total_recharge',
      key: 'total_recharge',
      align: 'center',
    },
    {
      title: 'Tổng rút',
      dataIndex: 'total_withdraw',
      key: 'total_withdraw',
      align: 'center',
    },
    {
      title: 'Lãi/lỗ',
      dataIndex: 'net_profit',
      key: 'net_profit',
      align: 'center',
    },
  ];
  const ChangePage = (pageItem: number, pageSize: number) => {
    handleChangeParam({ paginate: pageSize, page: pageItem });
  };
  return (
    <>
      <div className="table-wrap">
        <div className="m-top">
          <Table
            className="table wrap-col"
            loading={loading}
            scroll={{ x: 960 }}
            pagination={false}
            columns={columns}
            dataSource={data}
            rowKey={(record) => record.user_id}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>Tổng</Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell className="total-contet" index={3}>
                    {convert.moneyInput(totalRecharge)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell className="total-contet" index={4}>
                    {convert.moneyInput(totalWithdraw)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell className="total-contet" index={5}>
                    {convert.moneyInput(totalNetProfit)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
          <div className="wrap-pagination">
            {data !== undefined && (
              <Pagination
                onChange={ChangePage}
                current={current_page}
                defaultPageSize={100}
                total={total}
                showSizeChanger={true}
                pageSizeOptions={[10, 20, 50, 100, 500, 1000]}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
