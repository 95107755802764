import { IPermissions } from '@interfaces/Role';
import { IUserInfo } from '@interfaces/User';

type SubLevelPermissions = IPermissions & { children: IPermissions[] };
type LevelPermissions = IPermissions & { children: SubLevelPermissions[] };
function convertDateFormat(dateString) {
  const date = new Date(dateString);

  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const seconds = date.getUTCSeconds().toString().padStart(2, '0');

  const formattedDate = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
  return formattedDate;
}

const filterRoleWithRoute = (permissions: IPermissions[]) => {
  let ListRoute: { name_route: string; name: string; group: { label: string; value: string; id: number }[] }[] = [];
  for (let permission of permissions) {
    const x = ListRoute.find((item) => item.name_route === permission.path);
    if (!x) {
      ListRoute.push({
        name_route: permission.path,
        name: permission.title_name,
        group: [{ label: permission.description, value: permission.name, id: permission.id }],
      });
    } else {
      x.group = [
        ...x.group,
        {
          label: permission.description,
          value: permission.name,
          id: permission.id,
        },
      ];
    }
  }
  return ListRoute;
};

// const filterRoleWithRouteById = (permissions: IPermissions[]) => {
//   let ListRoute: {
//     roleParents: IPermissions;
//     children: { roleChildren: IPermissions; subChildren: IPermissions[] }[];
//   }[] = [];
//   let ListRouteParent = permissions.filter((level1) => level1.pid === 0);
//   let SubListRoute = ListRouteParent.map((level1) => {
//     return { roleParents: { ...level1 }, children: permissions.filter((level2) => level2.pid === level1.id) };
//   });
//   return ListRoute;
// };

function filterRoleWithRouteById(permissions: IPermissions[]) {
  const tree: LevelPermissions[] = [];

  const permissionMap = new Map();
  permissions.forEach((permission) => {
    permissionMap.set(permission.id, { ...permission, children: [] });
  });

  permissionMap.forEach((permission) => {
    const parentId = permission.pid;
    if (parentId === 0) {
      tree.push(permission);
    } else {
      const parentPermission = permissionMap.get(parentId);
      if (parentPermission) {
        parentPermission.children.push(permission);
      }
    }
  });
  return tree;
}

export const toPlainString = (num: any) => {
  return ('' + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/, function (a, b, c, d, e) {
    return e < 0 ? b + '0.' + Array(1 - e - c.length).join('0') + c + d : b + c + d + Array(e - d.length + 1).join('0');
  });
};

export const money = (value: any, unit: string) => {
  return new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
  })
    .format(value)
    .replace(/\./g, ',');
  // return `${value.toLocaleString('vi-VN')}`; // da-DK
};
export const moneyInput = (value: any) => {
  return `${value.toLocaleString('vi-VN').replace(/\./g, ',')}`; // da-DK
};

const getRole = (data: IUserInfo) => {
  const path = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
  if (path === 'clients' || path === 'agents') {
    return data.roles[0].permissions.filter((item) => item.path.includes(path) || item.path.includes('game-members'));
  }
  return data.roles[0].permissions.filter((item) => item.path.includes(path));
};

const formatDate = (str: string) => {
  let date = new Date(str),
    mnth = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join('-');
};

export const convert = {
  formatDate,
  convertDateFormat,
  filterRoleWithRoute,
  filterRoleWithRouteById,
  money,
  moneyInput,
  getRole,
};
