import { DeleteOutlined, PlusOutlined, UndoOutlined } from '@ant-design/icons';
import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import Upload from '@services/Upload';
type IUploadImage = {
  handleChangeImg: (value: string, nameImg: string) => void;
  urlImg?: string;
  nameImg: string;
  edit?: boolean;
};
function UploadImage({ handleChangeImg, urlImg, nameImg, edit }: IUploadImage) {
  const [imageUrl, setImageUrl] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    setImageUrl(urlImg || '');
  }, []);
  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (event.target.files[0]) {
      const formData = new FormData();
      formData.append('image', file);
      try {
        const response = await Upload.img({ file: file, category: 'event' });
        setImageUrl(response.file_url);
        handleChangeImg(response.file_url, nameImg);
      } catch (error) {}
    }
  };
  const uploadButton = (
    <label className="btn-upload" htmlFor={`upload${nameImg}`}>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </label>
  );
  const handleImageDelete = async () => {
    await Upload.delete({ file_url: imageUrl || urlImg });
    setImageUrl('');
    handleChangeImg('', nameImg);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  return (
    <div>
      <input
        ref={fileInputRef}
        id={`upload${nameImg}`}
        className="upload-img"
        type="file"
        onChange={handleImageUpload}
      />
      {!imageUrl && uploadButton}
      {imageUrl && (
        <div className="wrap-img">
          <div className="action-img">
            {edit && (
              <label htmlFor={`upload${nameImg}`} className="btn-change">
                <UndoOutlined />
              </label>
            )}
            <div className="btn-delete">
              <DeleteOutlined onClick={handleImageDelete} />
            </div>
          </div>
          <img src={imageUrl} alt="Uploaded" />
        </div>
      )}
    </div>
  );
}

export default UploadImage;
