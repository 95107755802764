import {
  ICreatePointUser,
  IDataRes,
  IDeletePointUser,
  IEditPointUser,
  IPointUser,
  IReqGetAllPointUser,
} from '@interfaces/PointUser';
import httpRequest from './Api';

const PointUser = {
  getPointUser({ ...rest }: Partial<IReqGetAllPointUser>): Promise<IDataRes<IPointUser>> {
    const url = '/list-user-points';
    return httpRequest.get(url, { params: { ...rest } });
  },
  create({ ...rest }: ICreatePointUser): Promise<{ messages: string }> {
    const url = '/create-user-point';
    return httpRequest.post(url, { ...rest });
  },
  update({ ...rest }: IEditPointUser): Promise<{ messages: string }> {
    const url = '/user-point-status';
    return httpRequest.post(url, { ...rest });
  },
  delete({ ...rest }: IDeletePointUser): Promise<{ messages: string }> {
    const url = '/delete-user-point';
    return httpRequest.post(url, { ...rest });
  },
};

export default PointUser;
