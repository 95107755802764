import TransferMoneyAgentComponent from '@components/admin/manager-agent/transfer-money-agent';
import React from 'react';

export default function TransferMoneyAgent() {
  return (
    <>
      <TransferMoneyAgentComponent />
    </>
  );
}
