import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Switch } from 'antd';
import { useAppDispatch, useAppSelector } from '@hook/index';
import './style.scss';
import { ICreateBankUser } from '@interfaces/BankUser';
import { createBanner } from '@store/bannerSlice';
import { createBankUser } from '@store/bankUserSlice';
import { getAllBank } from '@store/bankSlice';
import { getAllBankType } from '@store/bankCardSlice';
import { getAllUser } from '@store/userSlice';
export interface IFormCreateComponent {
  handleCloseScreen: (type: string) => void;
  getData: (message?: string) => void;
}

const optionsApplyType = [
  {
    value: '1',
    label: 'Thẻ tiết kiệm',
  },
];

export default function FormCreateComponent(props: IFormCreateComponent) {
  const { getData } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [dataForm, setDataForm] = useState<ICreateBankUser>();
  const { loading: loadingUser, data: dataBank } = useAppSelector((state) => state.bankSlice);
  const { data: dataBankType } = useAppSelector((state) => state.bankCardSlice);
  const { data: dataUser } = useAppSelector((state) => state.userSlice);

  const handleSubmint = (event: ICreateBankUser) => {
    setDataForm({ ...(dataForm as ICreateBankUser), ...event });
    dispatch(
      createBankUser({
        param: {
          ...dataForm,
          ...event,
          status: event.status ? 1 : 0,
          card_type: typeof event.card_type === 'object' ? event.card_type.value : event.card_type,
          bank_type: typeof event.bank_type === 'object' ? event.bank_type.value : event.bank_type,
        },
        callback: getData,
      }),
    );
  };
  return (
    <>
      <Form
        form={form}
        className="form-event"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={handleSubmint}
        style={{ maxWidth: 1200, width: '100%' }}
        size={'large'}
      >
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name={'user_id'}
              label="Tên người dùng"
            >
              <Select
                showSearch
                allowClear
                placeholder="Tên người dùng"
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={dataUser.listUser.map((item) => ({ label: item.username, value: item.id }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name="card_no"
              label="Số tài khoản"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name={'card_type'}
              label="Các loại hoạt động"
            >
              <Select
                showSearch
                allowClear
                placeholder="Chọn loại ví"
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={Object.values(dataBankType?.listBankBankType || {}).map((item, index) => ({
                  label: String(item),
                  value: String(item),
                }))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name={'bank_type'}
              label="Tên ngân hàng"
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={dataBank.listBank.map((item) => ({ label: item.name, value: item.key }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name={'phone'}
              label="Số điện thoại"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name={'owner_name'}
              label="Chủ sỡ hữu"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name="bank_address"
              label="Địa chỉ ngân hàng"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col span={24}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name="status"
              initialValue={true}
              label="Trạng thái"
            >
              <Switch defaultChecked />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label=" " colon={false}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                Thêm mới
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}
