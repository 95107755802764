import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'antd';
import { useAppDispatch } from '@hook/index';
import './style.scss';

import { updateEvent } from '@store/eventSlice';
import UploadFileImg from '@components/common/upload/UploadFileImg';
import { IUpdateSetting } from '@interfaces/Setting';
import { updateSetting } from '@store/settingSlice';

export interface ITabs {
  getData: (message?: string) => void;
  data: any;
}
export default function Tab1(props: ITabs) {
  const { getData, data } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [dataForm, setDataForm] = useState<any>();

  useLayoutEffect(() => {
    if (Object.keys(data).length === 0) return;

    let dataItem = {};
    data?.forEach((item) => {
      dataItem = { ...dataItem, [item.key]: item.value };
    });
    setDataForm(dataItem);
  }, [data]);

  const handleSubmint = (event: IUpdateSetting) => {
    setDataForm({ ...(dataForm as IUpdateSetting), ...event });
    dispatch(
      updateSetting({
        param: {
          ...dataForm,
          ...event,
          group: 0,
        },
        callback: getData,
      }),
    );
  };

  const handleChangeImg = (url: string, name: string) => {
    setDataForm({ ...(dataForm as IUpdateSetting), [name]: url });
  };

  return (
    <>
      <Form
        form={form}
        className="form-event"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        initialValues={dataForm}
        onFinish={handleSubmint}
        style={{ maxWidth: 1200, width: '100%' }}
        size={'large'}
      >
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label="Logo">
              {<UploadFileImg edit={true} nameImg={'logo'} urlImg={dataForm?.logo} handleChangeImg={handleChangeImg} />}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label="Favicon">
              {
                <UploadFileImg
                  edit={true}
                  nameImg={'favicon'}
                  urlImg={dataForm?.favicon}
                  handleChangeImg={handleChangeImg}
                />
              }
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label="Thumbail">
              {
                <UploadFileImg
                  edit={true}
                  nameImg={'thumbail'}
                  urlImg={dataForm?.thumbail}
                  handleChangeImg={handleChangeImg}
                />
              }
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label=" " colon={false}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                Cập nhật
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}
