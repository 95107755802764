import { PayloadCallBack } from '@interfaces/CommonInterface';
import { IDeleteSetting, StateSetting, IUpdateSetting, IDataSetting } from '@interfaces/Setting';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Setting from '@services/Setting';
import { BANK_CARD } from '@constants/text';

const initialState: StateSetting<IDataSetting> = {
  data: {
    listSetting: [],
  },
  loading: false,
  error: '',
};

export const getAllSetting = createAsyncThunk('Setting/getAll', async (_, { getState }) => {
  try {
    const { data: dataRes } = await Setting.getSetting();
    return {
      listSetting: [...dataRes],
    };
  } catch (error) {
    console.log(error);
  }
});

export const updateSetting = createAsyncThunk(
  'Setting/edit',
  async (payload: PayloadCallBack<IUpdateSetting>, { getState }) => {
    try {
      const { messages } = await Setting.update({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      if (error.status === 500) {
        payload.callback(error.data.message);
        return;
      }
      payload.callback(error.response.data.messages);
    }
  },
);

const settingSlice = createSlice({
  name: 'Setting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('Setting/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Setting/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Setting/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default settingSlice.reducer;
