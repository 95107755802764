import BankAgentComponent from '@components/admin/manager-agent/bank-agent';
import React from 'react';

export default function BankAgentPage() {
  return (
    <>
      <BankAgentComponent />
    </>
  );
}
