import React from 'react';
import { Layout, Col, Row } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import './layout-login.scss';
import { useAppSelector } from '@hook/index';
import Loading from '@components/common/loading';

type Props = {
  children: React.ReactNode;
};

const LoginLayout: React.FC<Props> = (props: Props) => {
  const { children } = props;
  return (
    <>
      <Loading />
      <Layout className="wrapper">
        <Content>
          <Row justify="center" align="middle" className="wrapper--box--login">
            <Col className="login-container">{children}</Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default LoginLayout;
