import * as React from 'react';
import ListUserDashboard from './table/user';
import './style.scss';
import Statistical from './table/statistical';
export interface IDashBoardComponentProps {}

export default function DashBoardComponent(props: IDashBoardComponentProps) {
  return (
    <div className="container-dashboard">
      <div className="header-dashboard">
        <Statistical />
      </div>
      <div className="body-dashboard">
        <ListUserDashboard />
      </div>
      <div className="footer-dashboard"></div>
    </div>
  );
}
