import React, { useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Switch, DatePicker } from 'antd';
import { useAppDispatch } from '@hook/index';
import './style.scss';
import Editor from '@components/common/editor';
import UploadFileImg from '@components/common/upload/UploadFileImg';
import TagComponent from '@components/common/tag';
import { IEditPage, IPage } from '@interfaces/Page';
import { updatePage } from '@store/pageSlice';
import EditText from '@components/common/editor-text';
export interface IFormCreateComponent {
  handleCloseScreen: (type: string) => void;
  getData: (message?: string) => void;
  data: IPage;
  listPage: IPage[];
}
const listRole = [
  { value: 1, label: 'Chính sách' },
  {
    value: 2,
    label: 'Đại lí',
  },
  {
    value: 3,
    label: 'Thành viên',
  },
];
const groupRole = [
  {
    value: 2,
    label: 'Đại lí',
  },
  {
    value: 3,
    label: 'Thành viên',
  },
];
export default function IFormEditComponent(props: IFormCreateComponent) {
  const { getData, data, listPage } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [dataForm, setDataForm] = useState<IEditPage>({
    id: data.id,
    pid: {
      value: data.pid === 0 ? 0 : listPage.find((item) => item.id === data.pid)?.id,
      label: data.pid === 0 ? '---' : listPage.find((item) => item.id === data.pid)?.title,
    },
    title: data.title,
    slug: data.slug,
    url: data.url,
    icon: data.icon,
    image: data.image,
    thumbail: data.thumbail,
    description: data.description,
    content: data.content,
    // tags: data.tags,
    seo_title: data.seo_title,
    seo_description: data.seo_description,
    seo_keywords: data.seo_keywords,
    status: data.status,
    sort: data.sort,
    group_type: {
      value: data.group_type,
      label: groupRole.find((item) => Number(item.value) === Number(data.group_type))?.label,
    },
    type: {
      value: data.type,
      label: listRole.find((item) => Number(item.value) === Number(data.type))?.label,
    },
  });

  const handleSubmint = (event: IEditPage) => {
    setDataForm({ ...(dataForm as IEditPage), ...event });

    dispatch(
      updatePage({
        param: {
          ...dataForm,
          ...event,
          status: event.status ? 1 : 0,
          pid: typeof event.pid === 'object' ? event.pid.value : event.pid,
          type: typeof event.type === 'object' ? event.type.value : event.type,
          group_type: typeof event.group_type === 'object' ? event.group_type.value : event.group_type,
        },
        callback: getData,
      }),
    );
  };
  const handleChangeEditContent = (content: string) => {
    setDataForm({ ...(dataForm as IEditPage), content: content });
  };

  const handleChangeEditDescription = (description: string) => {
    setDataForm({ ...(dataForm as IEditPage), description: description });
  };

  const handleChangeTag = (value: string, name: string) => {
    setDataForm({ ...(dataForm as IEditPage), [name]: value });
  };

  const handleChangeImg = (url: any, name: string) => {
    setDataForm({ ...(dataForm as IEditPage), [name]: url });
  };
  return (
    <>
      <Form
        form={form}
        className="form-event"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        initialValues={dataForm}
        onFinish={handleSubmint}
        style={{ maxWidth: 1200, width: '100%' }}
        size={'large'}
      >
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name={'pid'}
              label="Cấp bật menu"
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={[
                  { value: 0, label: '----' },
                  ...listPage.map((item) => ({ value: item.id, label: item.title })),
                ]}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name="title"
              label="Tiêu đề"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name="slug"
              label="Đường dẫn thân thiện"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label="Hình ảnh">
              <UploadFileImg nameImg={'image'} urlImg={data.image} handleChangeImg={handleChangeImg} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label="Ảnh đại diện">
              <UploadFileImg nameImg={'thumbail'} urlImg={data.thumbail} handleChangeImg={handleChangeImg} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col span={24}>
            <Form.Item label="Mô tả ngắn">
              <EditText content={data.description} handleChangeEdit={handleChangeEditDescription} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col span={24}>
            <Form.Item rules={[{ required: true, message: 'Vui lòng không được để trống' }]} label="Nội dung">
              <EditText content={data.content} handleChangeEdit={handleChangeEditContent} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col span={24}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name="status"
              label="Trạng thái"
              initialValue={true}
            >
              <Switch defaultChecked={!!Number(data.status)} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name="type"
              label="Vai trò"
              initialValue={true}
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={listRole}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              name="group_type"
              label="Nhóm phân loại"
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
            >
              <Select
                placeholder="Nhóm phân loại"
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={groupRole}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: false, message: 'Vui lòng không được để trống' }]}
              label="Số thứ tự hiển thị"
              name="sort"
            >
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name="seo_title" label="Tiêu đề SEO">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name="seo_description" label="Mô tả SEO">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name="seo_keywords" label="Từ khóa SEO">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item rules={[{ required: true, message: 'Vui lòng không được để trống' }]} label="Thẻ tags">
              <TagComponent handleChangeTag={handleChangeTag} name={'tags'} />
            </Form.Item>
          </Col>
        </Row> */}

        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label=" " colon={false}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                Cập nhật
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}
