import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Switch } from 'antd';
import { useAppDispatch, useAppSelector } from '@hook/index';
import './style.scss';

import { IBankCard, IEditBankCard } from '@interfaces/BankCard';
import { updateBankCard } from '@store/bankCardSlice';
import UploadFileImg from '@components/common/upload/UploadFileImg';

export interface IFormEditComponent {
  handleCloseScreen: (type: string) => void;
  getData: (message?: string) => void;
  data: IBankCard;
}

const optionsApplyType = [
  {
    value: '1',
    label: 'Thẻ tiết kiệm',
  },
];
export default function FormEditComponent(props: IFormEditComponent) {
  const { getData, data } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading: loadingUser, data: dataBank } = useAppSelector((state) => state.bankSlice);
  const { data: dataBankType } = useAppSelector((state) => state.bankCardSlice);
  const itemBank = dataBank.listBank.filter((item) => item.key === data.bank_type)[0];
  const [dataForm, setDataForm] = useState<IEditBankCard>({
    id: data.id,
    card_no: data.card_no,
    card_type: {
      value: data.card_type,
      label: Object.values(dataBankType.listBankBankType).find(
        (value, index) => Number(index + 1) === Number(data.card_type),
      ),
    },
    bank_type: {
      value: itemBank?.key,
      label: itemBank?.name,
    },
    phone: data.phone as string,
    owner_name: data.owner_name,
    bank_address: data.bank_address,
    status: !!Number(data.status),
    bank_id: itemBank?.id,
  });

  const handleSubmint = (event: IEditBankCard) => {
    setDataForm({ ...(dataForm as IEditBankCard), ...event });

    dispatch(
      updateBankCard({
        param: {
          ...dataForm,
          ...event,
          status: event.status ? 1 : 0,
          card_type: typeof event.card_type === 'object' ? event.card_type.value : event.card_type,
          bank_type: typeof event.bank_type === 'object' ? event.bank_type.value : event.bank_type,
          bank_id: dataBank.listBank.find(
            (item) => item.key === (typeof event.bank_type === 'object' ? event.bank_type.value : event.bank_type),
          )?.id,
        },
        callback: getData,
      }),
    );
  };
  const handleChangeImg = (url: any, name: string) => {
    setDataForm({ ...(dataForm as IEditBankCard), [name]: url });
  };

  return (
    <>
      <Form
        form={form}
        className="form-event"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        initialValues={dataForm}
        onFinish={handleSubmint}
        style={{ maxWidth: 1200, width: '100%' }}
        size={'large'}
      >
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name="card_no"
              label="Số tài khoản"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name={'card_type'}
              label="Loại thẻ"
            >
              <Select
                showSearch
                allowClear
                placeholder="Chọn loại ví"
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={Object.values(dataBankType?.listBankBankType || {}).map((item, index) => ({
                  label: String(item),
                  value: String(index + 1),
                }))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name={'bank_type'}
              label="Tên ngân hàng"
            >
              <Select
                allowClear
                showSearch
                placeholder="Tên người dùng"
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={dataBank.listBank.map((item) => ({ label: item.name, value: item.key }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name={'phone'}
              label="Số điện thoại"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name={'owner_name'}
              label="Chủ sỡ hữu"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name="bank_address"
              label="Địa chỉ ngân hàng"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col span={24}>
            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name="status"
              label="Trạng thái"
            >
              <Switch defaultChecked={!!Number(data.status)} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label="QR code">
              <UploadFileImg urlImg={data.qrcode} nameImg={'qrcode'} handleChangeImg={handleChangeImg} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label=" " colon={false}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                Cập nhật
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}
