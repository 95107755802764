import { StateProfitStatistics, IDataProfitStatistics, IReqGetAllProfitStatistics } from '@interfaces/ProfitStatistics';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ProfitStatistics from '@services/ProfitStatistics';
import { BANK_USER } from '@constants/text';

const initialState: StateProfitStatistics<IDataProfitStatistics> = {
  data: {
    listProfitStatistics: [],
    current_page: BANK_USER.PAGE,
    per_page: BANK_USER.LIMIT,
    total: 1,
    totalNetProfit: 0,
    totalRecharge: 0,
    totalWithdraw: 0,
  },
  loading: false,
  error: '',
};

export const getAllProfitStatistics = createAsyncThunk(
  'ProfitStatistics/getAll',
  async (payload: Partial<IReqGetAllProfitStatistics>, { getState }) => {
    try {
      const {
        total_net_profit,
        total_recharge,
        total_withdraw,
        data: dataRes,
      } = await ProfitStatistics.getProfitStatistics({ ...payload });
      const { data, current_page, total, per_page } = dataRes;
      return {
        listProfitStatistics: [...data],
        current_page,
        total,
        per_page,
        totalNetProfit: total_net_profit,
        totalRecharge: total_recharge,
        totalWithdraw: total_withdraw,
      };
    } catch (error) {
      console.log(error);
    }
  },
);

const profitStatisticslice = createSlice({
  name: 'ProfitStatistics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('ProfitStatistics/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('ProfitStatistics/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('ProfitStatistics/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default profitStatisticslice.reducer;
