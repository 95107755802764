import WithdrawMoneyAgentComponent from '@components/admin/manager-agent/withdraw-money-agent';
import React from 'react';

export default function WithdrawMoneyAgent() {
  return (
    <>
      <WithdrawMoneyAgentComponent />
    </>
  );
}
