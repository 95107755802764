import { PayloadCallBack } from '@interfaces/CommonInterface';
import { ICreatePage, IDeletePage, IEditPage, StatePage, IDataPage, IReqGetAllPage } from '@interfaces/Page';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Page from '@services/Page';
import { BANK_USER } from '@constants/text';

const initialState: StatePage<IDataPage> = {
  data: {
    listPage: [],
    current_page: BANK_USER.PAGE,
    per_page: BANK_USER.LIMIT,
    total: 1,
  },
  loading: false,
  error: '',
};

export const getAllPage = createAsyncThunk('Page/getAll', async (payload: Partial<IReqGetAllPage>, { getState }) => {
  try {
    const { data: dataRes } = await Page.getPage({ ...payload });
    const { data, current_page, total, per_page } = dataRes;
    return {
      listPage: [...data],
      current_page,
      total,
      per_page,
    };
  } catch (error) {
    console.log(error);
  }
});

export const createPage = createAsyncThunk(
  'Page/create',
  async (payload: PayloadCallBack<ICreatePage>, { getState }) => {
    try {
      const { messages } = await Page.create({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const updatePage = createAsyncThunk('Page/edit', async (payload: PayloadCallBack<IEditPage>, { getState }) => {
  try {
    const { messages } = await Page.update({ ...payload.param });
    payload.callback(messages);
  } catch (error: any) {
    console.log(error);
    payload.callback(error.response.data.messages);
  }
});

export const deletePage = createAsyncThunk(
  'Page/delete',
  async (payload: PayloadCallBack<IDeletePage>, { getState }) => {
    try {
      const { messages } = await Page.delete({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

const pageSlice = createSlice({
  name: 'Page',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('Page/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Page/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Page/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default pageSlice.reducer;
