import { IDataRes, IDeleteFile, IUpdateFile, IFile, IReqGetAllFile } from '@interfaces/File';
import httpRequest from './Api';

const File = {
  getFile({ ...rest }: Partial<IReqGetAllFile>): Promise<IDataRes<IFile>> {
    const url = '/list-attachments';
    return httpRequest.get(url, { params: { ...rest } });
  },
  update({ ...rest }: IUpdateFile): Promise<{ messages: string }> {
    const url = '/upload-attachment';
    return httpRequest.post(
      url,
      { ...rest },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  delete({ ...rest }: IDeleteFile): Promise<{ messages: string }> {
    const url = '/delete-attachment';
    return httpRequest.post(url, { ...rest });
  },
};

export default File;
