import React, { ReactElement, useEffect, useState } from 'react';
import ModalBase from '@components/common/modal/ModalBase';
import { ISetting } from '@interfaces/Setting';
import { PlusOutlined } from '@ant-design/icons';
import { SettingMenu } from '@constants/menu';
import { Tabs, TabsProps } from 'antd';
import Tab1 from './tabs/tab_1';
import Tab2 from './tabs/tab_2';
import Tab3 from './tabs/tab_3';
import Tab4 from './tabs/tab_4';
export interface IListMember {
  data: ISetting[];
  loading: boolean;
  onEdit: (data: ISetting, type: string) => void;
  onCreate: (type: string) => void;
  getData: (message?: string) => void;
}

export default function DataTable(props: IListMember) {
  const { data, getData } = props;

  /** data for column */
  const convertData = () => {
    const groupedData: any = {};
    data.forEach((item) => {
      const settingItem = SettingMenu.find((setting) => setting.key === item.key);

      if (!settingItem) return;

      const group = item.group;

      const newItem = { ...item, label: settingItem.label };

      if (!groupedData[`Group ${group}`]) {
        groupedData[`Group ${group}`] = [];
      }

      groupedData[`Group ${group}`].push(newItem);
    });
    return groupedData;
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Hình ảnh`,
      children: <Tab1 getData={getData} data={convertData()?.['Group 0'] || {}} />,
    },
    {
      key: '2',
      label: `Thông tin`,
      children: <Tab2 getData={getData} data={convertData()?.['Group 1'] || {}} />,
    },
    {
      key: '3',
      label: `Mạng xã hội`,
      children: <Tab3 getData={getData} data={convertData()?.['Group 2'] || {}} />,
    },
    {
      key: '4',
      label: `Cài đặt`,
      children: <Tab4 getData={getData} data={convertData()?.['Group 3'] || {}} />,
    },
  ];

  return (
    <>
      <div className="table-wrap">
        <Tabs defaultActiveKey="1" items={items} />
      </div>
    </>
  );
}
