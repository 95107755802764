import { PayloadCallBack } from '@interfaces/CommonInterface';
import { IDeleteFile, StateFile, IUpdateFile, IDataFile, IReqGetAllFile } from '@interfaces/File';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import File from '@services/File';
import { BANK_CARD } from '@constants/text';

const initialState: StateFile<IDataFile> = {
  data: {
    listFile: [],
    current_page: BANK_CARD.PAGE,
    per_page: BANK_CARD.LIMIT,
    total: 1,
  },
  loading: false,
  error: '',
};

export const getAllFile = createAsyncThunk('File/getAll', async (payload: Partial<IReqGetAllFile>, { getState }) => {
  try {
    const { data: dataRes } = await File.getFile({ ...payload });
    const { data, current_page, total, per_page } = dataRes;
    return {
      listFile: [...data],
      current_page,
      total,
      per_page,
    };
  } catch (error) {
    console.log(error);
  }
});

export const updateFile = createAsyncThunk('File/edit', async (payload: PayloadCallBack<IUpdateFile>, { getState }) => {
  try {
    const { messages } = await File.update({ ...payload.param });
    payload.callback(messages);
  } catch (error) {
    console.log(error);
  }
});

export const deleteFile = createAsyncThunk(
  'File/delete',
  async (payload: PayloadCallBack<IDeleteFile>, { getState }) => {
    try {
      const { messages } = await File.delete({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

const fileSlice = createSlice({
  name: 'File',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('File/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('File/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('File/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default fileSlice.reducer;
