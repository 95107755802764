import React, { useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Switch } from 'antd';
import { useAppDispatch } from '@hook/index';
import './style.scss';
import { ICreateBanner } from '@interfaces/Banner';
import { createBanner } from '@store/bannerSlice';
import UploadFileImg from '@components/common/upload/UploadFileImg';
export interface IFormCreateComponent {
  handleCloseScreen: (type: string) => void;
  getData: (message?: string) => void;
}

export default function FormCreateComponent(props: IFormCreateComponent) {
  const { getData } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [dataForm, setDataForm] = useState<ICreateBanner>();

  const handleSubmint = (event: ICreateBanner) => {
    setDataForm({ ...(dataForm as ICreateBanner), ...event });
    dispatch(
      createBanner({
        param: {
          ...dataForm,
          ...event,
          status: event.status ? 1 : 0,
          is_new_window: event.is_new_window ? 1 : 0,
        },
        callback: getData,
      }),
    );
  };
  const handleChangeImg = (url: string, name: string) => {
    setDataForm({ ...(dataForm as ICreateBanner), url });
  };
  return (
    <>
      <Form
        form={form}
        className="form-event"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={handleSubmint}
        style={{ maxWidth: 1200, width: '100%' }}
        size={'large'}
      >
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name="title" label="Tiêu đề">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name="description" label="Mô tả">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label="Danh sách hình bìa">
              <Input value={dataForm?.url} disabled={!!dataForm?.url} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item
              label="Tải hình lên"
              name={'image_validate'}
              rules={[
                {
                  message: 'Vui lòng nhập ảnh bìa',
                  validator: (_, value) => {
                    if (!dataForm?.url) {
                      return Promise.reject('Số điểm phải lớn hơn hoặc bằng 1');
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <UploadFileImg handleChangeImg={handleChangeImg} nameImg={'url'} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name={'groups'} label="Nhóm">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name={'jump_link'} label="Link liên kết">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col span={24}>
            <Form.Item name="is_new_window" initialValue={true} label="Mở màn hình mới">
              <Switch defaultChecked />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col span={24}>
            <Form.Item name="status" initialValue={true} label="Trạng thái">
              <Switch defaultChecked />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label=" " colon={false}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                Thêm mới
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}
