import { PayloadCallBack } from '@interfaces/CommonInterface';
import { StateInviteUser, IDataInviteUser, IReqGetAllInviteUser } from '@interfaces/InviteUser';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import InviteUser from '@services/InviteUser';
import { BANK_USER } from '@constants/text';

const initialState: StateInviteUser<IDataInviteUser> = {
  data: {
    listInviteUser: [],
    current_page: BANK_USER.PAGE,
    per_page: BANK_USER.LIMIT,
    total: 1,
  },
  loading: false,
  error: '',
};

export const getAllInviteUser = createAsyncThunk(
  'InviteUser/getAll',
  async (payload: Partial<IReqGetAllInviteUser>, { getState }) => {
    try {
      const { data: dataRes } = await InviteUser.getInviteUser({ ...payload });
      const { data, current_page, total, per_page } = dataRes;
      return {
        listInviteUser: [...data],
        current_page,
        total,
        per_page,
      };
    } catch (error) {
      console.log(error);
    }
  },
);

const inviteUserSlice = createSlice({
  name: 'InviteUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('InviteUser/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('InviteUser/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('InviteUser/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default inviteUserSlice.reducer;
