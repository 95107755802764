import { PayloadCallBack } from '@interfaces/CommonInterface';
import {
  ICreateAdmin,
  IDeleteAdmin,
  IUpdateAdmin,
  StateAdmin,
  IDataAdmin,
  IReqGetAllAdmin,
} from '@interfaces/listAdmin';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Admin from '@services/Admin';
import { BANK_USER } from '@constants/text';

const initialState: StateAdmin<IDataAdmin> = {
  data: {
    listAdmin: [],
    current_page: BANK_USER.PAGE,
    per_page: BANK_USER.LIMIT,
    total: 1,
  },
  loading: false,
  error: '',
};

export const getAllAdmin = createAsyncThunk('Admin/getAll', async (payload: Partial<IReqGetAllAdmin>, { getState }) => {
  try {
    const { data: dataRes } = await Admin.getAdmin({ ...payload });
    const { data, current_page, total, per_page } = dataRes;
    return {
      listAdmin: [...data],
      current_page,
      total,
      per_page,
    };
  } catch (error) {
    console.log(error);
  }
});

export const createAdmin = createAsyncThunk(
  'Admin/create',
  async (payload: PayloadCallBack<ICreateAdmin>, { getState }) => {
    try {
      const { messages } = await Admin.create({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const updateAdmin = createAsyncThunk(
  'Admin/edit',
  async (payload: PayloadCallBack<IUpdateAdmin>, { getState }) => {
    try {
      const { messages } = await Admin.update({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const deleteAdmin = createAsyncThunk(
  'Admin/delete',
  async (payload: PayloadCallBack<IDeleteAdmin>, { getState }) => {
    try {
      const { messages } = await Admin.delete({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

const adminSlice = createSlice({
  name: 'Admin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('Admin/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Admin/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Admin/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default adminSlice.reducer;
