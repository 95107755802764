import { PayloadCallBack } from '@interfaces/CommonInterface';
import { ICreateUser, IDataUse, IDeleteUser, IReqGetAllUser, IUpdateUser, StateUser } from '@interfaces/User';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import User from '@services/User';

const initialState: StateUser<IDataUse> = {
  data: {
    listUser: [],
    current_page: 1,
    per_page: 1,
    total: 1,
  },
  loading: false,
  error: '',
};

export const getAllUser = createAsyncThunk('user/getAll', async (payload: Partial<IReqGetAllUser>, { getState }) => {
  try {
    const { data: dataRes } = await User.getUser({ ...payload });
    const { data, current_page, total, per_page } = dataRes;
    return {
      listUser: [...data],
      current_page,
      total,
      per_page,
    };
  } catch (error) {
    console.log(error);
  }
});

export const createUser = createAsyncThunk(
  'user/create',
  async (payload: PayloadCallBack<ICreateUser>, { getState }) => {
    try {
      const { messages } = await User.create({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const updateUser = createAsyncThunk(
  'user/update',
  async (payload: PayloadCallBack<IUpdateUser>, { getState }) => {
    try {
      const { messages } = await User.update({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const deleteUser = createAsyncThunk(
  'user/delete',
  async (payload: PayloadCallBack<IDeleteUser>, { getState }) => {
    try {
      const { messages } = await User.delete({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    handleLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('user/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          if (action.type === 'user/logout/pending') return;
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('user/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...state.data, ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('user/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});
export const { handleLoading } = userSlice.actions;
export default userSlice.reducer;
