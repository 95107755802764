import BankCardComponent from '@components/admin/bank-card';
import React from 'react';

export default function BankCardPage() {
  return (
    <>
      <BankCardComponent />
    </>
  );
}
