import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import * as React from 'react';

export interface IActiveProps {
  children: React.ReactNode;
  permission: boolean;
  active?: number;
}

export default function Active(props: IActiveProps) {
  const { children, permission, active } = props;
  return (
    <>
      {permission ? (
        children
      ) : (
        <>
          <div className="status-col">
            {active ? (
              <span className="status-on btn-hover btn-disable">
                <CheckOutlined />
              </span>
            ) : (
              <span className="status-off btn-hover btn-disable">
                <CloseOutlined />
              </span>
            )}
          </div>
        </>
      )}
    </>
  );
}
