import { PayloadCallBack } from '@interfaces/CommonInterface';
import {
  ICreateCategory,
  IDeleteCategory,
  IEditCategory,
  StateCategory,
  IDataCategory,
  IReqGetAllCategory,
} from '@interfaces/Category';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Category from '@services/Category';
import { BANK_USER } from '@constants/text';

const initialState: StateCategory<IDataCategory> = {
  data: {
    listCategory: [],
    current_page: BANK_USER.PAGE,
    per_page: BANK_USER.LIMIT,
    total: 1,
  },
  loading: false,
  error: '',
};

export const getAllCategory = createAsyncThunk(
  'Category/getAll',
  async (payload: Partial<IReqGetAllCategory>, { getState }) => {
    try {
      const { data: dataRes } = await Category.getCategory({ ...payload });
      const { data, current_page, total, per_page } = dataRes;
      return {
        listCategory: [...data],
        current_page,
        total,
        per_page,
      };
    } catch (error) {
      console.log(error);
    }
  },
);

export const createCategory = createAsyncThunk(
  'Category/create',
  async (payload: PayloadCallBack<ICreateCategory>, { getState }) => {
    try {
      const { messages } = await Category.create({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const updateCategory = createAsyncThunk(
  'Category/edit',
  async (payload: PayloadCallBack<IEditCategory>, { getState }) => {
    try {
      const { messages } = await Category.update({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const deleteCategory = createAsyncThunk(
  'Category/delete',
  async (payload: PayloadCallBack<IDeleteCategory>, { getState }) => {
    try {
      const { messages } = await Category.delete({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

const categorySlice = createSlice({
  name: 'Category',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('Category/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Category/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Category/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default categorySlice.reducer;
