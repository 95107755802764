import { FastBackwardOutlined } from '@ant-design/icons';
import * as React from 'react';
import FormCreateComponent from '../form/Create';

export interface ICreateRowProps {
  handleCloseScreen: (type: string) => void;
  getData: () => void;
}

export default function CreateRow(props: ICreateRowProps) {
  const { handleCloseScreen, getData } = props;
  return (
    <div className="screen-option">
      <div className="header">
        <h2 className="title-screen">Thêm mới</h2>
        <button className="btn-back" onClick={() => handleCloseScreen('table')}>
          <FastBackwardOutlined /> Trở về
        </button>
      </div>
      <div className="body-screen">
        <FormCreateComponent handleCloseScreen={handleCloseScreen} getData={getData} />
      </div>
    </div>
  );
}
