import { ICreateAdmin, IDataRes, IDeleteAdmin, IUpdateAdmin, IAdmin, IReqGetAllAdmin } from '@interfaces/listAdmin';
import httpRequest from './Api';

const Admin = {
  getAdmin({ ...rest }: Partial<IReqGetAllAdmin>): Promise<IDataRes<IAdmin>> {
    const url = '/list-adminds';
    return httpRequest.get(url, { params: { ...rest } });
  },
  create({ ...rest }: ICreateAdmin): Promise<{ messages: string }> {
    const url = '/create-admin';
    return httpRequest.post(
      url,
      { ...rest },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  update({ ...rest }: IUpdateAdmin): Promise<{ messages: string }> {
    const url = '/update-admin';
    return httpRequest.post(
      url,
      { ...rest },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  delete({ ...rest }: IDeleteAdmin): Promise<{ messages: string }> {
    const url = '/delete-admin';
    return httpRequest.post(url, { ...rest });
  },
};

export default Admin;
