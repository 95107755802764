import React, { ReactElement, useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Button, Space, Table } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import ModalBase from '@components/common/modal/ModalBase';
import { ILevel } from '@interfaces/Level';
import Delete from '../dialog/Delete';
import { IPermissions } from '@interfaces/User';
import { useAppSelector } from '@hook/index';
import { convert } from '@utils/convert';
import EditTable from '@components/common/role/EditTable';
import { isCheckRole } from '@utils/helper';
import DeleteTable from '@components/common/role/DeleteTable';
import CreateTable from '@components/common/role/CreateTable';
export interface IListMember {
  data: ILevel[];
  loading: boolean;
  onEdit: (data: ILevel, type: string) => void;
  onCreate: (type: string) => void;
  getData: (message?: string) => void;
}
const optionsLevelup_type = [
  {
    value: '1',
    label: 'Số tiền gửi đạt tiêu chuẩn',
  },
  {
    value: '2',
    label: 'Số tiền đặt cược đạt tiêu chuẩn',
  },
  {
    value: '3',
    label: 'Tất cả đạt tiêu chuẩn',
  },
  {
    value: '4',
    label: 'Bất kỳ số tiền nào đạt tiêu chuẩn',
  },
];
export default function ListLevel(props: IListMember) {
  const { data, loading, onEdit, onCreate, getData } = props;
  const [openModel, setOpenModal] = useState<boolean>(false);
  const [optoin, setOption] = useState({ id: 0, key: 0, title: '' });
  const [role, setRole] = useState<IPermissions[]>([]);
  const { data: currentUser } = useAppSelector((state) => state.authSlice);
  useEffect(() => {
    if (!currentUser.isLogin) return;
    setRole(convert.getRole(currentUser));
  }, [currentUser]);
  const handleShowOption = (id: number, item) => {
    setOpenModal(true);
    setOption({ id, key: item.key, title: item.label });
  };

  const handleSubmit = (value: boolean) => {
    setOpenModal(value);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDelete = (id: number) => {
    setOpenModal(true);
    setOption({ id, key: 2, title: 'Xác nhận' });
  };

  /** data for column */
  const columns: ColumnsType<ILevel> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      className: 'col-size_x',
      fixed: 'left',
      width: 50,
    },
    {
      title: 'Cấp bậc',
      dataIndex: 'level',
      key: 'level',
      className: 'col-size',
      width: 100,
    },
    {
      title: 'Tên cấp bậc',
      dataIndex: 'level_name',
      key: 'level_name',
      className: 'col-size',
      width: 100,
    },
    {
      title: 'Số tiền gửi khuyến mãi',
      dataIndex: 'deposit_money',
      key: 'deposit_money',
      align: 'center',
      className: 'col-size',
      width: 150,
      render: (_, record) => <Space size="middle">{convert.moneyInput(Number(record.deposit_money))}</Space>,
    },
    {
      title: 'Số lần rút tiền trong ngày',
      dataIndex: 'withdrawal_today',
      key: 'withdrawal_today',
      align: 'center',
      className: 'col-size',
      width: 150,
    },
    {
      title: 'Quà khuyến mãi',
      dataIndex: 'level_bonus',
      key: 'level_bonus',
      align: 'center',
      className: 'col-size',
      width: 150,
      render: (_, record) => <Space size="middle">{convert.moneyInput(Number(record.level_bonus))}</Space>,
    },
    {
      title: 'Quà tặng hàng ngày',
      dataIndex: 'day_bonus',
      key: 'day_bonus',
      align: 'center',
      className: 'col-size',
      width: 150,
      render: (_, record) => <Space size="middle">{convert.moneyInput(Number(record.day_bonus))}</Space>,
    },
    {
      title: 'Quà tặng hàng tuần',
      dataIndex: 'week_bonus',
      key: 'week_bonus',
      align: 'center',
      className: 'col-size',
      width: 150,
      render: (_, record) => <Space size="middle">{convert.moneyInput(Number(record.week_bonus))}</Space>,
    },
    {
      title: 'Quà tặng hàng tháng',
      dataIndex: 'month_bonus',
      key: 'month_bonus',
      align: 'center',
      className: 'col-size',
      width: 150,
      render: (_, record) => <Space size="middle">{convert.moneyInput(Number(record.month_bonus))}</Space>,
    },
    {
      title: 'Hằng năm, tiền quà',
      dataIndex: 'year_bonus',
      key: 'year_bonus',
      align: 'center',
      className: 'col-size',
      width: 150,
      render: (_, record) => <Space size="middle">{convert.moneyInput(Number(record.year_bonus))}</Space>,
    },
    {
      title: 'Loại điều kiện khuyến mãi',
      dataIndex: 'levelup_type',
      key: 'levelup_type',
      align: 'center',
      className: 'col-size',
      width: 150,
      render: (_, record) => <div>{optionsLevelup_type[record.levelup_type].label}</div>,
    },
    {
      title: 'Cập nhật lúc',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'center',
      className: 'col-size',
      width: 150,
    },
    {
      title: 'Tạo lúc',
      dataIndex: 'updated_at',
      key: 'updated_at',
      align: 'center',
      className: 'col-size',
      width: 150,
    },
    {
      title: 'Điều khiển',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: 150,
      render: (_, record) => (
        <div className="group-action">
          <EditTable permission={isCheckRole(role, 'edit')}>
            <Button onClick={() => onEdit(record, 'edit')} type="primary" icon={<EditOutlined />} />
          </EditTable>
          <DeleteTable permission={isCheckRole(role, 'delete')}>
            <Button onClick={() => handleDelete(record.id)} type="primary" danger icon={<DeleteOutlined />} />
          </DeleteTable>
        </div>
      ),
    },
  ];

  const renderContentModal = (id: number, key?: number) => {
    return <Delete handleClose={handleClose} getData={getData} id={id} />;
  };
  return (
    <>
      <ModalBase openModel={openModel} title={optoin?.title} handleSubmit={handleSubmit}>
        <div>{renderContentModal(optoin?.id, optoin?.key)}</div>
      </ModalBase>
      <div className="table-wrap">
        <div className="tool-bar">
          <div className="tool-bar__left">
            <CreateTable permission={isCheckRole(role, 'create')}>
              <button onClick={() => onCreate('create')} className="btn-add">
                <PlusOutlined />
                <strong>Thêm VIP mới</strong>
              </button>
            </CreateTable>
          </div>
        </div>
        <div className="m-top">
          <Table
            className="table wrap-col"
            loading={loading}
            scroll={{ x: 960 }}
            pagination={false}
            columns={columns}
            dataSource={isCheckRole(role, 'view level') ? data : undefined}
            rowKey={(record) => record.id}
          />
        </div>
      </div>
    </>
  );
}
