import React, { useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { useAppDispatch } from '@hook/index';
import './style.scss';
import { IUpdateSetting } from '@interfaces/Setting';
import { updateSetting } from '@store/settingSlice';
import UploadFile from '@components/common/upload/UploadFile';

export interface ITabs {
  getData: (message?: string) => void;
  data: any;
}
export default function Tab4(props: ITabs) {
  const { getData, data } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  let dataConvert: any = {};
  data.forEach((item) => {
    dataConvert = { ...dataConvert, [item.key]: item.value };
  });

  const [dataForm, setDataForm] = useState<any>({ ...dataConvert });
  const handleSubmint = (event: IUpdateSetting) => {
    setDataForm({ ...(dataForm as IUpdateSetting), ...event });
    dispatch(
      updateSetting({
        param: { ...dataForm, ...event, group: 3 },
        callback: getData,
      }),
    );
  };

  const handleChangeFile = (value: any, title: string) => {
    setDataForm({ ...(dataForm as IUpdateSetting), [title]: value });
  };

  return (
    <>
      <Form
        form={form}
        className="form-event"
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 10 }}
        layout="horizontal"
        initialValues={dataForm}
        onFinish={handleSubmint}
        style={{ maxWidth: 1200, width: '100%' }}
        size={'large'}
      >
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label="Chuông notify">
              <UploadFile
                fileDefault={dataConvert.ring_notify}
                handleChangeFile={handleChangeFile}
                title="ring_notify"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name="support" label="Hỗ trợ trực tuyến">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name="group_telegram_notify" label="Group thông báo">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name="group_telegram_bot" label="Group bot">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name="group_telegram_support" label="Group hỗ trợ">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name="channel_telegram_notify" label="Channel thông báo">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name="channel_telegram_bot" label="Channel bot">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name="channel_telegram_new" label="Channel tin tức">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name="bot_telegram" label="Bot telegram">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name="member_bonus" label="Tỷ lệ bonus member">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name="agent_bonus_1" label="Tỷ lệ bonus đại lý bậc 1">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name="agent_bonus_2" label="Tỷ lệ bonus đại lý bậc 2">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name="agent_bonus_3" label="Tỷ lệ bonus đại lý bậc 3">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name="agent_bonus_4" label="Tỷ lệ bonus đại lý bậc 4">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name="time_notify" label=" Thời gian rung chuông notify">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item name="reason_withdrawal" label="Thông báo cấm rút tiền">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} justify="start">
          <Col xs={{ span: 24 }} sm={{ span: 20 }}>
            <Form.Item label=" " colon={false}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                Cập nhật
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}
