import GameUserComponent from '@components/admin/manager-user/game-user';
import React from 'react';

export default function GameUser() {
  return (
    <>
      <GameUserComponent />
    </>
  );
}
