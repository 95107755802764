import { ListResponse } from '@interfaces/CommonInterface';
import httpRequest from './Api';
import { ICreateGame, IDataRes, IDeleteGame, IEditGame, IGame, IReqGetAllGame } from '@interfaces/Game';

const Game = {
  getGame({ ...rest }: Partial<{ time_range: any }>): Promise<IDataRes<IGame>> {
    const url = '/list-games';
    return httpRequest.get(url, { params: { ...rest } });
  },
  create({ ...rest }: ICreateGame): Promise<{ messages: string }> {
    const url = '/create-game';
    return httpRequest.post(url, { ...rest });
  },
  update({ ...rest }: IEditGame): Promise<{ messages: string }> {
    const url = '/update-game';
    return httpRequest.post(url, { ...rest });
  },
  delete({ ...rest }: IDeleteGame): Promise<{ messages: string }> {
    const url = '/delete-game';
    return httpRequest.post(url, { ...rest });
  },
};

export default Game;
