import React, { ReactElement, useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Button, Dropdown, MenuProps, Pagination, Space, Table } from 'antd';
import { CaretDownOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { MenuTransaction } from '@constants/menu';
import ModalBase from '@components/common/modal/ModalBase';
import { ILogs } from '@interfaces/Log';
import { ParamReq } from '@interfaces/CommonInterface';
import Delete from '../dialog/Delete';
export interface IListMember {
  data: ILogs[];
  current_page: number;
  total: number;
  per_page: number;
  loading: boolean;
  handleChangeParam: (param: ParamReq) => void;
  onEdit: (data: ILogs, type: string) => void;
  onCreate: (type: string) => void;
  getData: (message?: string) => void;
}

export default function DataTable(props: IListMember) {
  const { data, current_page, total, per_page, loading, onEdit, onCreate, getData, handleChangeParam } = props;
  const [openModel, setOpenModal] = useState<boolean>(false);
  const [optoin, setOption] = useState({ id: 0, key: 0, title: '' });
  const handleShowOption = (id: number, item) => {
    setOpenModal(true);
    setOption({ id, key: item.key, title: item.label });
  };

  const handleSubmit = (value: boolean) => {
    setOpenModal(value);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDelete = (id: number) => {
    setOpenModal(true);
    setOption({ id, key: 2, title: 'Xác nhận' });
  };

  /** data for column */
  const columns: ColumnsType<ILogs> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'username',
      key: 'username',
      align: 'center',
      width: 200,
      render: (_, record) => <Space size="middle">{record.user.username}</Space>,
    },
    {
      title: 'Hành động',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 150,
    },
    {
      title: 'Vai trò',
      dataIndex: 'type_user',
      key: 'type_user',
      align: 'center',
      width: 150,
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
      align: 'center',
      width: 150,
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address',
      align: 'center',
      width: 250,
    },
    {
      title: 'Địa chỉ IP',
      dataIndex: 'ip',
      key: 'ip',
      align: 'center',
      width: 200,
    },
    {
      title: 'Điều khiển',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 200,
      render: (_, record) => (
        <div className="group-action">
          <Button onClick={() => handleDelete(record.id)} type="primary" danger icon={<DeleteOutlined />} />
        </div>
      ),
    },
  ];

  const renderContentModal = (id: number, key?: number) => {
    return <Delete handleClose={handleClose} getData={getData} id={id} />;
  };
  const ChangePage = (pageItem: number, pageSize: number) => {
    handleChangeParam({ paginate: pageSize, page: pageItem });
  };
  return (
    <>
      <ModalBase openModel={openModel} title={optoin?.title} handleSubmit={handleSubmit}>
        <div>{renderContentModal(optoin?.id, optoin?.key)}</div>
      </ModalBase>
      <div className="table-wrap">
        <div className="m-top">
          <Table
            className="table wrap-col"
            loading={loading}
            scroll={{ x: 960 }}
            pagination={false}
            columns={columns}
            dataSource={data}
            rowKey={(record) => record.id}
          />
          <div className="wrap-pagination">
            {data !== undefined && (
              <Pagination
                onChange={ChangePage}
                current={current_page}
                defaultPageSize={100}
                total={total}
                showSizeChanger={true}
                pageSizeOptions={[10, 20, 50, 100, 500, 1000]}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
