import { LoginLayout } from '@shared/layouts';
import React from 'react';
import { Button, Card, Checkbox, Col, Form, Input, message, notification, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../hook/index';
import { authLogin } from '../../store/authSlice';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import ImgIconLog from '../../assets/images/img3_rule_i2_pc.png';
import './style.scss';

const Login = () => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const onFinish = async (values: any) => {
    try {
      dispatch(
        authLogin({
          data: values,
          callback() {
            notification.success({ message: 'Đăng nhập thành công!', duration: 3 });
            navigate('/dashboard');
          },
          error() {
            notification.error({ message: 'Đăng nhập thất bại', duration: 3 });
          },
        }),
      );
    } catch (err) {
      const msg = (err as any).response;
      if (msg?.data.message !== undefined) {
        message.error(msg.data.message);
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <LoginLayout>
        <div className="title-login">ADMIN</div>
        <Card style={{ width: '100%' }}>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item name="username" rules={[{ required: true, message: 'Vui lòng nhập tên người dùng !' }]}>
              <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Username" />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}>
              <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password" />
            </Form.Item>

            <Form.Item
              rules={[{ required: true, message: 'Vui lòng không được để trống' }]}
              name="remember"
              valuePropName="checked"
              wrapperCol={{ offset: 0 }}
            >
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
            <Row justify="center">
              <Col xs={{ span: 24 }} sm={{ span: 20 }}>
                <Form.Item rules={[{ required: true, message: 'Vui lòng không được để trống' }]} className="btn-login">
                  <Button type="primary" htmlType="submit">
                    Login
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </LoginLayout>
    </>
  );
};

export default Login;
