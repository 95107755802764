export const USER = {
  TYPE_USER: 1,
  LIMIT: 100,
  PAGE: 1,
  STATUS: 1,
};

export const EVENT = {
  LIMIT: 100,
  PAGE: 1,
};

export const BANNER = {
  LIMIT: 100,
  PAGE: 1,
  STATUS: 1,
};

export const BANK = {
  LIMIT: 100,
  PAGE: 1,
  STATUS: 1,
};

export const BANK_USER = {
  LIMIT: 100,
  PAGE: 1,
  STATUS: 1,
  CARD_TYPE: 1,
};

export const BANK_CARD = {
  LIMIT: 100,
  PAGE: 1,
  STATUS: 1,
  CARD_TYPE: 1,
};

export const BANK_TRANSFER_MONEY = {
  LIMIT: 100,
  PAGE: 1,
};

export const AGENT = {
  type_user: 2,
};

export const MEMBER = {
  type_user: 3,
};
