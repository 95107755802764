import {
  ICreateBankCard,
  IDataRes,
  IDeleteBankCard,
  IEditBankCard,
  IEditStatusBankCard,
  IBankCard,
  IReqGetAllBankCard,
  IDataResBankType,
} from '@interfaces/BankCard';
import httpRequest from './Api';

const BankCard = {
  getBankCard({ ...rest }: Partial<IReqGetAllBankCard>): Promise<IDataRes<IBankCard>> {
    const url = '/list-bank-cards';
    return httpRequest.get(url, { params: { ...rest } });
  },
  getBankType(): Promise<IDataResBankType> {
    const url = '/list-bank-type';
    return httpRequest.get(url);
  },
  create({ ...rest }: ICreateBankCard): Promise<{ messages: string }> {
    const url = '/create-bank-card';
    return httpRequest.post(
      url,
      { ...rest },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  update({ ...rest }: IEditBankCard | IEditStatusBankCard): Promise<{ messages: string }> {
    const url = '/update-bank-card';
    return httpRequest.post(
      url,
      { ...rest },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  delete({ ...rest }: IDeleteBankCard): Promise<{ messages: string }> {
    const url = '/delete-bank-card';
    return httpRequest.post(url, { ...rest });
  },
};

export default BankCard;
