import TransferMoneyComponent from '@components/admin/manager-user/transfer-money';
import React from 'react';

export default function TransferMoney() {
  return (
    <>
      <TransferMoneyComponent />
    </>
  );
}
