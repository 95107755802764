import { FastBackwardOutlined } from '@ant-design/icons';
import * as React from 'react';
import FormEditComponent from '../form/Edit';
import { IAdmin } from '@interfaces/listAdmin';

export interface IEditRowProps {
  data: IAdmin;
  handleCloseScreen: (type: string) => void;
  getData: (message?: string) => void;
}

export default function EditRow(props: IEditRowProps) {
  const { data, handleCloseScreen, getData } = props;
  return (
    <div className="screen-option">
      <div className="header">
        <h2 className="title-screen">Chỉnh sửa</h2>
        <button className="btn-back" onClick={() => handleCloseScreen('table')}>
          <FastBackwardOutlined /> Trở về
        </button>
      </div>
      <div className="body-screen">
        {' '}
        <FormEditComponent getData={getData} handleCloseScreen={handleCloseScreen} data={data} />
      </div>
    </div>
  );
}
