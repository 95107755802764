import { PayloadCallBack } from '@interfaces/CommonInterface';
import { ICreateBonus, IDeleteBonus, IEditBonus, StateBonus, IDataBonus, IReqGetAllBonus } from '@interfaces/Bonus';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Bonus from '@services/Bonus';
import { BANK } from '@constants/text';

const initialState: StateBonus<IDataBonus> = {
  data: {
    listBonus: [],
    current_page: BANK.PAGE,
    per_page: BANK.LIMIT,
    total: 1,
  },
  loading: false,
  error: '',
};

export const getAllBonus = createAsyncThunk('Bonus/getAll', async (payload: Partial<IReqGetAllBonus>, { getState }) => {
  try {
    const { data: dataRes } = await Bonus.getBonus({ ...payload });
    const { data, current_page, total, per_page } = dataRes;
    return {
      listBonus: [...data],
      current_page,
      total,
      per_page,
    };
  } catch (error) {
    console.log(error);
  }
});

export const createBonus = createAsyncThunk(
  'Bonus/create',
  async (payload: PayloadCallBack<ICreateBonus>, { getState }) => {
    try {
      const { messages } = await Bonus.create({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const updateBonus = createAsyncThunk(
  'Bonus/edit',
  async (payload: PayloadCallBack<IEditBonus>, { getState }) => {
    try {
      const { messages } = await Bonus.update({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

export const deleteBonus = createAsyncThunk(
  'Bonus/delete',
  async (payload: PayloadCallBack<IDeleteBonus>, { getState }) => {
    try {
      const { messages } = await Bonus.delete({ ...payload.param });
      payload.callback(messages);
    } catch (error: any) {
      console.log(error);
      payload.callback(error.response.data.messages);
    }
  },
);

const bonusSlice = createSlice({
  name: 'Bonus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => {
          return action.type.startsWith('Bonus/') && action.type.endsWith('/pending');
        },
        (state, action) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Bonus/') && action.type.endsWith('/fulfilled');
        },
        (state, action) => {
          state.loading = false;
          if (!action.payload) return;
          state.data = { ...action.payload };
        },
      )
      .addMatcher(
        (action) => {
          return action.type.startsWith('Bonus/') && action.type.endsWith('/rejected');
        },
        (state, action) => {
          state.loading = false;
          state.error = action.error;
        },
      );
  },
});

export default bonusSlice.reducer;
