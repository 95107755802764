import {
  ICreateCategory,
  IDataRes,
  IDeleteCategory,
  IEditCategory,
  ICategory,
  IReqGetAllCategory,
} from '@interfaces/Category';
import httpRequest from './Api';

const Category = {
  getCategory({ ...rest }: Partial<IReqGetAllCategory>): Promise<IDataRes<ICategory>> {
    const url = '/list-categories';
    return httpRequest.get(url, { params: { ...rest } });
  },
  create({ ...rest }: ICreateCategory): Promise<{ messages: string }> {
    const url = '/create-category';
    return httpRequest.post(
      url,
      { ...rest },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  update({ ...rest }: IEditCategory): Promise<{ messages: string }> {
    const url = '/update-category';
    return httpRequest.post(
      url,
      { ...rest },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  delete({ ...rest }: IDeleteCategory): Promise<{ messages: string }> {
    const url = '/delete-category';
    return httpRequest.post(url, { ...rest });
  },
};

export default Category;
